import React, { useState } from "react"
import { palet, FlexRow, Spacer, Icon, FlexColumn, SvgIcon } from "@marg51/react-ui"
import SmallImage from "../ui/SmallImage"
import Popup from "../ui/Popup"
import Link from "next/link"
import { getContext } from "../../utils/context"
import { V2 as TrackCard } from "./TrackCard"
import IconButton from "../ui/IconButton"
import TrackLike from "./TrackLike"
import PlayingBars from "../ui/PlayingBars"
import { useTrackListContext } from "./TrackList"
import { useGaEvent } from "../../trackers/ga"
import { useHierarchieContext } from "./HierarchieContext"
import { useRouter } from "next/router"
import { ArtistFavouriteStar } from "Components/Artist/ArtistFavourite"

export default function InlineBlockTrack({
    item,
    showArtist = false,
    showComments = false,
    hideActions = true,
    hideStats = false,
    small = false,
    ...props
}) {
    const trackEvent = useGaEvent()
    const context = getContext()
    const trackListContext = useTrackListContext()
    const hierarchieName = useHierarchieContext()
    const { push, asPath } = useRouter()
    const isCurrentlyPlaying = context.state.player.playlist[context.state.player.track_position] === item.id

    const displayLang = item.langue !== "EN"
    const targeturl = `/traduction/${item.artist.slug}/${item.slug}`

    return (
        <div
            className="bg-white shadow clickable InlineBlockTrack"
            style={{ width: 270, position: "relative" }}
            onClick={() => {
                const track_id =
                    context.state.player.track_id || context.state.player.playlist[context.state.player.track_position]
                if (track_id === item.id || !context.state.isDesktop) {
                    push(targeturl)
                } else {
                    context.playTrack(item.id, { suggestions: trackListContext || [] })
                }
                trackEvent(
                    "event",
                    {
                        category: "SelectedTrack",
                        action: hierarchieName,
                        label: `${item.name} - ${item.artist.name}`,
                    },
                    { 4: item.artist.name }
                )
            }}
        >
            <div
                className={false ? `rounded-t-lg ${small ? "rounded-lg" : ""}` : ""}
                style={{
                    backgroundImage: `url('${item.image}')`,
                    backgroundSize: "cover",
                    "--webkit-background-size": "cover",
                    width: 270,
                    height: 150,
                    overflow: "hidden",
                    position: "relative",
                }}
            >
                {displayLang && (
                    <div style={{ position: "absolute", right: -13, top: 1 }}>
                        <Lang name={item.langue} size={14} />
                    </div>
                )}
                {isCurrentlyPlaying && false && (
                    <div style={{ width: 270, margin: "20px auto 0" }} className="text-center">
                        <PlayingBars />
                    </div>
                )}
                {item.catchphrase && !small && <div className="subtitle-sample">{item.catchphrase}</div>}
            </div>

            <div style={small ? { marginTop: -80, background: "#FFF9", textShadow: "1px 1px 3px #FFF9" } : {}}>
                {(showArtist || item.feat_artists.length >= 1) && (
                    <div style={{ position: "relative" }} className="p-2">
                        {showArtist && (
                            <SmallImage
                                src={item.artist.slug}
                                style={{
                                    borderRadius: "50%",
                                    border: "2px solid white",
                                    position: "absolute",
                                    top: -20,
                                }}
                            />
                        )}
                        {item.feat_artists.length >= 1 &&
                            item.feat_artists.map(({ slug }, index) => (
                                <SmallImage
                                    src={slug}
                                    style={{
                                        borderRadius: "50%",
                                        right: index * 15 + 5,
                                        position: "absolute",
                                        top: -20,
                                        border: "2px solid white",
                                    }}
                                />
                            ))}
                    </div>
                )}

                <div className="p-2">
                    <FlexRow style={{ alignItems: "center", overflow: "hidden" }} nowrap>
                        <DisplayTrackTitle item={item} />
                        <div className="flex-1" />
                        <div>
                            <FlexRow nowrap>
                                <TrackStats item={item} />
                            </FlexRow>
                        </div>
                    </FlexRow>
                    <div className="ellipsis text-gray-700" style={{ minWidth: 0 }}>
                        {showArtist && (
                            <span className="pr-1">
                                {item.artist.name} <ArtistFavouriteStar artist={item.artist} />
                            </span>
                        )}
                        {showFeats(item)}
                    </div>
                </div>
            </div>
            {isCurrentlyPlaying ? (
                asPath !== targeturl ? (
                    <div className="playButton">
                        <Icon name="expand" />
                    </div>
                ) : null
            ) : (
                <div className="playButton">
                    <Icon name="play-circle" />
                </div>
            )}
        </div>
    )
}

function showFeats(item) {
    return item.feat_artists.length >= 1 && item.feat_artists.length < 3 ? (
        <React.Fragment>
            <span style={{ whiteSpace: "nowrap" }}>
                <span className="pr-1 text-gray-600">avec</span>
                <span>{item.feat_artists.map((feat) => feat.name).join(", ")}</span>
            </span>
        </React.Fragment>
    ) : item.feat_artists.length >= 3 ? (
        <span style={{ whiteSpace: "nowrap" }}>• {item.feat_artists.length} autres artistes</span>
    ) : null
}

function DisplayTrackTitle({ item }) {
    let tags = item.name.match(/^\[([a-zA-Z0-9 _-]+)\] (.*)$/)

    let name = <div className="text-gray-800 font-semibold ellipsis">{item.name}</div>

    if (tags) {
        name = (
            <React.Fragment>
                <span className="text-gray-700 font-semibold">{tags[2]}</span>
                <span className="text-gray-600 bg-gray-200 ml-1" style={{ padding: "2px 5px", borderRadius: 5 }}>
                    {tags[1]}
                </span>
            </React.Fragment>
        )
    }

    let lang = null
    // if (item.langue !== "EN") {
    //     lang = <Lang name={item.langue} />
    // }

    return (
        <React.Fragment>
            {lang}
            {name}
        </React.Fragment>
    )
}

const flags = {
    FR: "https://image.flaticon.com/icons/svg/197/197560.svg",
}
function Lang({ name, size, style, ...props }) {
    if (flags[name]) {
        return <Icon {...props} name={flags[name]} style={{ width: size, ...style }} />
    }

    return (
        <span className="text-gray-600 bg-gray-200 mr-1 rounded" style={{ padding: "2px 5px" }}>
            {name}
        </span>
    )
}

function TrackCommentsCount({ track }) {
    if (track.comments_count > 0)
        return (
            <div
                className="text-xs text-gray-500 bg-gray-200 rounded px-1 font-semibold"
                style={{ whiteSpace: "nowrap" }}
            >
                <Icon name="comments-o" style={{ paddingRight: 3 }} />{" "}
                <span className="text-gray-700">{track.comments_count}</span>
            </div>
        )
    return null
}
function TrackLikesCount({ track }) {
    if (track.likes_count > 0)
        return (
            <div
                className="text-xs text-gray-500 bg-gray-200 rounded px-1 font-semibold"
                style={{ whiteSpace: "nowrap" }}
            >
                <Icon name="thumbs-up" style={{ paddingRight: 3 }} />{" "}
                <span className="text-gray-700">{track.likes_count}</span>
            </div>
        )
    return null
}

export function TrackStats({ item }) {
    return (
        <React.Fragment>
            <TrackLikesCount track={item} />
            {item && item.likes_count > 0 && item.comments_count > 0 && <div style={{ width: 5 }} />}
            <TrackCommentsCount track={item} />
        </React.Fragment>
    )
}
