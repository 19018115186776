import React from "react"
import { Router } from "@reach/router"

import Homepage from "pages/index"
import Artist from "pages/artiste/[slug]"
import Artists from "pages/artistes"
import Search from "pages/classement/[...params]"
import UserProfile from "pages/mon-compte"
import Login from "pages/login"
import Video from "pages/traduction/[...params]"
import Playlist from "pages/playlist"
import { Alert } from "@marg51/react-ui"
import { useMediaQuery } from "react-responsive"

import LayoutDesktop from "Components/ui/Layout.desktop"
import LayoutMobile from "Components/ui/Layout.mobile"
import { localStorageRemoveItem } from "./utils/localStorage"
import Member from "./pages/membre/[slug]"
import Logout from "./pages/logout"
import Auth from "./pages/auth"
import { PathProvider } from "./Components/Track/HierarchieContext"

const MyHomepage = () => <Homepage />
const UserProfilePage = () => <UserProfile />
const ArtistPage = ({ slug }) => <Artist slug={slug} key={slug} />
const ArtistsPage = () => <Artists />
const MyVideo = ({ artist_id, id }) => (
    <React.Fragment>
        <Video artistSlug={artist_id} slug={id} key={id} />
    </React.Fragment>
)
const SearchPage = (params) => <Search query={params["*"]} />
const PlaylistPage = () => <Playlist />
const LoginPage = ({ redirect }) => <Login redirect={redirect} />

const MemberPage = () => <Member />

const NotFound = () => <Alert theme="red">Page introuvée</Alert>

const LogoutPage = () => <Logout />
const AuthPage = () => <Auth />

function Route({ path, Component, Layout, ...props }) {
    return (
        <PathProvider value={path}>
            <Component {...props} />
        </PathProvider>
    )
}

export default function MainRouter() {
    const isSmall = useMediaQuery({ query: "(max-width:900px)" })

    const Layout = isSmall ? LayoutMobile : LayoutDesktop
    return (
        <Layout>
            <Router style={{ flex: 1, maxWidth: 750 }}>
                <Route Component={MyVideo} Layout={Layout} path="/traduction/:param1/:param2" />
                <Route Component={MyVideo} Layout={Layout} path="/traduction/:param1/:param2/:param3" />
                <Route Component={ArtistPage} Layout={Layout} path="/artiste/:slug" />
                <Route Component={MyHomepage} Layout={Layout} path="/" />
                <Route Component={ArtistsPage} Layout={Layout} path="/artistes" />
                <Route Component={SearchPage} Layout={Layout} path="/classement/:param1/:param2" />
                <Route Component={SearchPage} Layout={Layout} path="/classement/:param1" />
                <Route Component={LoginPage} Layout={Layout} path="/login" />
                <Route Component={UserProfilePage} Layout={Layout} path="/mon-compte" />
                <Route Component={PlaylistPage} Layout={Layout} path="/playlist" />
                <Route Component={LogoutPage} Layout={Layout} path="/logout" />
                <Route Component={AuthPage} Layout={Layout} path="/auth" />
                <Route Component={MemberPage} Layout={Layout} path="/membre/:slug" />
                <Route Component={NotFound} Layout={Layout} default />
            </Router>
        </Layout>
    )
}
