import React from "react"
import { Icon } from "@marg51/react-ui"
import Link from "next/link"
import { getContext } from "../../utils/context"

export default function ButtonShowMore({ children, ...props }) {
    const context = getContext()

    return (
        <div className="bg-gray-300 rounded-b-sm" style={{ marginTop: -15, paddingBottom: 15, paddingTop: 15 }}>
            <SpanOrLink
                {...props}
                className="font-semibold clickable text-xs uppercase text-gray-700 rounded-full shadow px-3 py-2 bg-gray-400"
                style={{ marginLeft: 75, letterSpacing: "-0.5px" }}
            >
                {children}
                <span className="ml-4" />
                <span
                    className="bg-gray-500"
                    style={{
                        width: 20,
                        height: 20,
                        textAlign: "center",
                        lineHeight: "20px",
                        display: "inline-block",
                        borderRadius: "50%",
                        paddingLeft: 2,
                    }}
                >
                    <Icon name="chevron-right" />
                </span>
            </SpanOrLink>
        </div>
    )
}

function SpanOrLink({ to, as, ...props }) {
    if (to) {
        return (
            <Link href={to} as={as}>
                <a {...props} />
            </Link>
        )
    }
    return <span {...props} />
}
