// @ts-check
import { Button, ButtonIcon, FlexColumn, FlexRow, Icon, IconButton, Spacer } from "@marg51/react-ui"
import { useAdminContext } from "Admin/context"
import { Provider as HierarchieProvider } from "Components/Track/HierarchieContext"
import Link from "next/link"
import React, { useRef, useState } from "react"
import MySvg from "svg/PlayNext"
import { useGaEvent } from "trackers/ga"
import { getContext } from "utils/context"
import { formatDate } from "utils/date"
import formatNumber from "utils/numbers"
import { parse } from "utils/srt"
import { useCurrentTrack } from "utils/useCurrentTrack"
import ArtistFavourite, { ArtistFavouriteStar, useAddArtistToFavourites } from "../../Artist/ArtistFavourite"
import { CommentList } from "../../Comment/CommentList"
import CommentCta from "../../Comment/Comment_cta"
import { ShareLink, useShareLinkRenderProps } from "../../Player/ShareLink"
import Popup from "../../ui/Popup"
import SmallImage from "../../ui/SmallImage"
import { TrackStats } from "../InlineTrack"
import TrackFavourite, { useAddTrackToFavourites } from "../TrackFavourite"

export default function TrackCard({ item }) {
    const currentTrack = useCurrentTrack()
    const context = getContext()
    const adminContext = useAdminContext()

    function seekTo(time) {
        context.playerRef.current.seekTo(time)
    }

    const { state } = context
    return (
        <HierarchieProvider value="TrackCardV2">
            <div
                style={{ flexShrink: 0, position: "relative", marginTop: 10 }}
                itemScope
                itemType="https://schema.org/MusicRecording"
            >
                <FlexRow
                    nowrap
                    className="rounded-t bg-gray-800"
                    style={{ position: "relative", alignItems: "center", padding: "5px 0" }}
                >
                    <div
                        className="rounded-lg bg-gray-800 _border-gray-800 _border-2"
                        style={{
                            padding: 3,
                            flexShrink: 0,
                            zIndex: 1,
                            height: 86,
                            width: 86,
                            position: "absolute",
                            top: -12,
                            left: 15,
                            overflow: "auto"
                        }}
                    >
                        <Link href="/artiste/[slug]" as={`/artiste/${item.artist.slug}`}>
                            <a>
                                <SmallImage src={item.artist.slug} alt={item.artist.name} size={80} />
                            </a>
                        </Link>
                    </div>
                    <div style={{ width: 86 + 15, flexShrink: 0 }} />
                    <Spacer />

                    <h1 className="flex-col flex-1 text-white ellipsis">
                        <FlexRow nowrap className="items-center">
                            <div
                                className="ellipsis"
                                style={{ letterSpacing: 0.5, fontWeight: "bold" }}
                                itemProp="name"
                            >
                                {item.name}
                            </div>
                        </FlexRow>
                        <FlexRow style={{ letterSpacing: 0.5 }}>
                            <ArtistName artist={item.artist} />
                            {item.feat_artists.length > 0 && (
                                <div>
                                    <span className="text-gray-400 px-1">ft.</span>
                                    {item.feat_artists.map((_, index) => (
                                        <React.Fragment>
                                            <ArtistName artist={_} />
                                            {index < item.feat_artists.length - 1 && ", "}
                                        </React.Fragment>
                                    ))}
                                </div>
                            )}
                        </FlexRow>
                    </h1>
                    {adminContext.isAdmin && (
                        <React.Fragment>
                            <div className="text-white">
                                {item.id} - {item.artist.id}
                            </div>
                            <Spacer />
                        </React.Fragment>
                    )}
                </FlexRow>
            </div>

            <FlexColumn
                className="rounded-b bg-white p-4 border-l border-r border-b border-gray-300"
                style={{
                    flexShrink: 0,
                    position: "relative"
                }}
            >
                <FlexRow style={{ marginTop: 5 }}>
                    <div style={{ marginTop: 5 }} className="flex-1">
                        <FlexRow
                            className="mb-4 bg-gray-200 p-4 rounded justify-around"
                            style={{ minHeight: 81 }}
                            nowrap
                        >
                            {item.video.traduction.sf_guard_user && (
                                <FlexColumn className="items-center">
                                    <div className="text-gray-600 text-xs white-space-nowrap">Postée par</div>
                                    <div className="text-purple-800 font-semibold">
                                        {item.video.traduction.sf_guard_user.username}
                                    </div>
                                </FlexColumn>
                            )}
                            <FlexColumn className="items-center">
                                <div className="text-gray-600 text-xs white-space-nowrap">Il y a</div>
                                <div className="text-purple-800 font-semibold white-space-nowrap">
                                    {formatDate(item.video.traduction.created_at)}
                                </div>
                            </FlexColumn>
                            {item.likes_count > 0 && (
                                <FlexColumn className="items-center">
                                    <div className="text-gray-600 text-xs white-space-nowrap">Likes</div>
                                    <FlexRow className="text-purple-800 font-semibold" nowrap>
                                        {item.likes_count}
                                        <Spacer size="small" />
                                        <div className="text-gray-600">
                                            <Icon name="thumbs-up" />
                                        </div>
                                    </FlexRow>
                                </FlexColumn>
                            )}
                            {item.comments_count > 0 && (
                                <FlexColumn className="items-center">
                                    <div className="text-gray-600 text-xs">Com's</div>
                                    <FlexRow className="text-purple-800 font-semibold" nowrap>
                                        {item.comments_count}
                                        <Spacer size="small" />
                                        <div className="text-gray-600">
                                            <Icon name="comments-o" />
                                        </div>
                                    </FlexRow>
                                </FlexColumn>
                            )}
                        </FlexRow>
                    </div>
                    <Spacer />
                    <div className="flex-1">
                        <MyPopup track={item} />
                    </div>
                </FlexRow>

                {((currentTrack && currentTrack.id !== item.id) || !state.isDesktop) && (
                    <React.Fragment>
                        <Spacer />
                        {item && <ShareLink track={item} />}
                    </React.Fragment>
                )}

                {state.user && state.user.id && state.user.id == 1 && false && (
                    <React.Fragment>
                        <div> slug: {item.slug}</div>
                        <div> artist slug: {item.artist.slug}</div>
                    </React.Fragment>
                )}
                <Spacer />
                <Spacer />
                <div className="m-2 text-gray-700 font-semibold border-b border-gray-400">Commentaires</div>
                <Spacer />
                <div>
                    <FlexColumn>
                        <CommentCta track={item} key={item.id} />
                        <Spacer />
                        <CommentList track={item} />
                    </FlexColumn>
                </div>
                <Spacer />
                <Spacer />
                {item.video.traduction.text && (
                    <React.Fragment>
                        <div className="m-2 text-gray-700 font-semibold border-b border-gray-400">Texte</div>
                        <Spacer />
                        <div className="p-2 text-gray-700">
                            {parse(item.video.traduction.text).map((sub) => (
                                <FlexRow style={{ alignItems: "baseline" }} nowrap>
                                    <div
                                        className="clickable hover:underline text-xs"
                                        onClick={() => seekTo(sub.start)}
                                        style={{ fontFamily: "monaco" }}
                                    >
                                        {formatTime(sub.start)}
                                    </div>
                                    <Spacer />
                                    <div>{sub.text}</div>
                                </FlexRow>
                            ))}
                        </div>
                    </React.Fragment>
                )}
            </FlexColumn>
        </HierarchieProvider>
    )
}

function formatTime(time) {
    const seconds = Math.floor(time) % 60

    const minutes = Math.floor(time / 60)

    return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`
}

function ArtistName({ artist }) {
    return (
        <Link href="/artiste/[slug]" as={`/artiste/${artist.slug}`}>
            <a className="text-gray-200">
                <u itemProp="byArtist" itemscope itemtype="http://schema.org/MusicGroup">
                    <meta itemProp="name" content={artist.name} />
                    {artist.name}
                    <ArtistFavouriteStar artist={artist} style={{ marginLeft: 5 }} />
                </u>
            </a>
        </Link>
    )
}

function MyPopup({ track }) {
    const trackEvent = useGaEvent()
    const currentTrack = useCurrentTrack()
    const context = getContext()
    const [isFavourite, toggleFavs] = useAddTrackToFavourites(track.id)

    function onRequestClose(type) {}

    return (
        <FlexColumn>
            {(!currentTrack || currentTrack.id !== track.id) && (
                <React.Fragment>
                    <Spacer size="small" />

                    <Button
                        type="primary"
                        theme="gray"
                        className="underline"
                        icon="play"
                        onClick={() => {
                            context.playTrack(track.id)
                            trackEvent("event", {
                                category: "action",
                                action: "Selected Track",
                                label: `${track.name} - ${track.artist.name}`
                            })
                            onRequestClose()
                        }}
                    >
                        Play
                    </Button>
                </React.Fragment>
            )}
            <Spacer size="small" />
            <ArtistFavourite artist={track.artist} />
            <Spacer size="small" />
            <TrackFavourite track={track} />
            {(!currentTrack || currentTrack.id !== track.id) && (
                <React.Fragment>
                    <Spacer size="small" />

                    <Button
                        type="primary"
                        theme="gray"
                        className="underline"
                        icon={<MySvg fill="#4a5568" />}
                        onClick={() => {
                            context.addTrackNext(track.id)
                            onRequestClose()
                        }}
                    >
                        Jouer après
                    </Button>
                </React.Fragment>
            )}
            {false && (!currentTrack || currentTrack.id !== track.id) && (
                <React.Fragment>
                    <Spacer size="small" />

                    <Button
                        type="primary"
                        theme="gray"
                        className="underline"
                        icon={<MySvg transform="scale(1,-1)" fill="#4a5568" />}
                        onClick={() => {
                            context.addTrackEnd(track.id)
                            onRequestClose()
                        }}
                    >
                        Jouer à la fin
                    </Button>
                </React.Fragment>
            )}
        </FlexColumn>
    )
}
