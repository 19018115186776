import React, { useState, useEffect } from "react"
import { FlexColumn, FlexRow, Spacer, Modal, Alert, Button } from "@marg51/react-ui"
import { useAuth0 } from "auth/Provider"
import { getContext } from "utils/context"
import { useMutationLinkAuth0Account, getMe } from "../api/users"
import { useQuery } from "api/urql"
import { localStorageSetItem } from "../utils/localStorage"
import { useGaEvent } from "../trackers/ga"

export const useAccountNeedsTransition = () => {
    const [{ fetching, data }] = useQuery(getMe)

    if (fetching || !data || !data.me || data.me.auth0_id) {
        return false
    }

    return true
}

export default function ModalTransition() {
    const context = getContext()
    const [result, doLinkAccount] = useMutationLinkAuth0Account()
    const [{ fetching, data }, ErrorComp] = useQuery(getMe)
    const trackEvent = useGaEvent()

    const {
        isAuthenticated,
        loading,
        loginWithRedirect: loginWithPopup,
        logout,
        user,
        getTokenSilently,
        ...other
    } = useAuth0()

    useEffect(() => {
        if (isAuthenticated && !fetching && data && data.me && !data.me.auth0_id) {
            getTokenSilently().then((token) => {
                localStorageSetItem("tz.auth0_token", token)
                doLinkAccount().then((e) => {
                    if (e.error) {
                        trackEvent("event", {
                            category: "account",
                            action: "error while linking account",
                            label: data.me.username,
                        })
                    } else {
                        trackEvent("event", { category: "account", action: "linking account", label: data.me.username })
                    }
                })
            })
        }
    }, [isAuthenticated, fetching])

    if (ErrorComp) {
        return ErrorComp
    }

    if (result.data) {
        if (result.data.LinkAuth0Account && result.data.LinkAuth0Account.auth0_id) {
            return <div>Compte migré avec succès.</div>
        }
    }
    if (result.error) {
        return <div>Une erreur est survenue: {result.error.message}</div>
    }

    if (fetching || !data || data.me.auth0_id) {
        return null
    }

    return (
        <React.Fragment>
            <Alert theme="blue">
                <Alert.Title>Mettez votre compte à jour!</Alert.Title>
                Pour améliorer la sécurité de vos données, nous avons changé la manière dont vous vous connectez sur
                TraduZic.
                <br />
                Pour cela, vous devez ajouter un moyen de connexion, toutes vos données seront conservées.
                <Button
                    onClick={() => {
                        loginWithPopup({ screen_hint: "signup" })
                        trackEvent("event", {
                            category: "account",
                            action: "click on link account",
                            label: data.me.username,
                        })
                    }}
                >
                    Ajouter un moyen de connexion
                </Button>
            </Alert>
            <Spacer />
        </React.Fragment>
    )
}
