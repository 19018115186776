const timeDifference = (current, previous) => {
    const milliSecondsPerMinute = 60 * 1000
    const milliSecondsPerHour = milliSecondsPerMinute * 60
    const milliSecondsPerDay = milliSecondsPerHour * 24
    const milliSecondsPerMonth = milliSecondsPerDay * 30
    const milliSecondsPerYear = milliSecondsPerDay * 365

    const elapsed = current - previous

    // if (elapsed < milliSecondsPerMinute / 3) {
    //     return "just now"
    // }

    if (elapsed < milliSecondsPerMinute) {
        return "1 minute"
    } else if (elapsed < milliSecondsPerHour) {
        return pluralize(elapsed, milliSecondsPerMinute, "minute", "minutes")
    } else if (elapsed < milliSecondsPerDay) {
        return pluralize(elapsed, milliSecondsPerHour, "heure", "heures")
    } else if (elapsed < milliSecondsPerMonth) {
        return pluralize(elapsed, milliSecondsPerDay, "jour", "jours")
    } else if (elapsed < milliSecondsPerYear) {
        return pluralize(elapsed, milliSecondsPerMonth, "mois", "mois")
    } else {
        return pluralize(elapsed, milliSecondsPerYear, "an", "ans")
    }
}

function pluralize(elapsed, baseDuration, singular, plural) {
    const number = Math.round(elapsed / baseDuration)
    if (number > 1) {
        return `${number} ${plural}`
    }

    return `${number} ${singular}`
}

export const timeDifferenceForDate = date => {
    const now = new Date().getTime()

    if (date == parseInt(date)) {
        const updated = new Date(parseInt(date)).getTime()

        return timeDifference(now, updated)
    }

    const updated = new Date(date).getTime()

    return timeDifference(now, updated)
}
