import React, { useEffect, useRef } from "react"
import Link from "next/link"
import { FlexRow, palet, Spacer, Icon } from "@marg51/react-ui"
import { useMediaQuery } from "react-responsive"

import SlideUpPanel from "../ui/SlideUpPanel"
import { getContext } from "../../utils/context"
import useLocation from "utils/useLocation"
import usePlayerSize from "../../utils/usePlayerSize"
import { useCurrentTrack } from "../../utils/useCurrentTrack"
import RenderActiveTrack from "../RenderActiveTrack"
import PlayerControls from "../Player/PlayerControlsMobile"
import { useRouter } from "next/router"
import Avatar from "../User/Avatar"
import { useAccountNeedsTransition } from "auth/ModalTransition"

// import useUsableHeight from "../../../../utils/useUsableHeight"

export default function LayoutMobile({ children }) {
    const context = getContext()
    const { location } = useLocation()
    const [playerWidth, playerHeight] = usePlayerSize()
    const ref = useRef()
    // const usableHeight = useUsableHeight()
    const isTallEnough = useMediaQuery({ query: "(min-height:600px)" })
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
    // const isPortrait = usableHeight > 450

    useEffect(() => {
        window.scrollTo(0, 0)
        setTimeout(() => window.scrollTo(0, 0), 100)
    }, [location, isPortrait])

    useEffect(() => {
        if (isPortrait && context.state.player.isFullScreen) {
            context.toggleFullScreen()
        }
        if (!isPortrait && !context.state.player.isFullScreen && window.innerHeight + window.innerWidth > 1000) {
            context.toggleFullScreen()
        }
        // return () => {
        //     if (context.state.player.isFullScreen) {
        //         context.toggleFullScreen()
        //     }
        // }
    }, [isPortrait, context.state.player.isFullScreen])

    useEffect(() => {
        if (context.state.player.isFullScreen) {
            if (typeof window !== "undefined") window.document.body.classList.add("fullscreen")
        } else {
            if (typeof window !== "undefined") window.document.body.classList.remove("fullscreen")
        }

        return () => {
            if (typeof window !== "undefined") window.document.body.classList.remove("fullscreen")
        }
    }, [context.state.player.isFullScreen])

    useEffect(() => {
        if (typeof window !== "undefined") window.document.body.classList.add("mobile")

        return () => {
            if (typeof window !== "undefined" || true) window.document.body.classList.remove("mobile")
        }
    }, [])

    return (
        <React.Fragment>
            {!context.state.player.isFullScreen && <Navigation context={context} />}
            <div
                style={{
                    position: isPortrait && isTallEnough ? "fixed" : "relative",
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 2,
                    height: playerHeight,
                    background: "rgba(0,0,0,1)",
                    overflow: "hidden"
                }}
            >
                <RenderActiveTrack />
            </div>
            {!context.state.player.isFullScreen && (
                <React.Fragment>
                    <div
                        style={
                            isPortrait && isTallEnough
                                ? {
                                      marginTop: playerHeight
                                      //   maxHeight: `calc( 100vh - ${playerHeight + 35}px)`,
                                      //   overflow: "auto",
                                  }
                                : {}
                        }
                        ref={ref}
                    >
                        <div className="bg-gray-200 border-b-4 border-gray-300 mb-1">
                            <PlayerControls />
                        </div>
                        {children}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

function Navigation({ context }) {
    const isLoggedIn = context.state.user && context.state.user.username

    return (
        <FlexRow
            className="px-8"
            style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 2,
                height: 50,
                alignItems: "center",
                background: "rgb(8, 23, 83)",
                boxShadow: "0 -7px 7px rgba(8, 23, 83,0.25)",
                fontSize: 20
            }}
        >
            <MyLink to="/">
                <Icon name="home" alt="Page d'accueil" />
            </MyLink>
            <div className="flex-1" />
            <div
                style={{
                    marginTop: -20,
                    background: "rgb(8, 23, 83)",
                    borderRadius: "50%",
                    boxShadow: "0 -7px 7px rgba(8, 23, 83,0.25)",
                    fontSize: 26,
                    width: 60
                }}
                className="p-2 text-center"
            >
                <MyLink to="/classement/[...params]" as="/classement/meilleures-videos-sous-titrees/aujourdhui">
                    <Icon name="search" alt="Recherche" />
                </MyLink>
            </div>
            <div className="flex-1" />

            {isLoggedIn ? (
                <LoggedInButton context={context} />
            ) : (
                <MyLink to="/login">
                    <Icon
                        name="https://i.ibb.co/yYVN9HT/2102647.png"
                        alt={"login"}
                        style={{ width: 20, margin: "auto", marginTop: 6, background: "white", borderRadius: "50%" }}
                    />
                </MyLink>
            )}
        </FlexRow>
    )
}

function LoggedInButton({ context }) {
    const needsTransition = useAccountNeedsTransition()

    return (
        <MyLink to="/mon-compte">
            {needsTransition && (
                <div style={{ position: "relative" }}>
                    <div
                        style={{
                            position: "absolute",
                            top: -5,
                            right: -5,
                            width: 20,
                            height: 20,
                            lineHeight: "20px"
                        }}
                        className="bg-red-700 text-center text-white rounded-full"
                    >
                        1
                    </div>
                </div>
            )}
            <Avatar user={context.state.user} size={31} style={{ marginLeft: 1, marginTop: 1, marginRight: 0 }} />
        </MyLink>
    )
}

function MyLink({ to, as, children, notSelectable, ...props }) {
    const router = useRouter()

    const isActive = router.pathname == to

    return (
        <Link href={to} as={as}>
            <a
                {...props}
                className={`text-center transition ${
                    isActive ? "bg-white text-gray-700" : "text-gray-300 hover:bg-white hover:text-gray-700"
                }`}
                style={{ display: "inline-block", width: 33, height: 33, lineHeight: "33px", borderRadius: "50%" }}
            >
                {children}
            </a>
        </Link>
    )
}
