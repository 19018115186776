import { useState, useEffect } from "react"

const useLocation = () => {
    const initialState = { location: {} }

    const [state, setState] = useState(initialState)

    return state
}

export default useLocation
