import React, { useState } from "react"

import { getContext } from "../../utils/context"
import { usePlaylist } from "../../utils/PlaylistContext"
import { FlexColumn, Spacer } from "@marg51/react-ui"
import InlineTrack from "../Track/InlineTrack"
import TrackList from "../Track/TrackList/V3"

export default function Playlist({ context = getContext() }) {
    const playlist = usePlaylist()
    const [displayPreviousSongs, setDisplayPreviousSongs] = useState(false)

    const tracksToDisplay =
        displayPreviousSongs || context.state.player.track_position === 0
            ? playlist
            : playlist.slice(context.state.player.track_position)
    return (
        <TrackList
            title="Playlist"
            items={tracksToDisplay}
            showArtist
            showArtistImage
            hidePlayAllAction
            childrenAtBeginning
        >
            {!displayPreviousSongs && context.state.player.track_position > 0 && (
                <React.Fragment>
                    <div
                        className="text-gray-600 underline clickable"
                        style={{ textAlign: "center" }}
                        onClick={() => setDisplayPreviousSongs(true)}
                    >
                        {context.state.player.track_position === 1
                            ? "Afficher la musique précédente"
                            : `Afficher les ${context.state.player.track_position} musiques précédentes`}
                    </div>
                    <Spacer />
                </React.Fragment>
            )}
        </TrackList>
    )
}
