import React, { useState, useEffect } from "react"

export default function RenderUrqlError({ error }) {
    const [errors, setErrors] = useState([{ message: "Une erreur s'est produite" }])

    useEffect(() => {
        if (!error.response || error.response.bodyUsed) {
            return
        }
        error.response.json().then(({ errors }) => {
            setErrors(errors)
        })
    }, [error])

    return (
        <div className="bg-red-100 p-2 text-red-600 border border-red-500 my-2 rounded">
            {errors.map(_ => _.message).join(",")}
        </div>
    )
}
