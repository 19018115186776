import { useParams, useNavigate, useLocation } from "@reach/router"
export const useRouter = () => {
    const params = useParams()
    const push = useNavigate()
    const location = useLocation()

    let query = params
    if (params && params.param1) {
        query = {
            ...params,
            params: [params.param1, params.param2],
        }
    }
    return { query, push, asPath: location.pathname }
}
