import React, { useState } from "react"
import { Button } from "@marg51/react-ui"
import ButtonSelect from "../ui/ButtonSelect"
import { V3 as Block } from "../Track/TrackList"
import formatNumber from "utils/numbers"
import gql from "graphql-tag"
import fragments from "../../api/_fragments"
import { useQuery } from "api/urql"
import ButtonShowMore from "../ui/ButtonShowMore"
import TrackListLoader from "../Track/TrackListLoader"
import FetchAndRenderSingles from "./FetchAndRenderSingles"
import { ENDPOINT } from "config"
import { useRouter } from "next/router"

const ALBUMS_QUERY = gql`
    query Artist($slug: String!, $offset: Int, $limit: Int = 30) {
        artist(slug: $slug) {
            id
            albums(limit: $limit, offset: $offset, orderBy: year) {
                ...albumFragment
                tracks(limit: 30) {
                    ...trackFragment
                }
            }
        }
    }
    ${fragments.track}
    ${fragments.album}
`
export default function FetchAndRenderAlbums({ item, limit = 3, offset, children }) {
    // const {
    //     query: { ssr },
    // } = useRouter()
    const [displayMore, shouldDisplayMore] = useState(false)
    const [{ fetching, data }, errorComponent] = useQuery(ALBUMS_QUERY, { slug: item.slug, limit, offset })

    if (errorComponent) {
        return errorComponent
    }

    if (fetching) {
        return <TrackListLoader />
    }
    const artist = data.artist

    return (
        <React.Fragment>
            {artist.albums
                .filter(({ tracks }) => tracks.length)
                .map((album, index) => (
                    <React.Fragment>
                        <Block
                            listName="album"
                            key={index}
                            items={album.tracks}
                            image={"album-" + album.slug}
                            alt={`Cover de l'album de ${album.name}`}
                            subtitle={album.year > 10 ? album.year : null}
                            title={album.name}
                            ldData={{
                                "@context": "http://schema.org",
                                "@type": "MusicAlbum",
                                name: album.name,
                                image: `${ENDPOINT}images/album-${album.slug}-80x80.jpeg`,
                                byArtist: {
                                    "@type": "MusicGroup",
                                    name: item.name,
                                },
                                track: {
                                    "@type": "ItemList",
                                    numberOfItems: album.tracks.length,
                                    itemListElement: [
                                        album.tracks.map((track, index) => ({
                                            "@type": "ListItem",
                                            position: index + 1,
                                            item: {
                                                "@type": "MusicRecording",
                                                name: track.name,
                                                url: `/traduction/${item.slug}/${track.slug}`,
                                            },
                                        })),
                                    ],
                                },
                            }}
                        />
                    </React.Fragment>
                ))}
            {item.albums_count > 3 && displayMore && (
                <FetchAndRenderAlbums item={item} limit={30} offset={3}>
                    <FetchAndRenderSingles item={item} />
                </FetchAndRenderAlbums>
            )}

            {item.albums_count <= 3 && <FetchAndRenderSingles item={item} />}
            {item.albums_count > 3 && !displayMore && !children && (
                <Button
                    icon="plus-circle"
                    type="tertiary"
                    theme="gray"
                    style={{ textDecoration: "underline", marginTop: -35, marginBottom: 20 }}
                    onClick={() => shouldDisplayMore(true)}
                >
                    {item.albums_count - 3 === 1 ? (
                        <React.Fragment>Afficher le dernier album</React.Fragment>
                    ) : (
                        <React.Fragment>Afficher les {item.albums_count - 3} autres albums</React.Fragment>
                    )}
                </Button>
            )}
            {children}
        </React.Fragment>
    )
}
