import React from "react"
import { useQuery } from "urql"

import PlaylistContext from "utils/PlaylistContext"
import { TRACKS_QUERY } from "api/tracks"
import { getContext } from "utils/context"
import ErrorBoundary from "utils/ErrorBoundary"
import LoadDefaultPlaylist from "Components/LoadDefaultPlaylist"
import CurrentTrackProvider from "../utils/useCurrentTrack"
import UserData from "utils/UserData"
import { localStorageGetItem } from "../utils/localStorage"

export default function App({ children }) {
    const context = getContext()
    const [result] = useQuery({
        query: TRACKS_QUERY,
        variables: { ids: context.state.player.playlist },
        pause: context.state.player.playlist.length === 0,
    })

    const tracks = result.data ? result.data.tracksByIds : []

    return (
        <PlaylistContext value={tracks}>
            <ErrorBoundary>
                <LoadDefaultPlaylist />
                <CurrentTrackProvider>{children}</CurrentTrackProvider>
                <UserData />
            </ErrorBoundary>
        </PlaylistContext>
    )
}
