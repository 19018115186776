// @ts-check
import React, { useState, useEffect, useContext } from "react"
import createAuth0Client, { Auth0Client } from "@auth0/auth0-spa-js"

import * as config from "../config"

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext()
export const useAuth0 = () => useContext(Auth0Context)

const BaseProvider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
    const [isAuthenticated, setIsAuthenticated] = useState()
    const [user, setUser] = useState()

    const [/** @type {Auth0Client} */ auth0Client, setAuth0] = useState()
    const [loading, setLoading] = useState(true)
    const [popupOpen, setPopupOpen] = useState(false)
    const [justLoggedIn, setJustLoggedIn] = useState(false)

    useEffect(() => {
        const initAuth0 = async () => {
            const auth0FromHook = await createAuth0Client(initOptions)

            setAuth0(auth0FromHook)

            if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
                const { appState } = await auth0FromHook.handleRedirectCallback()
                console.log({ appState })
                onRedirectCallback(appState)
            }

            const isAuthenticated = await auth0FromHook.isAuthenticated()

            setIsAuthenticated(isAuthenticated)

            if (isAuthenticated) {
                const user = await auth0FromHook.getUser()
                console.log({ user })
                setUser(user)
            }

            setLoading(false)
        }
        initAuth0()
        // eslint-disable-next-line
    }, [])

    const loginWithPopup = async (params = {}) => {
        setPopupOpen(true)
        try {
            await auth0Client.loginWithPopup(params)
        } catch (error) {
            console.error(error)
        } finally {
            setPopupOpen(false)
        }
        const user = await auth0Client.getUser()
        setUser(user)
        setJustLoggedIn(true)
        setIsAuthenticated(true)
    }

    const handleRedirectCallback = async () => {
        setLoading(true)
        await auth0Client.handleRedirectCallback()
        const user = await auth0Client.getUser()
        setLoading(false)
        setIsAuthenticated(true)
        setUser(user)
        setJustLoggedIn(true)
    }
    return (
        <Auth0Context.Provider
            value={{
                isAuthenticated,
                user,
                loading,
                popupOpen,
                loginWithPopup,
                handleRedirectCallback,
                getIdTokenClaims: (...args) => auth0Client.getIdTokenClaims(...args),
                loginWithRedirect: (...args) => auth0Client.loginWithRedirect(...args),
                getTokenSilently: (...args) => auth0Client.getTokenSilently(...args),
                getTokenWithPopup: (...args) => auth0Client.getTokenWithPopup(...args),
                logout: (...args) => auth0Client.logout(...args),
                justLoggedIn,
            }}
        >
            {children}
        </Auth0Context.Provider>
    )
}

export const Auth0Provider = (params) => {
    return (
        <BaseProvider
            audience={config.auth0_audience}
            domain={config.auth0_domain}
            client_id={config.auth0_client_id}
            redirect_uri={window.location.origin}
            ui_locales="FR-fr"
            {...params}
        />
    )
}
