import React from "react"
import Link from "next/link"
import { Icon } from "@marg51/react-ui"
import formatNumber from "utils/numbers"
import TrackList from "../Track/TrackList/V3"

export function RenderBigArtist({ artist }) {
    return (
        <TrackList
            listName="RenderBigArtist"
            image={artist.slug}
            playlist={artist.topTracks}
            subtitle={formatNumber(artist.track_count) + " musiques • " + formatNumber(artist.total_views) + " vues"}
            title={
                <Link href="/artiste/[slug]" as={`/artiste/${artist.slug}`}>
                    <a>{artist.name}</a>
                </Link>
            }
            items={artist.topTracks}
        >
            <div className="bg-gray-300 rounded-b-sm" style={{ marginTop: -15, paddingBottom: 15, paddingTop: 15 }}>
                <Link href="/artiste/[slug]" as={`/artiste/${artist.slug}`}>
                    <a
                        className="font-semibold text-xs uppercase text-gray-700 rounded-full shadow px-3 py-2 bg-gray-400"
                        style={{ marginLeft: 75, letterSpacing: "-0.5px" }}
                    >
                        Voir son profil
                        <span className="ml-4" />
                        <span
                            className="bg-gray-500"
                            style={{
                                width: 20,
                                height: 20,
                                textAlign: "center",
                                lineHeight: "20px",
                                display: "inline-block",
                                borderRadius: "50%",
                                paddingLeft: 2,
                            }}
                        >
                            <Icon name="chevron-right" />
                        </span>
                    </a>
                </Link>
            </div>
        </TrackList>
    )
}
