import React, { createContext, useContext } from "react"
import { useHierarchieContext, usePathContext } from "../Components/Track/HierarchieContext"

export const Context = createContext()

export const useGaContext = () => useContext(Context)

/**
 * @returns {(type: "pageview"|"event", context: {category?: "action"|string, label?: string|number, action?:string}) => any}
 */
export const useGaEvent = () => {
    const context = useGaContext()
    const cd3 = useHierarchieContext() // UI context
    const cd2 = usePathContext() || "#" // url

    let dimensions

    if (cd2 || cd3) {
        dimensions = {
            2: cd2,
            3: cd3,
            5: cd2 + " " + cd3, // path + ui context
        }
    }

    return (type, params, moreDimensions) => {
        // const dimToUse =
        //     dimensions && moreDimensions ? { ...dimensions, ...moreDimensions } : dimensions || moreDimensions
        context.push(type, params, dimensions)
    }
}
