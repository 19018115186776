import { useEffect } from "react"
import { GetContext } from "../utils/context"
import { get } from "../api/_fetch"
import { ENDPOINT } from "config"

export default function LoadDefaultPlaylist() {
    const context = GetContext()
    useEffect(() => {
        if (!window.location.pathname.match(/^\/traduction/) && !window.location.pathname.match(/^\/artiste/)) {
            get(ENDPOINT + "api/playlist/traduzic").then((trackIds) =>
                context.setPlaylist(trackIds, "default playlist")
            )
        }
    }, [])
    return null
}
