import React from "react"
import { FlexColumn, FlexRow, Spacer } from "@marg51/react-ui"
import { useQuery } from "api/urql"
import { getAdminTrack, getAdminTracks, updateAdminTrack } from "api/admin"
import { useMutation } from "urql"

function useEntity({ filter, query, path, mutation, multiple = false }) {
    const [{ fetching, data }, error] = useQuery(query, { filter })
    const [updateResult, update] = useMutation(mutation)

    if (error) {
        return { error }
    }
    if (fetching) {
        return { fetching }
    }

    const item = data[path]

    if (!item) {
        throw new Error("is path correct? (" + path + ")")
    }

    return { fetching, [multiple ? "items" : "item"]: item, updateResult, update }
}

export function useTrack(filter) {
    return useEntity({ filter, query: getAdminTrack, path: "getAdminTrack", mutation: updateAdminTrack })
}

export function useTracks(filter) {
    return useEntity({
        filter,
        query: getAdminTracks,
        path: "getAdminTracks",
        mutation: updateAdminTrack,
        multiple: true,
    })
}
