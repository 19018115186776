import React, { useEffect, useState, createContext, useContext, useMemo } from "react"
import { getContext } from "./context"

const DEFAULT_SIZE = [350, 197]

const SIDEBAR_WIDTH = 100
const CONTENT_WIDTH = 600

const CONTEXT = createContext([0, 0])

export const PlayerSizeProvider = ({ children }) => {
    const context = getContext()
    const fullscreen = context.state.player.isFullScreen
    const isDesktop = context.state.isDesktop

    const [sizes, setSize] = useState(() => getSize({ fullscreen, isDesktop }))

    const [width, height] = sizes

    useEffect(() => {
        function tryToSetSize([_width, _height]) {
            if (width !== _width || height !== _height) {
                setSize([_width, _height])
            }
        }
        tryToSetSize(getSize({ fullscreen, isDesktop }))
    }, [fullscreen, isDesktop])

    return <CONTEXT.Provider value={sizes} children={children} />
}

export default function usePlayerSize() {
    return useContext(CONTEXT)
}

function getSize({ fullscreen, isDesktop, isPortrait }) {
    if (fullscreen && isDesktop) {
        return getDerivedSizes(window.innerWidth - 30, window.innerHeight - 75)
    } else if (fullscreen) {
        return getDerivedSizes(window.innerWidth - 30, window.innerHeight)
    } else if (isDesktop) {
        return getDerivedSizes(Math.max(400, window.innerWidth - SIDEBAR_WIDTH - CONTENT_WIDTH), 800)
    } else {
        return DEFAULT_SIZE
    }
}

function getDerivedSizes(width, height) {
    if ((width * 9) / 16 > height) return [(height * 16) / 9, height]

    return [width, (width * 9) / 16]
}
