import React from "react"
import ContentLoader from "react-content-loader"

export default function CommentListLoader() {
    return (
        <ContentLoader height={100} width={320} speed={2} primaryColor="#edf2f7" secondaryColor="#e2e8f0">
            <Item multiplier={0} baseY={0} />
            <Item multiplier={1} baseY={0} />
        </ContentLoader>
    )
}

function Item({ multiplier, baseY }) {
    return (
        <React.Fragment>
            <circle cx="25" cy={multiplier * 50 + 20} r="15" />
            <rect x="65" y={multiplier * 50 + 7} rx="4" ry="4" width="200" height="10" />
            <rect x="65" y={multiplier * 50 + 20} rx="4" ry="4" width="130" height="10" />
        </React.Fragment>
    )
}
