import gql from "graphql-tag"
import { useMutation } from "urql"

const AddEvent = gql`
    mutation Event($type: EventType!, $category: String, $label: String!, $action: String) {
        AddEvent(type: $type, category: $category, label: $label, action: $action)
    }
`
export function useMutationAddEvent() {
    return useMutation(AddEvent)
}
