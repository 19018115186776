import React, { useState } from "react"
import { palet, FlexRow, Spacer, Icon, FlexColumn, SvgIcon } from "@marg51/react-ui"
import SmallImage from "../ui/SmallImage"
import Popup from "../ui/Popup"
import Link from "next/link"
import { getContext } from "../../utils/context"
import { V2 as TrackCard } from "./TrackCard"
import IconButton from "../ui/IconButton"
import TrackLike from "./TrackLike"
import PlayingBars from "../ui/PlayingBars"
import { useTrackListContext } from "./TrackList"
import { useGaEvent } from "../../trackers/ga"
import { useHierarchieContext, Provider as HierarchieProvider } from "./HierarchieContext"
import { ArtistFavouriteStar } from "../Artist/ArtistFavourite"

export default function InlineTrack({
    item,
    index,
    showArtist,
    showArtistImage,
    showComments = false,
    hideActions,
    hideStats,
    className = ""
}) {
    const trackEvent = useGaEvent()
    const context = getContext()
    const trackListContext = useTrackListContext()
    const hierarchieName = useHierarchieContext()
    const isCurrentlyPlaying = context.state.player.playlist[context.state.player.track_position] === item.id

    const image = showArtistImage ? item.artist.slug : ""

    const [showPopup, setShowPopup] = useState(false)

    const displayLang = item.langue !== "EN"

    return (
        <FlexRow
            nowrap
            style={{ fontSize: 16, alignItems: "center", paddingRight: 15 }}
            className={className}
            dataTrackId={item.id}
            itemScope
            itemType="https://schema.org/MusicRecording"
        >
            <meta itemProp="commentCount" content={item.comments_count} />
            {isCurrentlyPlaying ? (
                <div style={{ width: 60, paddingLeft: 15 }} className="text-center">
                    <PlayingBars />
                </div>
            ) : image ? (
                <div style={{ paddingLeft: 15, paddingRight: 5, width: 60, position: "relative" }}>
                    <Link href="/artiste/[slug]" as={`/artiste/${item.artist.slug}`}>
                        <a className="smallImage-hover">
                            <SmallImage src={image} alt={item.artist.name} size={40} />
                        </a>
                    </Link>
                    {displayLang && (
                        <div style={{ position: "absolute", right: -13, top: 1 }}>
                            <Lang name={item.langue} size={14} />
                        </div>
                    )}
                </div>
            ) : (
                <React.Fragment>
                    <div className="text-gray-600" style={{ width: 40, paddingLeft: 20 }}>
                        {index < 10 && "0"}
                        {index}
                    </div>
                    {displayLang ? (
                        <Lang name={item.langue} size={12} style={{ marginLeft: 12, marginRight: -4 }} />
                    ) : (
                        <React.Fragment>
                            <Spacer />
                            <div
                                className="bg-gray-500"
                                style={{
                                    alignSelf: "center",
                                    width: 5,
                                    height: 5,
                                    borderRadius: "50%"
                                }}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
            <Spacer />

            <FlexColumn
                className={`flex-1 ${
                    isCurrentlyPlaying ? "" : "clickable hover:bg-gray-100 hover:shadow"
                } transition rounded`}
                style={{
                    marginLeft: -72,
                    paddingLeft: 72,
                    overflow: "auto"
                }}
                onClick={() => {
                    if (trackListContext) {
                        context.setPlaylist(trackListContext, "trackListContext")
                    }
                    context.playTrack(item.id)
                    trackEvent(
                        "event",
                        {
                            category: "SelectedTrack",
                            action: hierarchieName,
                            label: `${item.name} - ${item.artist.name}`
                        },
                        { 4: item.artist.name }
                    )
                }}
            >
                <FlexRow style={{ alignItems: "center" }}>
                    <DisplayTrackTitle item={item} />
                    {!showArtist && showFeats(item)}
                    {!hideStats && context.state.isDesktop && (
                        <React.Fragment>
                            <div className="flex-1" />
                            <div>
                                <FlexRow nowrap>
                                    <TrackStats item={item} />
                                </FlexRow>
                            </div>
                        </React.Fragment>
                    )}
                </FlexRow>
                {showArtist && (
                    <div
                        className="ellipsis text-gray-700"
                        style={{ minWidth: 0 }}
                        itemProp="byArtist"
                        itemScope
                        itemType="http://schema.org/MusicGroup"
                    >
                        <meta itemProp="name" content={item.artist.name} />
                        {item.artist.name}
                        <ArtistFavouriteStar artist={item.artist} style={{ marginLeft: 3 }} />
                        {showFeats(item)}
                    </div>
                )}
                {!hideStats && !context.state.isDesktop && (
                    <FlexRow>
                        <TrackStats item={item} />
                    </FlexRow>
                )}
            </FlexColumn>

            {!hideActions && (
                <React.Fragment>
                    <Spacer size="small" />
                    <IconButton
                        icon="ellipsis-h"
                        onClick={() => {
                            setShowPopup(!showPopup)
                            if (!showPopup) {
                                trackEvent("event", {
                                    category: "action",
                                    action: "opened track popup",
                                    label: `${item.name} - ${item.artist.name}`
                                })
                            }
                        }}
                    />
                </React.Fragment>
            )}

            {showPopup && (
                <HierarchieProvider value="InlineTrackPopup">
                    <Popup
                        isOpen={true}
                        onRequestClose={() => setShowPopup(false)}
                        image={item.artist.image}
                        style={{ justifyContent: "center", maxWidth: 1150, margin: "auto" }}
                    >
                        <div className="shadow-lg" onClick={(event) => event.stopPropagation()}>
                            <TrackCard item={item} />
                        </div>
                    </Popup>
                </HierarchieProvider>
            )}
        </FlexRow>
    )
}

function showFeats(item) {
    return item.feat_artists.length >= 1 && item.feat_artists.length < 3 ? (
        <React.Fragment>
            <span style={{ whiteSpace: "nowrap" }}>
                <span className="px-1 text-gray-600">ft.</span>
                <span>{item.feat_artists.map((feat) => feat.name).join(", ")}</span>
            </span>
        </React.Fragment>
    ) : item.feat_artists.length >= 3 ? (
        <span className="text-gray-600" style={{ paddingLeft: 5, whiteSpace: "nowrap" }}>
            • {item.feat_artists.length} other artists
        </span>
    ) : null
}

function DisplayTrackTitle({ item }) {
    let tags = item.name.match(/^\[([a-zA-Z0-9 _-]+)\] (.*)$/)

    let name = (
        <div className="text-gray-700 font-semibold ellipsis" itemProp="name">
            {item.name}
        </div>
    )

    if (tags) {
        name = (
            <React.Fragment>
                <span className="text-gray-700 font-semibold" itemProp="name">
                    {tags[2]}
                </span>
                <span className="text-gray-600 bg-gray-200 ml-1" style={{ padding: "2px 5px", borderRadius: 5 }}>
                    {tags[1]}
                </span>
            </React.Fragment>
        )
    }

    let lang = null
    // if (item.langue !== "EN") {
    //     lang = <Lang name={item.langue} />
    // }

    return (
        <React.Fragment>
            {lang}
            {name}
        </React.Fragment>
    )
}

const flags = {
    FR: "https://cdn.countryflags.com/thumbs/france/flag-400.png"
}
function Lang({ name, size, style, ...props }) {
    if (flags[name]) {
        return <Icon {...props} name={flags[name]} style={{ width: size, ...style }} />
    }

    return (
        <span className="text-gray-600 bg-gray-200 mr-1 rounded" style={{ padding: "2px 5px" }}>
            {name}
        </span>
    )
}

function TrackCommentsCount({ track }) {
    if (track.comments_count > 0)
        return (
            <div
                className="text-xs text-gray-500 bg-gray-200 rounded px-1 font-semibold"
                style={{ whiteSpace: "nowrap" }}
            >
                <Icon name="comments-o" style={{ paddingRight: 3 }} />{" "}
                <span className="text-gray-700">{track.comments_count}</span>
            </div>
        )
    return null
}
function TrackLikesCount({ track }) {
    if (track.likes_count > 0)
        return (
            <div
                className="text-xs text-gray-500 bg-gray-200 rounded px-1 font-semibold"
                style={{ whiteSpace: "nowrap" }}
            >
                <Icon name="thumbs-up" style={{ paddingRight: 3 }} />{" "}
                <span className="text-gray-700">{track.likes_count}</span>
            </div>
        )
    return null
}

export function TrackStats({ item }) {
    return (
        <React.Fragment>
            <TrackLikesCount track={item} />
            {item && item.likes_count > 0 && item.comments_count > 0 && <div style={{ width: 5 }} />}
            <TrackCommentsCount track={item} />
        </React.Fragment>
    )
}
