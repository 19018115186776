import React, { useMemo, useState, useEffect } from "react"
import { parse, getSubtitleByTime } from "../../utils/srt"
import { useCurrentTrack } from "../../utils/useCurrentTrack"

export default function Subtitles({ Player, ...props }) {
    const track = useCurrentTrack()
    const [currentText, setCurrentText] = useState("")

    const srt = useMemo(() => {
        if (track && track.video.traduction.text) return parse(track.video.traduction.text)
    }, [track])

    useEffect(() => {
        const timeout = setInterval(() => {
            if (!srt || !Player.current) return
            const sub = getSubtitleByTime({ srt }, Player.current.getCurrentTime())
            if (sub) setCurrentText(sub.text)
            else setCurrentText("")
        }, 50)

        return () => clearInterval(timeout)
    }, [srt])

    if (!currentText) return null

    return (
        <div {...props}>
            <div className="subtitle-content">{parseText(currentText)}</div>
        </div>
    )
}

const entities = [
    [new RegExp("&amp;", "g"), "&"],
    [new RegExp("&gt;", "g"), ">"],
]
function parseText(text) {
    if (!text) return

    for (const [from, to] of entities) {
        text = text.replace(from, to)
    }

    return text
}
