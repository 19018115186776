import { useMutation } from "urql"

import gql from "graphql-tag"
import fragments from "./_fragments"

export const TOP_ARTISTS_QUERY = gql`
    query Artists {
        artists {
            id
            slug
            name
            vues
            total_views
            track_count: tracks_count
            topTracks: tracks(orderBy: vues, limit: 5) {
                ...trackFragment
            }
        }
    }

    ${fragments.track}
`

export const ARTIST_QUERY = gql`
    query Artist($slug: String!) {
        artist(slug: $slug) {
            id
            name
            slug
            vues
            total_views
            track_without_album_count: tracks_without_album_count
            track_count: tracks_count
            albums_count
            feats_count
            latestTracks: tracks(orderBy: id, limit: 5) {
                ...trackFragment
            }
            topTracks: tracks(orderBy: vues, limit: 5) {
                ...trackFragment
            }
        }
    }

    ${fragments.track}
`

const TOGGLE_FAVOURITE_MUTATION = gql`
    mutation ToggleArtistFavourite($id: ID!, $value: Boolean!) {
        ToggleArtistFavourite(id: $id, value: $value)
    }
`
export function useMutationToggleFavourite() {
    return useMutation(TOGGLE_FAVOURITE_MUTATION)
}
