import { FlexColumn, FlexRow, Icon, Spacer } from "@marg51/react-ui"
import gql from "graphql-tag"
import React, { useState } from "react"
import { useQuery } from "urql"
import { SUGGESTION_QUERY, useMutationUpvoteSuggestion } from "../../api/suggestions"
import { GetContext } from "../../utils/context"
import IconButton from "../ui/IconButton"
import UserSignupPrompt from "../User/UserSignupPrompt"
import SuggestionItem from "./Item"
import SuggestionSearch from "./Search"

export default function SuggestionList() {
    const [result] = useQuery({ query: SUGGESTION_QUERY })

    return (
        <>
            <Header />
            <FlexColumn className="bg-white p-2 shadow rounded-b" style={{ padding: 15, position: "relative" }}>
                <FlexColumn style={{ maxHeight: 350, overflow: "auto" }}>
                    {result.data?.getSuggestions.map((e) => (
                        <>
                            <SuggestionItem data={e} />
                            <Spacer size="small" />
                        </>
                    ))}
                </FlexColumn>
                <Spacer />
                <div className="p-4 rounded bg-gray-200">
                    <SuggestionSearch />
                </div>
                {/* <div
                    id="bientot"
                    className="flex items-center justify-center"
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: "rgba(150,150,150,0.7)"
                    }}
                >
                    <div
                        className="font-semibold p-4 rounded-lg"
                        style={{ transform: "rotate(-13deg)", background: "rgba(250,250,250,0.5)" }}
                    >
                        Bientôt
                    </div>
                </div> */}
            </FlexColumn>
        </>
    )
}

const Header = () => {
    return (
        <FlexRow
            className="bg-gray-800 rounded-t"
            style={{
                height: 60,
                alignItems: "center",
                paddingLeft: 75,
                paddingRight: 15,
                fontSize: 12,
                alignItems: "center",
                zIndex: 1
            }}
        >
            {/* <div className="absolute bg-pink-500" style={{ left: -30, top: -10, transform: "rotate(-23deg)" }}>
                New!
            </div> */}
            <FlexColumn className="flex-1">
                <div className="font-semibold text-white uppercase">Vos demandes</div>
                <div className="text-gray-400">Suggérez de nouvelles musiques</div>
            </FlexColumn>
        </FlexRow>
    )
}
