import React, { useEffect, useState } from "react"
import { getContext } from "../utils/context"
import { useTracks } from "../api/tracks"

export default function PageTitle({ children }) {
    const context = getContext()
    const trackId = context.state.player.playlist[context.state.player.track_position]

    const { tracks } = useTracks(trackId ? [trackId] : [])

    let track = tracks[0]

    useEffect(() => {
        if (!children) {
            document.title = "TraduZic | La traduction de plus de 10 000 paroles de musiques en français"
        } else {
            document.title = children
        }
    }, [track])

    return null
}

export function TrackTitle() {
    const context = getContext()
    const trackId = context.state.player.playlist[context.state.player.track_position]
    const [title, setTitle] = useState()

    const { tracks } = useTracks(trackId ? [trackId] : [])

    let track = tracks[0]

    useEffect(() => {
        // prettier-ignore
        if (track) {
            const isPlaying = context.state.player.isPlaying
            const isPlayingStatus = isPlaying ? "►" : ""
            setTitle(`${isPlayingStatus} ${track.name} - ${track.artist.name}, traduction et vidéo sur TraduZic`)



        }
    }, [track])

    return <PageTitle>{title}</PageTitle>
}
