import React from "react"

export default function SvgPlayNext(props) {
    return (
        <React.Fragment>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 29 29"
                {...props}
            >
                <path d="M26 22.943H9a1 1 0 0 1 0-2h17a1 1 0 0 1 0 2z" />
                <path d="M26 18.943H9a1 1 0 0 1 0-2h17a1 1 0 0 1 0 2z" />
                <path d="M26 14.943H9a1 1 0 0 1 0-2h17a1 1 0 0 1 0 2z" />
                <path d="M26 10.943H13a1 1 0 0 1 0-2h13a1 1 0 0 1 0 2z" />
                <path d="M6 12.355V7.532c0-.368.401-.596.717-.408l4.052 2.412a.474.474 0 0 1 0 .815l-4.052 2.412A.475.475 0 0 1 6 12.355z" />
                <path d="M3 19.943a1 1 0 0 1-1-1v-7c0-1.654 1.346-3 3-3h3a1 1 0 0 1 0 2H5c-.551 0-1 .449-1 1v7a1 1 0 0 1-1 1z" />
            </svg>
        </React.Fragment>
    )
}
