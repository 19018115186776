import React from "react"
import { useQuery } from "urql"
import { getContext } from "../utils/context"
import PlaylistContext from "../utils/PlaylistContext"
import ActiveTrackSidebar from "./ActiveTrackSidebar"
import RenderUrqlError from "./ui/RenderUrqlError"
import { TRACKS_QUERY } from "../api/tracks"
import ActiveTrackSidebarLoader from "./ActiveTrackSidebarLoader"
import { useCurrentTrack } from "../utils/useCurrentTrack"

export default function RenderActiveTrack() {
    const context = getContext()
    const track = useCurrentTrack()

    if (!track) {
        return <ActiveTrackSidebarLoader />
    }

    return (
        <React.Fragment>
            <ActiveTrackSidebar context={context} track={track} />
        </React.Fragment>
    )
}
