import React, { useMemo } from "react"

import { useRouter } from "next/router"
import BaseHead from "Components/Head"
import { useQuery } from "api/urql"
import { TRACKS_QUERY_BY_SLUG } from "api/tracks"
import { useCurrentTrack } from "utils/useCurrentTrack"
import { getContext } from "utils/context"
import { useEffect } from "react"
import { get } from "api/_fetch"
import { ENDPOINT } from "config"
import TrackCard from "../../Components/Track/TrackCard/V2"
import PageContainer from "../../Components/ui/PageContainer"
import { FlexColumn } from "@marg51/react-ui"
import { ArtistContent } from "../artiste/[slug]"
import LdJson from "../../utils/LdJson"
import { parse } from "utils/srt"

export default function TraductionPage() {
    const {
        query: { params },
        push,
    } = useRouter()

    const [artistSlug, slug] = params

    const [{ data, fetching, error }, errorComponent] = useQuery(TRACKS_QUERY_BY_SLUG, { slug, artistSlug })

    // const [{ data, fetching, error }, errorComponent] = [{ data: {} }]

    const currentTrack = useCurrentTrack()
    const context = getContext()

    useEffect(() => {
        if (fetching || errorComponent || !data.getTrackBySlug) {
            return
        }

        if (!currentTrack) {
            context.setPlaylist([data.getTrackBySlug.id], "auto from track page")
            get(ENDPOINT + "api/playlist/traduzic").then((trackIds) =>
                context.setPlaylist([data.getTrackBySlug.id, ...trackIds], "auto from track page")
            )
        }
    }, [fetching, data, errorComponent, typeof currentTrack])

    // useEffect(() => {
    //     if (data && data.getTrackBySlug) {
    //         const track = data.getTrackBySlug
    //         if (track.artist.slug !== artistSlug) {
    //             push(`/traduction/${data.getTrackBySlug.artist.slug}/${track.slug}`)
    //         }
    //     }
    // }, [data])

    const fetchedTrack = data && data.getTrackBySlug

    const description = useMemo(() => {
        if (!fetchedTrack || !fetchedTrack.video.traduction.text) return ""
        return parse(fetchedTrack.video.traduction.text)
            .filter(({ text }) => !text.match(/traduzic|paroles|commence/i))
            .slice(0, 4)
            .map(({ text }) => text)
            .join("; ")
    }, [fetchedTrack])

    return (
        <React.Fragment>
            {fetchedTrack && (
                <React.Fragment>
                    {fetchedTrack.langue === "FR" ? (
                        <BaseHead
                            title={`${fetchedTrack.name} ► ${fetchedTrack.artist.name}, Paroles avec clip video sous-titré karaoké | French lyrics `}
                            url={`${ENDPOINT}traduction/${fetchedTrack.artist.slug}/${fetchedTrack.slug}`}
                            image={`${ENDPOINT}images/${fetchedTrack.artist.slug}-80x80.jpeg`}
                            description={`Les paroles en vidéo! « ${description} ...`}
                        >
                            <link
                                rel="canonical"
                                href={`https://traduzic.com/traduction/${fetchedTrack.artist.slug}/${fetchedTrack.slug}`}
                            />
                        </BaseHead>
                    ) : (
                        <BaseHead
                            title={`${fetchedTrack.name} ► ${fetchedTrack.artist.name}, Traduction des paroles en Français`}
                            url={`${ENDPOINT}traduction/${fetchedTrack.artist.slug}/${fetchedTrack.slug}`}
                            image={`${ENDPOINT}images/${fetchedTrack.artist.slug}-80x80.jpeg`}
                            description={`Le video clip sous-titré en français! « ${description} ...`}
                        >
                            <link
                                rel="canonical"
                                href={`https://traduzic.com/traduction/${fetchedTrack.artist.slug}/${fetchedTrack.slug}`}
                            />
                        </BaseHead>
                    )}
                    <LdJson
                        data={[
                            {
                                "@context": "https://schema.org",
                                "@type": "BreadcrumbList",
                                itemListElement: [
                                    {
                                        "@type": "ListItem",
                                        position: 1,
                                        name: "Traductions et paroles",
                                        item: ENDPOINT,
                                    },
                                    {
                                        "@type": "ListItem",
                                        position: 2,
                                        name: fetchedTrack.artist.name,
                                        item: `${ENDPOINT}artiste/${fetchedTrack.artist.slug}`,
                                    },
                                    {
                                        "@type": "ListItem",
                                        position: 3,
                                        name: fetchedTrack.name,
                                    },
                                ],
                            },
                            {
                                "@context": "https://schema.org",
                                "@type": "VideoObject",
                                name: `${fetchedTrack.name} - ${fetchedTrack.artist.name}`,
                                description: `Découvrez le video clip sous-titré en français ${fetchedTrack.name} - ${fetchedTrack.artist.name}`,
                                thumbnailUrl: `https://i.ytimg.com/vi/${fetchedTrack.video.url}/hqdefault.jpg`,
                                contentUrl: `https://www.youtube.com/embed/${fetchedTrack.video.url}`,
                                uploadDate: new Date(parseInt(fetchedTrack.video.created_at)).toISOString(),
                            },
                        ]}
                    />
                </React.Fragment>
            )}
            {context.state.isDesktop
                ? fetchedTrack && <ArtistContent slug={fetchedTrack.artist.slug} />
                : fetchedTrack && (
                      <PageContainer>
                          <FlexColumn>
                              <TrackCard item={fetchedTrack} />
                          </FlexColumn>
                      </PageContainer>
                  )}
        </React.Fragment>
    )
}
