import React, { useRef, useEffect, useState } from "react"
import cx from "classnames"
import YouTube from "react-youtube"
import { getContext } from "../../utils/context"
import Subtitles from "./Subtitles"
import { usePlaylist } from "../../utils/PlaylistContext"
import LdJson from "../../utils/LdJson"
import { useGaEvent } from "../../trackers/ga"

export default function YtPlayer({ options, track }) {
    const context = getContext()
    const trackEvent = useGaEvent()
    const playlist = usePlaylist()

    const player = useRef()

    // it won't allow multiple players
    context.playerRef.current = player.current

    if (!track) {
        return null
    }

    // const trackIndex = context.state.player.playlist.indexOf(track.id)

    // const playlistItems = playlist.slice(trackIndex + 1)

    const opts = {
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: true,
            origin: "https://traduzic.com",
            playsinline: 1,
            cc_load_policy: 0,
            fs: 0,
            // playlist: playlistItems.map(({ video: { url } }) => url).join(),
        },
        ...options,
    }

    let style = { position: "relative" }

    return (
        <React.Fragment>
            <div style={style} className={cx("player", { isPaused: !context.state.player.isPlaying })}>
                <div>
                    <YouTube
                        videoId={track.video.url}
                        opts={opts}
                        onStateChange={({ data }) => {
                            context.setPlayerStatus(mapPlayerStateToStatus(data))
                        }}
                        onReady={(event) => {
                            player.current = event.target
                            console.log("ready")
                            // player.current.playVideo()
                        }}
                        onPlay={() => {
                            console.log("play")

                            context.play()
                        }}
                        onPause={() => {
                            console.log("pause")

                            // context.pause()
                        }}
                        onEnd={() => context.playNext("end of previous video")}
                        onError={(error) => {
                            console.log("error", error)
                            setTimeout(context.playNext, 250)
                        }}
                    />
                </div>
                {context.state.isDesktop && (
                    <Subtitles
                        Player={player}
                        className={`player-subtitles rounded ${context.state.isDesktop ? "desktop" : "mobile"} ${
                            context.state.player.isFullScreen ? "fullscreen" : ""
                        }`}
                    />
                )}
                {!context.state.isDesktop && <Subtitles Player={player} className="player-subtitles" />}
            </div>
        </React.Fragment>
    )
}

// export default memo(YtPlayer)

export function usePlayerProgress() {
    const context = getContext()
    const [duration, setDuration] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            if (!context.playerRef) {
                return
            }
            const player = context.playerRef.current

            if (player) setDuration(Math.floor((player.getCurrentTime() / player.getDuration()) * 100))
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    return duration
}

function mapPlayerStateToStatus(data) {
    switch (data) {
        case -1:
            return "unstarted"
        case 0:
            return "ended"
        case 1:
            return "playing"
        case 2:
            return "paused"
        case 3:
            return "buffering"
        case 5:
            return "cued"
    }
}
