import React, { useEffect, useState } from "react"
import gql from "graphql-tag"

import { useQuery } from "urql"
import { GetContext } from "./context"

import bugsnagClient from "trackers/bugsnag"
import { localStorageSetItem, localStorageGetItem } from "./localStorage"
import { useAuth0 } from "auth/Provider"

export default function UserData() {
    const context = GetContext()
    const [token, setToken] = useState()
    const { isAuthenticated, loading, getTokenSilently, user } = useAuth0()

    useEffect(() => {
        async function fetchData() {
            const token = await getTokenSilently()

            setToken(token)

            localStorageSetItem("tz.auth0_token", token)
        }
        if (isAuthenticated) {
            fetchData()
        }
    }, [isAuthenticated])

    if (localStorageGetItem("urql.token")) {
        return <FetchData />
    }

    if (loading || !isAuthenticated || !token) {
        return null
    }

    return <FetchData />
}
function FetchData({ token }) {
    const context = GetContext()

    const [result] = useQuery({ query: QUERY })

    useEffect(() => {
        if (result && result.data && !result.error) {
            // duplicated Login
            const { track_favourites, artist_favourites, tracks_liked, comments_liked, ...userData } = result.data.me

            context.setUserData(
                userData,
                toObject(track_favourites),
                toObject(tracks_liked),
                toObject(comments_liked),
                toObject(artist_favourites)
            )
            localStorageSetItem("tz.user", JSON.stringify(userData))
            bugsnagClient.user = {
                id: userData.id,
                username: userData.username,
            }

            if (window.FS) {
                window.FS.identify(userData.id, {
                    displayName: userData.username,
                    email: userData.email_address,
                })
            } else {
                setTimeout(() => {
                    if (window.FS) {
                        window.FS.identify(userData.id, {
                            displayName: userData.username,
                            email: userData.email_address,
                        })
                    }
                }, 8000)
            }
        }
    }, [result.fetching])

    return null
}

const QUERY = gql`
    query me {
        me {
            id
            username
            auth0_id
            email
            role
            tracks_liked {
                id
            }
            comments_liked {
                id
            }
            track_favourites {
                id
            }
            artist_favourites {
                id
            }
        }
    }
`

function toObject(items) {
    const list = {}
    for (const { id } of items) {
        list[id] = true
    }

    return list
}
