import { useQuery, useMutation } from "urql"
import fragments from "./_fragments"
import gql from "graphql-tag"

const Search = gql`
    mutation Search($query: String!) {
        Search(query: $query) {
            artists {
                ...artistFragment
            }
            tracks {
                ...trackFragment
            }
            artists_count
            tracks_count
        }
    }
    ${fragments.artist}
    ${fragments.track}
`
export function useMutationSearch() {
    return useMutation(Search)
}