import React, { useRef, useEffect, useState } from "react"
import bugsnagClient from "trackers/bugsnag"

import { FlexColumn, Spacer, Icon } from "@marg51/react-ui"

import { useQuery } from "api/urql"
import { GetContext } from "utils/context"
import * as TrackList from "Components/Track/TrackList"
import RenderUrqlError from "Components/ui/RenderUrqlError"
import PageContainer from "Components/ui/PageContainer"
import PageHeader from "Components/ui/PageHeader"
import TrackListLoader from "Components/Track/TrackListLoader"
import RenderArtistTop from "Components/Artist/FetchAndRenderArtistTop"
import FetchAndRenderFeats from "Components/Artist/FetchAndRenderFeats"
import FetchAndRenderAlbums from "Components/Artist/FetchAndRenderAlbums"

import useHasBeenOnScreen from "utils/useHasBeenOnScreen"
import { useRouter } from "next/router"
import { ARTIST_QUERY } from "../../api/artists"
import BaseHead from "../../Components/Head"
import { ENDPOINT } from "config"
import LdJson from "../../utils/LdJson"
import { useCurrentTrack } from "../../utils/useCurrentTrack"
import { getContext } from "../../utils/context"
import { get } from "../../api/_fetch"
import ArtistFavourite, { ArtistFavouriteStar } from "../../Components/Artist/ArtistFavourite"

export const Block = TrackList.V3

const TRACKS_COUNT_TO_DISPLAY_EXTENDED_VIEW = 10

export default function ArtistPage() {
    const {
        query: { slug },
    } = useRouter()

    return <ArtistContent slug={slug} showHeader />
}

export function ArtistContent(props) {
    const {
        query: { ssr },
    } = useRouter()
    const [isOpen, open] = useState(ssr)

    useEffect(() => {
        if (isOpen) return null
        setTimeout(() => open(true), 0)
    }, [])

    return isOpen ? <RealArtistContent {...props} /> : null
}

function RealArtistContent({ slug, showHeader }) {
    const [{ fetching, data }, ErrorComp] = useQuery(ARTIST_QUERY, { slug })
    const currentTrack = useCurrentTrack()

    const context = getContext()

    useEffect(() => {
        if (data && data.artist) {
            if (!currentTrack) {
                const ids = data.artist.latestTracks.map(({ id }) => id)
                // this should work but I don't know why it doesn't with cra :(
                context.setPlaylist([...ids], "/artiste latest tracks")
                get(ENDPOINT + "api/playlist/traduzic").then((trackIds) =>
                    context.setPlaylist([...ids, ...trackIds], "/artiste latest tracks")
                )
            }
        }
    }, [fetching])

    if (ErrorComp) {
        return ErrorComp
    }
    if (fetching || !data || !data.artist)
        return (
            <PageContainer header={<ArtistHeader item={{ name: slug, slug, albums: [] }} />}>
                <FlexColumn>
                    <TrackListLoader />
                    <Spacer size="large" />
                    <TrackListLoader />
                </FlexColumn>
            </PageContainer>
        )

    const item = data.artist

    return (
        <PageContainer header={<ArtistHeader item={item} />}>
            {showHeader &&
                (item.track_count > 1 ? (
                    <BaseHead
                        title={`${item.name} ► La traduction des paroles en video`}
                        image={`${ENDPOINT}images/${item.slug}-80x80.jpeg`}
                        description={`Découvrez ${item.track_count} musiques par ${item.name} traduites et sous-titrées, et pleins d'autres sur TraduZic`}
                        url={ENDPOINT + "artiste/" + item.slug}
                    >
                        <link rel="canonical" href={`https://traduzic.com/artiste/${item.slug}`} />
                    </BaseHead>
                ) : (
                    <BaseHead
                        title={`Profil de ${item.name} ► Traductions des paroles de ses videos`}
                        image={`${ENDPOINT}images/${item.slug}-80x80.jpeg`}
                        description={`Découvrez les musiques de ${item.name} traduites et sous-titrées, et pleins d'autres sur TraduZic`}
                        url={ENDPOINT + "artiste/" + item.slug}
                    >
                        <link rel="canonical" href={`https://traduzic.com/artiste/${item.slug}`} />
                    </BaseHead>
                ))}
            <FlexColumn>
                <div style={{ alignSelf: "flex-end" }}>
                    <ArtistFavourite artist={item} small />
                </div>
                <Spacer />
                {item.track_count >= TRACKS_COUNT_TO_DISPLAY_EXTENDED_VIEW && <RenderArtistTop item={item} />}
                <FetchAndRenderAlbums item={item} />
                <Spacer size="large" />

                {item.track_count < TRACKS_COUNT_TO_DISPLAY_EXTENDED_VIEW && <RenderFeatsAndSingles item={item} />}
            </FlexColumn>
        </PageContainer>
    )
}

function ArtistHeader({ item }) {
    const context = GetContext()

    return (
        <PageHeader image={item.slug}>
            <FlexColumn className="flex-1">
                <LdJson
                    data={{
                        "@context": "http://schema.org",
                        "@type": "MusicGroup",
                        name: item.name,
                        image: `${ENDPOINT}images/${item.slug}-80x80.jpeg`,
                    }}
                />
                <div className="font-semibold">
                    {item.name} <ArtistFavouriteStar artist={item} className="text-gray-700" />
                </div>
                <div className="text-gray-700" style={{ height: 20 }}>
                    {item.track_count >= 0 && `${item.track_count} vidéo${item.track_count >= 2 ? "s" : ""}`}
                    {item.albums_count > 1 && (
                        <React.Fragment>
                            <span className="mx-2 text-gray-500">•</span>
                            <span>{item.albums_count} albums</span>
                        </React.Fragment>
                    )}
                </div>
            </FlexColumn>

            {item.track_count >= 1 && (
                <div>
                    <div
                        className="text-green-800 pl-2 clickable"
                        onClick={() =>
                            context.setPlaylist(
                                [...item.latestTracks.map(({ id }) => id), ...item.topTracks.map(({ id }) => id)],
                                "artist play all"
                            )
                        }
                    >
                        <Icon name="play-circle" style={{ fontSize: 40 }} />
                    </div>
                </div>
            )}
        </PageHeader>
    )
}

function RenderFeatsAndSingles({ item }) {
    const ref = useRef()
    const isVisible = useHasBeenOnScreen(ref, "100px")

    if (!isVisible) return <div ref={ref} />

    return <FetchAndRenderFeats item={item} />
}
