import React, { useState } from "react"
import { V3 as Block } from "../Track/TrackList"
import { useQuery } from "api/urql"
import ButtonShowMore from "../ui/ButtonShowMore"
import TrackListLoader from "../Track/TrackListLoader"
import { Spacer } from "@marg51/react-ui"

import gql from "graphql-tag"
import fragments from "../../api/_fragments"

export const SINGLES_QUERY = gql`
    query Artist($slug: String!) {
        artist(slug: $slug) {
            id
            tracks_without_album {
                ...trackFragment
            }
        }
    }
    ${fragments.track}
`
export default function FetchAndRenderSingles({ item }) {
    const [displayAll, setDisplayAll] = useState(false)
    const [{ fetching, data }, errorComponent] = useQuery(SINGLES_QUERY, { slug: item.slug })
    if (errorComponent) {
        return errorComponent
    }
    if (fetching) {
        return (
            <React.Fragment>
                <TrackListLoader />
                <Spacer size="large" />
            </React.Fragment>
        )
    }
    const singles = data.artist.tracks_without_album
    const items = singles.length >= 10 && !displayAll ? singles.slice(0, 5) : singles

    if (items.length === 0) return null
    return (
        <Block items={items} title="Titres sans album" listName="singles">
            {items.length !== singles.length && (
                <ButtonShowMore onClick={() => setDisplayAll(true)}>
                    Afficher les {singles.length - items.length} autres vidéos
                </ButtonShowMore>
            )}
        </Block>
    )
}
