import React from "react"
import Playlist from "Components/Playlist/Playlist"
import Page from "Components/ui/Page"
import BaseHead from "../Components/Head"

export default function PlaylistPage() {
    return (
        <Page>
            <BaseHead
                title="Liste de lecture | TraduZic"
                description="Comprenez vos artistes favoris facilement grâce à nos vidéos traduites et sous-titrées à la main"
            />
            <Playlist />
        </Page>
    )
}
