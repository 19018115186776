import React, { useRef } from "react"
import { createPortal } from "react-dom"
import { FlexColumn, OnOutsideClick } from "@marg51/react-ui"
import { getContext } from "../../utils/context"
import useOnClickOutside from "../../utils/useOutsideClick"
import { HierarchieProvider } from "Components/Track/HierarchieContext"

export default function Popup({ isOpen, onRequestClose, image, style, children }) {
    const ref = useRef()
    useOnClickOutside(ref, onRequestClose)

    if (!isOpen) return null

    const context = getContext()
    if (context.state.isDesktop) {
        return (
            <HierarchieProvider value="Popup">
                <div style={{ position: "relative" }} ref={ref}>
                    <div
                        className="rounded"
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            zIndex: 2,
                            width: 460,
                            background: "rgba(200,200,200,0.8)",
                            padding: 15,
                            overflow: "auto",
                            ...style,
                        }}
                    >
                        {children}
                    </div>
                </div>
            </HierarchieProvider>
        )
    }

    const render = (
        <HierarchieProvider value="Popup">
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 5,
                    height: "100vh",
                    background: "rgba(100,100,100,0.9)",
                    padding: "20px 10px 30px",
                    overflow: "auto",
                }}
                onClick={onRequestClose}
            >
                {children}
            </div>
        </HierarchieProvider>
    )

    return createPortal(render, document.getElementById("ui_modal"))
}
