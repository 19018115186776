import React from "react"
import { FlexRow, Spacer, Icon, FlexColumn } from "@marg51/react-ui"
import { GetContext } from "../../utils/context"
import { useMutationToggleFavourite } from "../../api/tracks"
import IconButton from "../ui/IconButton"
import useUser from "utils/useUser"
import UserSignupPrompt from "Components/User/UserSignupPrompt"
import { Button } from "@marg51/react-ui/dist/index.es"

export function useAddTrackToFavourites(trackId) {
    const { state, addTrackFavourite, removeTrackFavourite } = GetContext()

    const [stateMutation, executeMutation] = useMutationToggleFavourite()

    const isFavourite = state.favourites[trackId]

    return [
        isFavourite,
        () => {
            if (isFavourite) removeTrackFavourite(trackId)
            else addTrackFavourite(trackId)
            executeMutation({ id: trackId, value: !isFavourite })
        },
    ]
}

export default function TrackFavourite({ track, small = false }) {
    const { state } = GetContext()
    const user = useUser()
    const [isFavourite, toggleFavs] = useAddTrackToFavourites(track.id)

    const color = isFavourite ? "blue" : "gray"

    const Container = user ? React.Fragment : UserSignupPrompt

    if (small) {
        return (
            <Container label={`ajouter ${track.name} à vos favoris`} style={{ width: "auto", left: 0 }}>
                <div className="clickable" onClick={toggleFavs}>
                    <IconButton
                        icon={isFavourite ? "heart" : "heart-o"}
                        name={isFavourite ? "Supprimer des favoris" : "Ajouter aux favoris"}
                        theme={color}
                    />
                </div>
            </Container>
        )
    }

    return (
        <Container label={`ajouter ${track.name} à vos favoris`} style={{ width: "auto", left: 0 }}>
            <Button type="primary" theme="gray" icon={isFavourite ? "heart" : "heart-o"} onClick={toggleFavs}>
                {isFavourite ? "Supprimer la vidéo des favoris" : "Ajouter la vidéo aux favoris"}
            </Button>
        </Container>
    )
}
