import React, { createContext, useContext } from "react"

const context = createContext()

export const Provider = ({ children, value }) => {
    const parent = useHierarchieContext()

    const name = parent ? parent + " - " + value : value

    return <context.Provider value={name || "undefined"} children={children} />
}

export const HierarchieProvider = Provider

export const useHierarchieContext = () => useContext(context)

const pathContext = createContext()

export const PathProvider = ({ children, value }) => {
    return <pathContext.Provider value={value || "/"} children={children} />
}

export const usePathContext = () => useContext(pathContext)
