import React from "react"
import { palet, FlexColumn, FlexRow, Spacer, Icon, Button } from "@marg51/react-ui"
import SmallImage from "./SmallImage"
import { getContext } from "../../utils/context"
import Link from "next/link"
import PageContainer from "./PageContainer"
import PageHeader from "./PageHeader"

export default function Page({ title, subtitle, image, header, rawHeader, headerStyle, style, children }) {
    const context = getContext()

    const headerComponent = header ? <PageHeader image={image}>{header}</PageHeader> : rawHeader || null

    return <PageContainer header={headerComponent}>{children}</PageContainer>
}

Page.Header = ({ children, image, playlist, subtitle, imageTo }) => {
    const context = getContext()
    throw new Error("where is it used")

    return (
        <FlexRow
            className="border border-gray-400 bg-white text-gray-800"
            style={{
                padding: "0 15px",
                textTransform: "uppercase",
                fontSize: 12,
                alignItems: "center",
                height: 46,
                borderRadius: 30,
            }}
            nowrap
        >
            {image ? (
                <div style={{ marginRight: 16 }} _className="rounded border-2 border-white">
                    {imageTo ? (
                        <Link href={imageTo}>
                            <a>
                                <SmallImage src={image} style={{ borderRadius: 30 }} />
                            </a>
                        </Link>
                    ) : (
                        <SmallImage src={image} />
                    )}
                </div>
            ) : (
                <div style={{ width: 50 }} />
            )}
            {children}
            <Spacer />
            <div className="text-gray-500">{subtitle}</div>
            <div style={{ flex: 1 }} />
            <Spacer />
            {playlist ? (
                <button onClick={() => context.setPlaylist(playlist.map(_ => _.id))}>
                    <Icon name="play-circle" style={{ fontSize: 30 }} />
                </button>
            ) : null}
        </FlexRow>
    )
}

Page.Loading = () => <Page />
