import React, { useState } from "react"
import { Spacer } from "@marg51/react-ui"
import ButtonSelect from "../ui/ButtonSelect"
import { V3 as Block } from "../Track/TrackList"
import formatNumber from "utils/numbers"
import gql from "graphql-tag"
import fragments from "../../api/_fragments"
import { useQuery } from "api/urql"
import ButtonShowMore from "../ui/ButtonShowMore"
import TrackListLoader from "../Track/TrackListLoader"

const FEATS_QUERY = gql`
    query Artist($slug: String!) {
        artist(slug: $slug) {
            id
            feat_tracks {
                ...trackFragment
            }
        }
    }
    ${fragments.track}
`
export default function RenderFeats({ item }) {
    const [displayAll, setDisplayAll] = useState(0)
    const [{ fetching, data }, errorComponent] = useQuery(FEATS_QUERY, { slug: item.slug })

    if (errorComponent) {
        return errorComponent
    }

    if (fetching) {
        return (
            <React.Fragment>
                <TrackListLoader />
                <Spacer size="large" />
            </React.Fragment>
        )
    }
    const artist = data.artist

    const feat_tracks = artist.feat_tracks
    const items =
        feat_tracks.length >= 10 && !displayAll ? feat_tracks.slice(0, 5) : feat_tracks.slice(0, 5 + displayAll)

    return items.length > 0 ? (
        <Block
            listName="feats"
            image={item.slug}
            subtitle={artist.feat_tracks.length + " feats"}
            title={`${item.name}'s feats`}
            items={items}
            showArtist
            showArtistImage
        >
            {items.length !== feat_tracks.length && (
                <ButtonShowMore onClick={() => setDisplayAll(displayAll + 10)}>
                    Afficher {Math.min(10, feat_tracks.length - items.length)} autres vidéos
                </ButtonShowMore>
            )}
        </Block>
    ) : null
}
