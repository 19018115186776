import { useState, useEffect } from "react"

export default function useHasBeenOnScreen(ref, rootMargin = "0px") {
    // State and setter for storing whether element is visible

    const [isIntersecting, setIntersecting] = useState(false)

    useEffect(() => {
        if (typeof IntersectionObserver === "undefined") {
            if (!isIntersecting) setIntersecting(true)
            return
        }
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Update our state when observer callback fires
                if (entry.isIntersecting) {
                    setIntersecting(entry.isIntersecting)
                    try {
                        observer.unobserve(ref.current)
                    } catch (e) {
                        console.info(e.message)
                    }
                }
            },

            {
                rootMargin,
            }
        )

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => {
            if (!isIntersecting && ref.current) observer.unobserve(ref.current)
        }
    }, []) // Empty array ensures that effect is only run on mount and unmount

    return isIntersecting
}
