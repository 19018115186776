import { Button, FlexColumn, FlexRow, Spacer } from "@marg51/react-ui"
import React, { useState } from "react"
import { useMutationUpvoteSuggestion } from "../../api/suggestions"
import { GetContext } from "../../utils/context"
import InlineTrack from "../Track/InlineTrack"
import IconButton from "../ui/IconButton"
import UserSignupPrompt from "../User/UserSignupPrompt"
import SuggestionTranslation from "./Translation"

const Div = (props) => <div {...props} />
export default function SuggestionItem({ data }) {
    const [stateMutation, executeMutation] = useMutationUpvoteSuggestion()
    const [openModal, setOpenModal] = useState(false)
    const context = GetContext()

    const isLoggedIn = context.state.user && context.state.user.username
    const isAdmin = isLoggedIn && context.state.user.role === "admin"

    const Wrapper = isLoggedIn ? Div : UserSignupPrompt

    return (
        <FlexRow className="items-center rounded" style={data._isNew ? { border: "1px solid #059669" } : {}} nowrap>
            <FlexColumn style={data.track ? { borderRight: "4px solid #10b981", borderRadius: 0 } : {}}>
                <div
                    className="font-bold text-lg text-gray-700 flex justify-center rounded-lg bg-gray-100 items-center p-1"
                    style={data.track ? { opacity: 0, width: 50 } : {}}
                >
                    <div className=" flex items-center justify-center px-2" style={{ width: 35 }}>
                        {data.upvotes}
                    </div>
                    <Wrapper style={{ left: -50 }}>
                        <IconButton
                            className="bg-gray-400"
                            icon="thumbs-up"
                            isActive
                            onClick={() => executeMutation({ id: data.id })}
                        />
                    </Wrapper>
                </div>
                {isAdmin && (
                    <Button type="tertiary" onClick={() => setOpenModal(true)}>
                        Modifier
                    </Button>
                )}
            </FlexColumn>
            <Spacer />
            {data.track ? (
                <InlineTrack item={data.track} showArtist showArtistImage className="flex-1" />
            ) : (
                <>
                    <div>
                        <img
                            src={data.artist?.image || "https://i.ytimg.com/vi/" + data.youtube_url + "/hqdefault.jpg"}
                            style={{ width: data.artist?.image ? 30 : 45, height: 30 }}
                            className="rounded"
                        />
                    </div>
                    <Spacer />

                    <FlexColumn>
                        <FlexRow>
                            <div className="text-gray-700 font-semibold ellipsis">{data.name}</div>
                            <div className="w-5">&nbsp;</div>
                            <div className="text-gray-600 ellipsis">{data.artist_name}</div>
                        </FlexRow>
                        {data.status === "in_progress" && (
                            <div className="text-gray-600 text-sm italic font-bold">En cours de traduction</div>
                        )}
                        {data.status === "completed" && (
                            <div className="text-gray-600 text-sm italic">Traduction terminée</div>
                        )}
                    </FlexColumn>
                    <Spacer />
                    {/* {isLoggedIn && !isAdmin && (
                        <>
                            <div className="flex-1" />
                            <Button type="tertiary" onClick={() => setOpenModal(true)}>
                                Traduire
                            </Button>
                        </>
                    )} */}
                </>
            )}

            <SuggestionTranslation
                isOpen={openModal}
                suggestionId={data.id}
                onRequestClose={() => setOpenModal(false)}
            />
        </FlexRow>
    )
}
