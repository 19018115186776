import React from "react"
// import ReactMetaTags from "react-meta-tags"
import ReactMetaTags from "next/head"

export default function BaseHead({ title, description, image, url, children }) {
    return (
        <ReactMetaTags>
            <title>{title}</title>
            <meta name="description" content={description} />

            <meta property="twitter:card" content="summary" />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:url" content={url} />
            <meta property="twitter:description" content={description} />

            <meta property="twitter:image" content={image} />

            <meta property="og:title" content={title} />
            <meta property="og:type" content="song" />
            <meta property="og:image" content={image} />
            <meta property="og:site_name" content="TraduZic.com" />
            <meta property="og:description" content={description} />

            <link rel="canonical" href={url} />
            {children}
        </ReactMetaTags>
    )
}
