import React from "react"
import { FlexColumn, FlexRow, Spacer, Input } from "@marg51/react-ui"

export default function SearchInput({ setValue, onFocus, style }) {
    return (
        <Input
            onChange={setValue}
            _onFocus={onFocus}
            style={style}
            className="flex-1 rounded-full"
            placeholder="Recherchez un artiste, une musique"
        />
    )
}
