import React from "react"
import { FlexRow, Spacer, Icon, FlexColumn } from "@marg51/react-ui"
import { GetContext } from "../../utils/context"
import { useMutationToggleLike } from "../../api/comments"
import IconButton from "../ui/IconButton"
import { useGaEvent } from "../../trackers/ga"

export default function CommentLike({ item, track, small = false }) {
    const trackEvent = useGaEvent()
    const { state, addCommentLike, removeCommentLike } = GetContext()

    const [stateMutation, executeMutation] = useMutationToggleLike()

    if (!state.user) {
        return (
            <FlexRow
                nowrap
                style={{ alignItems: "center" }}
                className={`bg-gray-100 disabled text-gray-600 rounded px-2 text-xs`}
            >
                {item.likes_count}
                <Spacer size="small" />
                <Icon name="thumbs-up" style={{ fontSize: 12 }} />
            </FlexRow>
        )
    }

    if (small) {
        throw new Error("this isn't supported anymore")
    }

    const isLiked = state.comments_liked && state.comments_liked[item.id]

    const color = isLiked ? "blue" : "gray"

    return (
        <div
            className="clickable"
            onClick={() => {
                if (isLiked) {
                    removeCommentLike(item.id)
                    trackEvent("event", {
                        category: "comment",
                        action: "un-like",
                        label: `${track.name} - ${track.artist}`,
                    })
                } else {
                    addCommentLike(item.id)
                    trackEvent("event", {
                        category: "comment",
                        action: "like",
                        label: `${track.name} - ${track.artist}`,
                    })
                }
                executeMutation({ id: item.id })
            }}
        >
            <FlexRow
                nowrap
                style={{ alignItems: "center" }}
                className={`bg-${color}-100 shadow text-${color}-600 rounded px-2 text-xs`}
            >
                {item.likes_count}
                <Spacer size="small" />
                <Icon name="thumbs-up" style={{ fontSize: 12 }} />
            </FlexRow>
        </div>
    )
}
