import React, { useState } from "react"
import { Button, Spacer } from "@marg51/react-ui"
import { CommentV2 } from "./Comment_listV2"
import { useQuery } from "api/urql"
import { COMMENT_LIST } from "../../api/comments"
import CommentListLoader from "./CommentListLoader"
export function CommentListV2({ track, limit = 100 }) {
    const [actualLimit, setLimit] = useState(limit)

    const [result, errorComponent] = useQuery(COMMENT_LIST, {
        track_id: parseInt(track.id),
    })

    if (errorComponent) {
        return errorComponent
    }
    if (result.fetching) {
        return <CommentListLoader />
    }

    const data = result.data.comments.filter(
        ({ created_at }) => created_at > Date.now() - 1000 * 60 * 60 * 24 * 30 * 2 // 2 mois
    )

    return (
        <React.Fragment>
            {data.length > actualLimit && (
                <React.Fragment>
                    <div className="self-center">
                        <Button type="tertiary" onClick={() => setLimit(actualLimit + limit)}>
                            Afficher les commentaires précédents
                        </Button>
                    </div>
                    <Spacer />
                </React.Fragment>
            )}
            {data
                .slice(0, actualLimit)
                .reverse()
                .map(comment => (
                    <React.Fragment>
                        <CommentV2 item={comment} track={track} />
                        <Spacer />
                    </React.Fragment>
                ))}
        </React.Fragment>
    )
}
