import React, { useEffect, useMemo, useRef } from "react"
import { FlexColumn, FlexRow } from "@marg51/react-ui"
import YtPlayer from "./Player/YtPlayer"
import PlayerControls from "./Player/PlayerControls"
import { V2 as Track } from "./Track/TrackCard"
import ActiveTrackSidebarLoader from "./ActiveTrackSidebarLoader"
import usePlayerSize from "../utils/usePlayerSize"
import { getContext } from "../utils/context"
import { useMutationAddTrackVue } from "../api/tracks"
import { useGaEvent } from "trackers/ga"
import { Provider as HierarchieProvider } from "Components/Track/HierarchieContext"

export default function ActiveTrackSidebar({ track }) {
    const context = getContext()

    if (!track) return <ActiveTrackSidebarLoader />
    return (
        <HierarchieProvider value="ActiveTrackSidebar">
            <FlexRow
                className={context.state.player.isFullScreen && "clickable"}
                style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    height:
                        context.state.player.isFullScreen || !context.state.isDesktop
                            ? undefined
                            : "calc(100vh - 25px)",
                }}
                onClick={() => {
                    context.state.player.isFullScreen && context.toggleFullScreen()
                }}
            >
                <FlexColumn className="flex-1">
                    <Player track={track} />
                    {!context.state.player.isFullScreen && context.state.isDesktop && <CurrentTrack track={track} />}
                </FlexColumn>
            </FlexRow>
        </HierarchieProvider>
    )
}

function Player({ track }) {
    const [width, height] = usePlayerSize()
    const context = getContext()
    const [, doAddTrackVue] = useMutationAddTrackVue()
    const trackEvent = useGaEvent()
    const currentTrack = useRef()

    if (track && currentTrack.current !== track.id) {
        if (currentTrack.current) {
            const player = context.playerRef.current
            try {
                const percentage = Math.floor((player.getCurrentTime() / player.getDuration()) * 10)

                if (!(percentage >= 0)) {
                    console.log("stopped", player.getCurrentTime(), player.getDuration())
                } else {
                    const action = `${percentage}0-${percentage + 1}0%`
                    trackEvent(
                        "event",
                        { category: "LeftTrack", action, label: `${track.name} - ${track.artist.name}` },
                        { 4: track.artist.name }
                    )
                }
            } catch (e) {
                console.log("failed to get percentage", e)
            }
        }
        currentTrack.current = track.id
    }

    // update size
    useEffect(() => {
        if (context.playerRef.current) context.playerRef.current.setSize(width, height)
    }, [width, height, context.playerRef.current])

    // notify vue, after 15s if player
    useEffect(() => {
        if (!context.state.player.isPlaying) return

        const timeoutId = setTimeout(() => {
            doAddTrackVue({ track_id: track.id })
            trackEvent("event", {
                action: "video_view",
                category: "video",
                label: track.artist.name + " - " + track.name,
                nonInteraction: true,
            })
            trackEvent("event", {
                action: context.state.player._origin_of_playlist[context.state.player.track_position],
                category: "playlist",
                label: context.state.player._origin_of_tracks[context.state.player.track_position],
                nonInteraction: true,
            })
        }, 15000)

        return () => clearTimeout(timeoutId)
    }, [track.id, context.state.player.isPlaying])

    const options = useMemo(() => {
        return { width, height }
    }, [])

    return (
        <div
            className="bg-white rounded"
            style={{ width, marginLeft: "auto", marginRight: "auto", cursor: "default" }}
            onClick={(event) => event.stopPropagation()}
        >
            <div
                style={{
                    width,
                    height,
                    background: "black",
                    overflow: "hidden",
                }}
                className="rounded-t"
            >
                <YtPlayer options={options} track={track} />
            </div>
            {context.state.isDesktop && (
                <div className="rounded-b border border-gray-300 border-t-0">
                    <PlayerControls />
                </div>
            )}
        </div>
    )
}

function CurrentTrack({ track }) {
    const playerSize = usePlayerSize()

    return (
        <div className="mx-auto" style={{ overflow: "auto", padding: "5px 0", width: playerSize[0], marginTop: 15 }}>
            <Track item={track} />
        </div>
    )
}
