import React from "react"
import { FlexRow, Spacer, Icon, Button, Input } from "@marg51/react-ui"
import { getContext } from "../../utils/context"
import { usePlayerProgress } from "./YtPlayer"
import { TrackLikeBase } from "../Track/TrackLike"
import { useCurrentTrack } from "../../utils/useCurrentTrack"
import { ShareLink } from "./ShareLink"
import { useGaEvent } from "../../trackers/ga"

const noop = () => {}

export default function PlayerControls() {
    const context = getContext()
    const isFullScreen = context.state.player.isFullScreen
    const isDesktop = context.state.isDesktop
    const track = useCurrentTrack()
    const trackEvent = useGaEvent()

    const padding = isDesktop ? 15 : "5px 10px"
    return (
        <React.Fragment>
            <FlexRow
                className="text-gray-800"
                style={{ flex: 1, alignItems: "center", justifyContent: "space-around" }}
                nowrap
            >
                <div style={{ flexBasis: 130, flexShrink: 1 }} className="text-center">
                    <TrackLikeBase track={track}>
                        {({ isLiked }) => (
                            <Button
                                icon="thumbs-up"
                                className="px-1"
                                style={{ paddingRight: 20 }}
                                type={isLiked ? "primary" : "tertiary"}
                                theme={isLiked ? "blue" : "gray"}
                            ></Button>
                        )}
                    </TrackLikeBase>
                </div>
                <div>
                    <FlexRow nowrap style={{ alignItems: "center", justifyContent: "center", width: 175 }}>
                        <div className="clickable" style={{ padding, fontSize: 20 }} onClick={context.playPrevious}>
                            <Icon name="step-backward" />
                        </div>
                        <Spacer />
                        <div style={{ padding }}>
                            <PlayPauseControls showProgress={isDesktop} />
                        </div>
                        <Spacer />
                        <div
                            className="clickable"
                            style={{ padding, fontSize: 20 }}
                            onClick={() => {
                                trackEvent("event", {
                                    category: "Video",
                                    action: "Video_skipped",
                                    label: `${track.artist.name} - ${track.name}`,
                                })
                                context.playNext()
                            }}
                        >
                            <Icon name="step-forward" />
                        </div>
                    </FlexRow>
                </div>

                {isDesktop ? (
                    <Button
                        type={isFullScreen ? "primary" : "tertiary"}
                        theme="gray"
                        key={isFullScreen}
                        icon={isFullScreen ? "compress" : "expand"}
                        style={{ minWidth: 130 }}
                        onClick={isDesktop ? context.toggleFullScreen : noop}
                    >
                        {isFullScreen ? "Quitter le plein écran" : <u>Plein écran</u>}
                    </Button>
                ) : (
                    <div style={{ width: 40 }} />
                )}
            </FlexRow>
            <FlexRow className="justify-center py-1 bg-gray-200">
                <ShareLink track={track} />
            </FlexRow>
        </React.Fragment>
    )
}

const mapStatusToIcon = state => {
    switch (state) {
        case "playing":
            return "pause-circle"
        case "paused":
            return "play-circle"
        case "buffering":
            return "spin fa-spinner"
        case "cued":
            return "play-circle"
        case "unstarted":
            return "play-circle"
    }
    return "play-circle"
}
export function PlayPauseControls({ size = 40 }) {
    const context = getContext()
    const progress = usePlayerProgress()

    return (
        <div className="clickable" onClick={context.togglePlay}>
            <Icon name={mapStatusToIcon(context.state.player.status)} style={{ fontSize: size }} />
        </div>
    )
}

export function ForwardControls({ size = 30 }) {
    const context = getContext()

    return (
        <div className="clickable" onClick={context.playNext}>
            <Icon name="step-forward" style={{ fontSize: size }} />
        </div>
    )
}
