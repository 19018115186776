import gql from "graphql-tag"
import { useMutation } from "urql"
import fragments from "./_fragments"

const UPVOTE_SUGGESTION_MUTATION = gql`
    mutation UpvoteSuggestion($id: String!) {
        UpvoteSuggestion(id: $id) {
            id
            name
            artist_name
            ok_translation
            upvotes
        }
    }
`
export function useMutationUpvoteSuggestion() {
    return useMutation(UPVOTE_SUGGESTION_MUTATION)
}

const CREATE_SUGGESTION = gql`
    mutation CreateSuggestion($input: SuggestionInput!) {
        CreateSuggestion(input: $input) {
            id
            name
            artist_name
            ok_translation
            status
            original_text
            upvotes
        }
    }
`
export function useMutationCreate() {
    return useMutation(CREATE_SUGGESTION)
}

const UPDATE_SUGGESTION = gql`
    mutation UpdateSuggestion($id: String!, $input: SuggestionInput!) {
        UpdateSuggestion(id: $id, input: $input) {
            id
            name
            artist_name
            ok_translation
            status
            original_text
            track {
                ...trackFullFragment
            }
            artist {
                id
                image
            }
            upvotes
        }
    }
    ${fragments.album}

    ${fragments.track}

    ${fragments.trackFull}
`
export function useMutationUpdate() {
    return useMutation(UPDATE_SUGGESTION)
}

const CREATE_SUGGESTION_TRANSLATION = gql`
    mutation CreateSuggestionTranslation($id: String!, $text: String!) {
        CreateSuggestionTranslation(id: $id, text: $text) {
            id
            name
            artist_name
            ok_translation
            status
            original_text
            track {
                id
                name
                artist {
                    name
                    image
                }
            }
            translations {
                id
                created_by {
                    id
                    username
                }
            }
            upvotes
        }
    }
`
export function useMutationCreateTranslation() {
    return useMutation(CREATE_SUGGESTION_TRANSLATION)
}

export const SUGGESTION_QUERY = gql`
    query suggestions {
        getSuggestions {
            id
            name
            artist_name
            ok_translation
            status
            youtube_url
            created_by {
                username
            }
            track {
                ...trackFullFragment
            }
            artist {
                id
                image
            }
            upvotes
            _isNew
        }
    }
    ${fragments.album}

    ${fragments.track}

    ${fragments.trackFull}
`
