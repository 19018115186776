// @ts-check
import React, { useState, useEffect } from "react"
import { FlexColumn, FlexRow, Spacer, Modal, Alert, Button } from "@marg51/react-ui"
import { useAuth0 } from "auth/Provider"
import { getContext } from "utils/context"
import {
    useMutationLinkAuth0Account,
    getMe,
    getAuth0Account,
    useMutationLoginAuth0,
    useMutationSignupAuth0,
} from "../api/users"
import { useQuery } from "api/urql"
import { localStorageSetItem } from "../utils/localStorage"
import { HandleLogin } from "../pages/login"
import { useRouter } from "next/router"
import { Form, InputForm, SubmitButton } from "@marg51/react-forms-ui"
import { useGaEvent } from "../trackers/ga"

export default function WaitingForLogin() {
    const context = getContext()
    const { isAuthenticated, justLoggedIn, getTokenSilently } = useAuth0()
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (justLoggedIn) {
            getTokenSilently().then((token) => {
                localStorageSetItem("tz.auth0_token", token)
                setOpen(true)
            })
        } else if (isAuthenticated && !context.state.user) {
            getTokenSilently().then((token) => {
                localStorageSetItem("tz.auth0_token", token)
                setOpen(true)
            })
        }
    }, [justLoggedIn, isAuthenticated, context.state.user])

    if (open && !context.state.user) return <ModalSignup />

    return null
}

function ModalSignup() {
    const context = getContext()
    const router = useRouter()
    const [open, setOpen] = useState(false)
    const trackEvent = useGaEvent()

    const [{ fetching, data }] = useQuery(getAuth0Account)
    const [result, doLinkAccount] = useMutationLinkAuth0Account()
    const [resultSignup, doSignup] = useMutationSignupAuth0()
    const [resultLogin, doLogin] = useMutationLoginAuth0()
    const { isAuthenticated, getTokenSilently } = useAuth0()
    const [error, setError] = useState()

    useEffect(() => {
        if (isAuthenticated) {
            getTokenSilently().then((token) => {
                localStorageSetItem("tz.auth0_token", token)
                doLogin().then(({ data }) => {
                    const status = data.LoginAuth0.status
                    if (status === "ok") {
                        HandleLogin(context, data.LoginAuth0.user)
                        trackEvent("event", {
                            category: "account",
                            action: "link account",
                            label: data.LoginAuth0.user.username,
                        })

                        setOpen(false)
                        router.push("/")
                    } else if (status === "not linked") {
                        setOpen(true)
                    }
                })
            })
        }
    }, [isAuthenticated])

    function handleSignup(data) {
        console.log("going to signup")
        return doSignup(data)
            .then(({ data, error, ...other }) => {
                if (error) return Promise.reject(error)

                HandleLogin(context, data.SignupAuth0.user)
                trackEvent("event", { category: "account", action: "signup", label: data.SignupAuth0.user.username })
                setOpen(false)
                router.push("/")
            })
            .catch((e) => {
                console.log("error", e)
                setError(
                    (e.message && e.message.replace(/\[GraphQL\] Unexpected error value: "(.+)"$/, "$1")) ||
                        "Une erreur est survenue"
                )
            })
    }

    return (
        <Modal isOpen={open} onRequestClose={() => setOpen(false)}>
            <FlexColumn className="p-4">
                Bienvenue, veuillez choisir un username
                <Spacer />
                <Form onSubmit={handleSignup}>
                    <FlexColumn>
                        <InputForm
                            pattern={/^[a-zA-Z0-9 _-]{3,16}$/}
                            name="username"
                            title="Votre nom d'utilisateur"
                            required
                            errorMessages={{
                                pattern:
                                    "Votre pseudo doit comporter entre 3 et 16 caractères. Les caractères spéciaux autorisés sont _ et -",
                                required: "Votre pseudo est requis.",
                            }}
                        />
                        <Spacer />
                        <SubmitButton>S'enregistrer</SubmitButton>
                    </FlexColumn>
                    {error && <Alert theme="red">{error}</Alert>}
                </Form>
            </FlexColumn>
        </Modal>
    )
}
