import React, { useState } from "react"
import { Spacer } from "@marg51/react-ui"
import ButtonSelect from "../ui/ButtonSelect"
import { V3 as Block } from "../Track/TrackList"
import formatNumber from "utils/numbers"
import FetchAndRenderFeats from "./FetchAndRenderFeats"

export default function RenderArtistTop({ item }) {
    const [selectedTab, setSelectedTab] = useState("last-tracks")
    return (
        <React.Fragment>
            <ButtonSelect>
                <ButtonSelect.Item onClick={() => setSelectedTab("top")} active={selectedTab === "top"}>
                    Top
                </ButtonSelect.Item>
                <Spacer size="small" />
                <ButtonSelect.Item onClick={() => setSelectedTab("last-tracks")} active={selectedTab === "last-tracks"}>
                    Nouveautés
                </ButtonSelect.Item>
                {item.feats_count > 0 && (
                    <React.Fragment>
                        <Spacer size="small" />
                        <ButtonSelect.Item onClick={() => setSelectedTab("feats")} active={selectedTab === "feats"}>
                            Feats ({item.feats_count})
                        </ButtonSelect.Item>
                    </React.Fragment>
                )}
            </ButtonSelect>
            <Spacer />
            {selectedTab === "top" && (
                <Block
                    listName="top-tracks"
                    image={item.slug}
                    playlist={item.topTracks}
                    subtitle={formatNumber(item.total_views) + " vues"}
                    title="TOP Vidéos"
                    items={item.topTracks}
                />
            )}
            {selectedTab === "last-tracks" && (
                <Block listName="last-tracks" items={item.latestTracks} title="Derniers Ajouts" />
            )}
            {selectedTab === "feats" && <FetchAndRenderFeats item={item} />}
        </React.Fragment>
    )
}
