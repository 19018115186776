import map from "lodash.map"
export function parse(text) {
    var subtitles = []
    text = String(text).replace(/\r\n|\r|\n/g, "\n") // windows / mac / linux
    text = text.replace(/\n{3,}/g, "\n\n") // max double ligne vide
    text = text.replace(/^\s+|\s+$/g, "") // trim

    var srt = text.split("\n\n")
    map(srt, (el, key) => {
        var obj = {}
        var sub = el.split("\n")
        if (sub.length > 3) sub[2] = sub.slice(2).join("\n")
        if (sub.length === 3) obj.id = parseInt(sub[0]) - 1
        var time = sub[1].split(" --> ")
        obj.start = parseToSecond(time[0])
        obj.end = parseToSecond(time[1])
        obj.text = sub[2]

        subtitles.push(obj)
    })

    return subtitles
}

export function getSubtitleByTime(subtitles, time) {
    var current = -1
    var subtitle
    for (let i = 0; i < subtitles.srt.length; i++) {
        var el = subtitles.srt[i]
        if (el.start < time) {
            current = i
            subtitle = el
        } else {
            break
        }
    }

    if (current > -1) {
        if (subtitle.end < time) {
            subtitle = null
        }
    }

    return subtitle
}

function parseToSecond(time) {
    var s = 0.0
    if (time) {
        var els = time.split(":")
        var i = 0
        while (i < els.length) {
            s = s * 60 + parseFloat(els[i].replace(",", "."))
            i++
        }
    }

    return s
}
