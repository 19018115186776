import React from "react"
import { FlexRow } from "@marg51/react-ui"

/**
 *
 * @param {{children: any, style?:any, className?:any}} param0
 */
export default function ButtonSelect({ children, style, className = "" }) {
    return (
        <FlexRow
            className={`bg-gray-300 font-semibold ${className}`}
            style={{ borderRadius: 5, padding: 2, fontSize: 14, ...style }}
        >
            {children}
        </FlexRow>
    )
}

ButtonSelect.Item = ({ className = "", active, ...props }) => {
    return (
        <div
            className={`text-gray-800 clickable underline flex-1 text-center p-1 rounded ${
                active ? "bg-white shadow" : "hover:bg-gray-100 "
            } ${className}`}
            {...props}
        />
    )
}
