import React, { useState } from "react"
import { FlexColumn, FlexRow, Spacer, Icon, Button, OnOutsideClick } from "@marg51/react-ui"
import { formatDate } from "../../utils/date"
import Avatar from "../User/Avatar"
import CommentLike from "./CommentLike"
import CommentForm from "./Comment_form"
import Popup from "../ui/Popup"
import { getContext } from "../../utils/context"
import LdJson from "../../utils/LdJson"
import Link from "next/link"
import StringReplace from "react-string-replace"
import SmallImage from "../ui/SmallImage"

export function CommentV2({ item, track }) {
    const context = getContext()
    const [isReplying, setReply] = useState(false)

    const username = item.user ? item.user.username : item.username_v1
    return (
        <React.Fragment>
            <FlexRow nowrap id={`comment-${item.id}`} style={{ alignItems: "flex-start" }}>
                <LdJson
                    data={{
                        "@context": "http://schema.org",
                        "@type": "https://schema.org/UserComments",
                        creator: username,
                        commentText: fixComment(item.text),
                        commentTime: new Date(parseInt(item.created_at)).toISOString(),
                    }}
                />
                <Avatar user={item.user} />
                <Spacer />
                <FlexColumn className="flex-1">
                    <FlexRow nowrap className="items-center">
                        <div className="font-semibold text-gray-800">{username}</div>
                        <div className="flex-1" />
                        <div className="text-gray-600 text-sm">Il y a {formatDate(item.created_at)}</div>
                    </FlexRow>
                    {item.reply_to && item.reply_to.user && (
                        <React.Fragment>
                            <Spacer size="small" />
                            <div className="text-gray-500">
                                <Icon name="share" className="text-xs mr-2" />
                                <span> En réponse à </span>
                                <span className="font-semibold">{item.reply_to.user.username}</span>
                            </div>
                        </React.Fragment>
                    )}
                    <Spacer size="small" />
                    <div className="text-gray-700" style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
                        <AugmentedComment>{fixComment(item.text)}</AugmentedComment>
                    </div>
                    <Spacer size="small" />
                    <FlexRow style={{ position: "relative", alignItems: "center" }}>
                        <CommentLike item={item} track={track} />
                        <Spacer />
                        {context.state.user && context.state.user.id && (
                            <Button type="tertiary" size="small" onClick={() => setReply(true)}>
                                Répondre
                            </Button>
                        )}
                    </FlexRow>
                    {isReplying && (
                        <React.Fragment>
                            <Spacer />
                            <div className="bg-white rounded p-2">
                                <CommentForm track={track} comment={item} onCancel={() => setReply(false)} />
                            </div>
                        </React.Fragment>
                    )}
                </FlexColumn>
            </FlexRow>
        </React.Fragment>
    )
}

function fixComment(text) {
    return text
        .replace(/Ã©/g, "é")
        .replace(/Ã®/g, "î")
        .replace(/Ã /g, "à")
        .replace(/Ã¨/g, "è")
        .replace(/Ë†Ë†/g, "")
        .replace(/Ã§/g, "ç")
        .replace(/Ãª/g, "ê")
}

function AugmentedComment({ children }) {
    const artistLinksPattern = /https?:\/\/w?w?w?\.?traduzic.com\/artiste\/([a-z_-]*)/g
    const traductionLinksPattern = /https?:\/\/w?w?w?\.?traduzic.com\/traduction\/([a-z_-]*\/[a-z_-]*)/g

    // let result = StringReplace(children, traductionLinksPattern, match => (
    //     <React.Fragment>
    //         <Link href="/traduction/[...params]" as={`/traduction/${match}`}>
    //             <a className="underline bg-gray-100">/traduction/{match}</a>
    //         </Link>
    //     </React.Fragment>
    // ))

    return StringReplace(children, artistLinksPattern, (match) => (
        <React.Fragment>
            <Link href="/artiste/[slug]" as={`/artiste/${match}`}>
                <a className="underline bg-gray-100">/artiste/{match}</a>
            </Link>
        </React.Fragment>
    ))
}
