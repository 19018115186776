import React, { useState } from "react"
import ButtonSelect from "../ui/ButtonSelect"
import { Spacer, Icon } from "@marg51/react-ui"
import { V3 as TrackList } from "./TrackList"
import { getContext } from "../../utils/context"
import ButtonShowMore from "../ui/ButtonShowMore"

export default function HomepageTop({ selection, mostViewed, latest }) {
    const context = getContext()
    const [selectedTab, setSelectedTab] = useState("last-tracks")

    return (
        <React.Fragment>
            <ButtonSelect>
                <ButtonSelect.Item onClick={() => setSelectedTab("selection")} active={selectedTab === "selection"}>
                    Sélection
                </ButtonSelect.Item>
                <Spacer size="small" />
                <ButtonSelect.Item onClick={() => setSelectedTab("last-tracks")} active={selectedTab === "last-tracks"}>
                    Nouveautés
                </ButtonSelect.Item>
                {mostViewed && mostViewed.length > 0 && (
                    <React.Fragment>
                        <Spacer size="small" />
                        <ButtonSelect.Item
                            onClick={() => setSelectedTab("top-tracks")}
                            active={selectedTab === "top-tracks"}
                        >
                            Top Vidéos
                        </ButtonSelect.Item>
                    </React.Fragment>
                )}
            </ButtonSelect>
            <Spacer />
            {selectedTab === "selection" && (
                <TrackList
                    listName="selection"
                    title="Sélection de l'équipe"
                    items={selection}
                    showArtist
                    showArtistImage
                />
            )}
            {selectedTab === "top-tracks" && (
                <TrackList
                    listName="home-top-tracks"
                    title="Les plus regardées du moment"
                    items={mostViewed}
                    showArtist
                    showArtistImage
                >
                    <ButtonShowMore
                        to="/classement/[...params]"
                        as="/classement/meilleures-videos-sous-titrees/aujourdhui"
                    >
                        Voir les autres vidéos du moment
                    </ButtonShowMore>
                </TrackList>
            )}
            {selectedTab === "last-tracks" && (
                <TrackList
                    listName="home-last-tracks"
                    title="Nouveautés sur TraduZic"
                    items={latest}
                    showArtist
                    showArtistImage
                >
                    <ButtonShowMore
                        to="/classement/[...params]"
                        as="/classement/dernieres-traductions-de-clips/tous-les-genres"
                    >
                        Voir les 20 derniers ajouts
                    </ButtonShowMore>
                </TrackList>
            )}
        </React.Fragment>
    )
}
