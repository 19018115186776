import React, { useEffect } from "react"
import { useMutationSyncAuth0 } from "api/users"
import { useRouter } from "next/router"
import { useGaEvent } from "../trackers/ga"

export default function AuthPage() {
    const router = useRouter()
    const trackEvent = useGaEvent()
    if (window.location.search.includes("iss=")) {
        console.log("coming from auth0")
    }
    const [, doSyncAuth0] = useMutationSyncAuth0()

    useEffect(() => {
        doSyncAuth0().then((e) => {
            trackEvent("event", {
                category: "account",
                action: "validating email",
                label: e.error ? "with error" : "success",
            })
            router.push("/")
        })
    }, [])

    return null
}
