import React from "react"
import { FlexColumn, FlexRow, Spacer } from "@marg51/react-ui"
import { Provider as HierarchieProvider } from "Components/Track/HierarchieContext"
import ArtistCardMini from "Components/Artist/CardMini"

export default function MostSearchedForArtists({ width = 250, innerStyle = {}, ...props }) {
    return (
        <div {...props}>
            <HierarchieProvider value="Artistes les plus recherchés">
                <div className="TrackListHorizontalTitle">Les artistes les plus recherchés</div>
                <FlexRow style={{ width, justifyContent: "space-around", ...innerStyle }}>
                    <div style={{ marginTop: 5 }}>
                        <ArtistCardMini slug="eminem" name="Eminem" />
                    </div>
                    <div style={{ marginTop: 5 }}>
                        <ArtistCardMini slug="the-weeknd" name="The Weekend" />
                    </div>
                    <div style={{ marginTop: 5 }}>
                        <ArtistCardMini slug="drake" name="Drake" />
                    </div>
                    <div style={{ marginTop: 5 }}>
                        <ArtistCardMini slug="travi-scott" name="Travis Scott" />
                    </div>
                    <div style={{ marginTop: 5 }}>
                        <ArtistCardMini slug="kendrick-lamar" name="Kendrick Lamar" />
                    </div>
                    <div style={{ marginTop: 5 }}>
                        <ArtistCardMini slug="kanye-west" name="Kanye West" />
                    </div>
                    <div style={{ marginTop: 5 }}>
                        <ArtistCardMini slug="2pac" name="2Pac" />
                    </div>
                    <div style={{ marginTop: 5 }}>
                        <ArtistCardMini slug="rick-ross" name="Rick Ross" />
                    </div>
                </FlexRow>
            </HierarchieProvider>
        </div>
    )
}
