import React from "react"
import { Form, InputForm, HiddenInputForm, SubmitButton } from "@marg51/react-forms-ui"
import { useMutationCreate } from "../../api/comments"
import { Spacer, FlexColumn, FlexRow, Button } from "@marg51/react-ui"
import { useGaEvent } from "../../trackers/ga"

export default function CommentForm({ track, comment, onCancel, shortLegend = false }) {
    const trackEvent = useGaEvent()
    const [, runMigration] = useMutationCreate()
    return (
        <Form
            onSubmit={(data) => {
                onCancel && onCancel()
                trackEvent("event", {
                    category: "comment",
                    action: "created",
                    label: `${track.name} - ${track.artist.name}`,
                })
                return runMigration({ input: data })
            }}
        >
            <FlexColumn>
                <HiddenInputForm name="track_id" defaultValue={parseInt(track.id)} />
                <HiddenInputForm name="reply_to_id" defaultValue={comment && parseInt(comment.id)} />
                <InputForm
                    name="text"
                    multiline
                    title={
                        comment
                            ? `Repondez à ${comment.user.username}`
                            : `Votre commentaire${shortLegend ? "" : ` pour ${track.name}`}`
                    }
                    required
                    autoFocus
                />
                <Spacer />
                <FlexRow style={{ justifyContent: "flex-end" }}>
                    {onCancel && (
                        <React.Fragment>
                            <Button type="tertiary" theme="gray" onClick={onCancel}>
                                Annuler
                            </Button>
                            <Spacer />
                        </React.Fragment>
                    )}
                    <SubmitButton>Envoyer</SubmitButton>
                </FlexRow>
            </FlexColumn>
        </Form>
    )
}
