import React, { useState } from "react"
import { useQuery } from "api/urql"
import { Spacer } from "@marg51/react-ui"
import TrackListLoader from "../Track/TrackListLoader"
import TrackList from "../Track/TrackList/V3"
import gql from "graphql-tag"
import _fragments from "../../api/_fragments"
import ButtonSelect from "../ui/ButtonSelect"
import { useRouter } from "next/router"
import Link from "next/link"
import PageTitle from "../../utils/PageTitle"
import BaseHead from "../Head"

const QUERY = gql`
    query HomePage($timeframe: TimeframeValues) {
        mostViewed: tracks(type: top, timeframe: $timeframe) {
            ...trackFullFragment
        }
    }
    ${_fragments.track}
    ${_fragments.trackFull}
    ${_fragments.album}
`
const map = {
    aujourdhui: "DAY",
    "derniere-heure": "HOUR",
    "ce-mois": "MONTH",
}
export default function TopTracks() {
    const {
        query: {
            params: [, timeframeUrlPart],
        },
        push: navigate,
    } = useRouter()
    const timeframe = map[timeframeUrlPart]
    const [result, errorComponent] = useQuery(QUERY, { timeframe })

    return (
        <React.Fragment>
            <BaseHead
                title={`Les 10 vidéos traduites qui font le buzz
                ${
                    timeframe === "DAY"
                        ? "aujourd'hui"
                        : timeframe === "HOUR"
                        ? "actuellement"
                        : "ces dernières semaines"
                }`}
                description="Comprenez vos artistes favoris facilement grâce à nos vidéos traduites et sous-titrées à la main"
            />
            <ButtonSelect>
                <Link href="/classement/[...params]" as="/classement/meilleures-videos-sous-titrees/derniere-heure">
                    <ButtonSelect.Item active={timeframe === "HOUR"}>Heure</ButtonSelect.Item>
                </Link>
                <Spacer size="small" />
                <Link href="/classement/[...params]" as="/classement/meilleures-videos-sous-titrees/aujourdhui">
                    <ButtonSelect.Item active={timeframe === "DAY"}>Jour</ButtonSelect.Item>
                </Link>
                <Spacer size="small" />
                <Link href="/classement/[...params]" as="/classement/meilleures-videos-sous-titrees/ce-mois">
                    <ButtonSelect.Item active={timeframe === "MONTH"}>Mois</ButtonSelect.Item>
                </Link>
            </ButtonSelect>
            <Spacer />
            {errorComponent ? (
                errorComponent
            ) : result.fetching ? (
                <TrackListLoader />
            ) : (
                <TrackList
                    listName="top-track"
                    title="Les plus regardées du moment"
                    items={result.data.mostViewed}
                    showArtist
                    showArtistImage
                />
            )}
        </React.Fragment>
    )
}
