import React, { useReducer, useEffect } from "react"
import { palet, FlexColumn, Spacer, Alert, ButtonIcon, Button } from "@marg51/react-ui"
import { InputForm, Form, SubmitButton } from "@marg51/react-forms-ui"

import { useRouter } from "next/router"
import { useAuth0 } from "auth/Provider"
import { FlexRow } from "@marg51/react-ui/dist/index.es"
import { useGaEvent } from "../../trackers/ga"

export default function Signup() {
    const trackEvent = useGaEvent()
    const router = useRouter()

    const { isAuthenticated, loading, loginWithRedirect: loginWithPopup, logout, user, ...other } = useAuth0()

    useEffect(() => {
        if (isAuthenticated) {
            router.push("/logout")
        }
    }, [isAuthenticated])
    if (loading) {
        return "loading"
    }

    if (isAuthenticated) {
        return null
    }

    return (
        <FlexRow>
            <SignupButton>Créer un compte</SignupButton>
            <Spacer />
            <LoginButton>Se connecter</LoginButton>
        </FlexRow>
    )
}

export const SignupButton = ({ children }) => {
    const trackEvent = useGaEvent()
    const { isAuthenticated, loading, loginWithRedirect: loginWithPopup, logout, user, ...other } = useAuth0()

    return (
        <Button
            theme="blue"
            onClick={() => {
                trackEvent("event", { category: "account", action: "clicked button", label: "signup" })
                loginWithPopup({ screen_hint: "signup" })
            }}
            className="flex-1"
        >
            {children}
        </Button>
    )
}

export const LoginButton = ({ children }) => {
    const trackEvent = useGaEvent()
    const { isAuthenticated, loading, loginWithRedirect: loginWithPopup, logout, user, ...other } = useAuth0()

    return (
        <Button
            theme="gray"
            onClick={() => {
                trackEvent("event", { category: "account", action: "clicked button", label: "login" })
                loginWithPopup({})
            }}
            className="flex-1"
        >
            {children}
        </Button>
    )
}
