// @ts-check
import React, { useEffect } from "react"
import bugsnagClient from "trackers/bugsnag"

import { GetContext } from "utils/context"
import { Spacer, Icon, Button, Alert } from "@marg51/react-ui"

import RenderUrqlError from "Components/ui/RenderUrqlError"
import { V3 as TrackList } from "Components/Track/TrackList"
import { useTracks } from "api/tracks"
import Page from "Components/ui/Page"
import Avatar from "Components/User/Avatar"
import TrackListLoader from "Components/Track/TrackListLoader"
import Link from "next/link"
import { localStorageRemoveItem } from "../utils/localStorage"
import IfLoggedIn from "auth/IfLoggedIn"
import ModalTransition from "auth/ModalTransition"
import ValidateEmail from "../Components/User/ValidateEmail"

export default function UserProfile() {
    const context = GetContext()

    const trackIds = []
    for (const [key, value] of Object.entries(context.state.favourites)) {
        if (value) trackIds.push(key)
    }
    const { tracks, error, fetched } = useTracks(trackIds)

    if (error) {
        bugsnagClient.notify(new Error(`userProfile({trackIds: ${JSON.stringify(trackIds)}})`))
        return <RenderUrqlError error={error} />
    }

    return (
        <Page
            header={
                <React.Fragment>
                    <Avatar user={context.state.user} />
                    <Spacer />
                    {context.state.user.username}
                </React.Fragment>
            }
        >
            <div className="text-right">
                <Link href="/logout">
                    <a
                        className="text-gray-700 underline"
                        onClick={() => {
                            localStorageRemoveItem("tz.user")
                            localStorageRemoveItem("urql.token")
                            localStorageRemoveItem("tz.auth0_token")

                            window.location.href = "/"
                        }}
                    >
                        Se déconnecter <Icon name="sign-out" />
                    </a>
                </Link>
            </div>

            {!context.state.user.auth0_id ? <ModalTransition /> : <ValidateEmail />}

            <div className="mt-5">
                {!fetched && <TrackListLoader />}
                {fetched && (
                    <TrackList
                        listName="favoris"
                        title={
                            <React.Fragment>
                                Vos Favoris <Icon name="heart-o" />
                            </React.Fragment>
                        }
                        items={tracks}
                        showArtist
                        showArtistImage
                    >
                        {tracks.length === 0 ? (
                            <em className="text-gray-600 p-4">Vous n'avez pas de favoris. </em>
                        ) : null}
                    </TrackList>
                )}
            </div>
        </Page>
    )
}

// export default () => <IfLoggedIn Component={UserProfile} />
