import React from "react"
import { FlexRow, Spacer, Icon, FlexColumn } from "@marg51/react-ui"
import { GetContext } from "../../utils/context"
import { useMutationToggleLike } from "../../api/tracks"
import IconButton from "../ui/IconButton"
import { useGaEvent } from "../../trackers/ga"
import useUser from "utils/useUser"
import UserSignupPrompt from "Components/User/UserSignupPrompt"

export default function TrackLike({ track, small = false }) {
    const { state } = GetContext()

    return (
        <TrackLikeBase track={track}>
            {({ isLiked }) => {
                const color = isLiked ? "blue" : "gray"

                return small ? (
                    <IconButton icon="thumbs-up" isActive={isLiked} theme={color} />
                ) : (
                    <FlexRow
                        nowrap
                        style={{ alignItems: "center" }}
                        className={`bg-${color}-100 shadow text-${color}-600 rounded px-2 text-xs`}
                    >
                        {track.likes_count}
                        <Spacer size="small" />
                        <Icon name="thumbs-up" style={{ fontSize: 12 }} />
                    </FlexRow>
                )
            }}
        </TrackLikeBase>
    )
}

export function TrackLikeBase({ track, children }) {
    const trackEvent = useGaEvent()
    const { state, addTrackLike, removeTrackLike } = GetContext()
    const user = useUser()

    const [stateMutation, executeMutation] = useMutationToggleLike()

    if (!track) {
        return <div style={{ width: 40 }} />
    }
    const isLiked = state.tracks_liked && state.tracks_liked[track.id]

    const Container = user ? React.Fragment : UserSignupPrompt

    return (
        <Container label={`aimer ${track.name}`} style={{ left: 0 }}>
            <div
                className="clickable"
                onClick={() => {
                    if (isLiked) {
                        removeTrackLike(track.id)
                        trackEvent("event", {
                            category: "track",
                            action: "un-like",
                            label: `${track.name} - ${track.artist.name}`,
                        })
                    } else {
                        addTrackLike(track.id)
                        trackEvent("event", {
                            category: "track",
                            action: "like",
                            label: `${track.name} - ${track.artist.name}`,
                        })
                    }
                    executeMutation({ id: track.id })
                }}
            >
                {children({ isLiked })}
            </div>
        </Container>
    )
}
