import React, { useContext, createContext, useMemo, useState, useEffect } from "react"
import { Modal } from "@marg51/react-ui"
import { TrackItem } from "./Track/Item"
import useUser from "utils/useUser"
import { useRouter } from "next/router"

const context = createContext()

/**
 * @returns {{
 *  isAdmin: boolean
 *  openTrackModal: (params: {id: ID}) => any
 * }}
 */
export function useAdminContext() {
    return useContext(context)
}

export function AdminProvider({ children }) {
    const [modal, setOpenModal] = useState()
    const { asPath } = useRouter()
    const user = useUser()
    const value = useMemo(() => {
        return {
            isAdmin: user && user.role === "admin",
            openTrackModal: (filter) => {
                setOpenModal({ type: "Track", filter })
            },
            closeModal: () => setOpenModal(false),
        }
    }, [user])

    useEffect(() => {
        modal && setOpenModal(false)
    }, [asPath])

    return (
        <context.Provider value={value}>
            {children}
            {modal && (
                <Modal isOpen onRequestClose={value.closeModal}>
                    {modal.type === "Track" && <TrackItem filter={modal.filter} />}
                </Modal>
            )}
        </context.Provider>
    )
}
