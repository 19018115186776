import React, { useEffect } from "react"

import { FlexRow, Spacer, FlexColumn } from "@marg51/react-ui"

import Sidebar from "./Sidebar"
import RenderActiveTrack from "../RenderActiveTrack"
import { getContext } from "../../utils/context"
import useLocation from "utils/useLocation"
import usePlayerSize from "../../utils/usePlayerSize"

export default function LayoutDesktop({ children }) {
    const context = getContext()
    const [size] = usePlayerSize()
    const { location } = useLocation()

    useEffect(() => {
        if (context.state.player.isFullScreen) {
            context.toggleFullScreen()
        }
    }, [location.pathname])

    return (
        <FlexRow>
            <div style={{ width: 70, zIndex: context.state.player.isFullScreen ? 2 : 3 }}>
                <Sidebar />
            </div>
            <Spacer size="large" />
            <div
                className={`pt-6 mx-auto`}
                style={{
                    width: size[0] + 15,
                    zIndex: 2,
                    marginTop: context.state.player.isFullScreen ? -1 : 0,
                }}
            >
                <Container fullScreen={context.state.player.isFullScreen} onClose={() => context.toggleFullScreen()}>
                    <RenderActiveTrack />
                </Container>
            </div>
            <Spacer />

            {children}
        </FlexRow>
    )
}

function Container({ children, onClose, fullScreen }) {
    const [size] = usePlayerSize()
    let styleContainer = {}

    if (fullScreen) {
        styleContainer = {
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            overflow: "auto",
            background: "rgba(0,0,0,1)",
        }
    }
    return (
        <FlexColumn style={styleContainer} onClick={fullScreen && onClose} className={fullScreen && "clickable"}>
            {fullScreen ? (
                <div
                    className="m-2 flex-1"
                    style={{ overflow: "auto", cursor: "default" }}
                    children={children}
                    onClick={(event) => event.stopPropagation()}
                />
            ) : (
                <div
                    style={{
                        width: size[0],
                    }}
                    children={children}
                />
            )}
        </FlexColumn>
    )
}
