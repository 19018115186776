import React, { useEffect } from "react"
import { v4 as uuid } from "uuid"
import { Router as RouterReach } from "@reach/router"

import { createClient, Provider } from "urql"

import { options } from "api/urql"
import Context from "utils/context"

import "./polyfills/requestAnimationFrame"

import "styles/index.scss"
import "styles/PlayingBars.scss"
import "styles/IconButton.scss"

import App from "Components/App"
import Router from "./Router"
import ContextProvider from "./trackers/GaContextProvider"
import { localStorageSetItem, localStorageGetItem } from "./utils/localStorage"
import { Auth0Provider } from "auth/Provider"
import ModalSignup from "./auth/ModalSignup"
import { PlayerSizeProvider } from "./utils/usePlayerSize"
import { AdminProvider } from "Admin/context"
const client = createClient(options)

function initToken() {
    let token = localStorageGetItem("tz.dtoken")
    if (!token) {
        try {
            token = uuid()
        } catch (e) {
            token = new Date().toString()
        }
        localStorageSetItem("tz.dtoken", token)
    }
}
initToken()

function Route({ path, Component, Layout, ...props }) {
    return (
        <ContextProvider>
            <Component {...props} />
        </ContextProvider>
    )
}

function Crapp() {
    useEffect(() => {
        setTimeout(() => {
            const elm = document.getElementById("loader")
            if (elm) elm.classList.add("active")
        }, 300)
        setTimeout(() => {
            const elm = document.getElementById("loader")

            if (elm) document.body.removeChild(elm)
        }, 500)
    }, [])
    return (
        <Auth0Provider>
            <Provider value={client}>
                <RouterReach>
                    <Route
                        default
                        Component={() => (
                            <Context>
                                <AdminProvider>
                                    <App>
                                        <PlayerSizeProvider>
                                            <Router />
                                            <ModalSignup />
                                        </PlayerSizeProvider>
                                    </App>
                                    <div id="hoistedElements" />
                                </AdminProvider>
                            </Context>
                        )}
                    />
                </RouterReach>
            </Provider>
        </Auth0Provider>
    )
}

export default Crapp
