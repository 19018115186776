import React from "react"
import { Link, useNavigate } from "@reach/router"
import { useGaEvent } from "./trackers/ga"
export default ({ children, href, as }) => {
    const navigate = useNavigate()
    const trackEvent = useGaEvent()
    const child = React.Children.only(children)

    if (child.type === "a") {
        return (
            <Link
                {...child.props}
                to={as || href}
                onClick={() => trackEvent("event", { category: "ClickedLink", action: href, label: as || href })}
            />
        )
    }

    return React.cloneElement(child, {
        ...child.props,
        onClick: () => {
            trackEvent("event", { category: "ClickedLink", action: href, label: as || href })
            navigate(as || href)
        },
    })
}
