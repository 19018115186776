// @ts-check
import { post } from "./_fetch"
import { useMutation } from "urql"
import fragments from "./_fragments"

import gql from "graphql-tag"

export function login(body) {
    return post("/users/login", body)
}

const LOGIN_MUTATION = gql`
    mutation Login($email: String!, $password: String!) {
        Login(email: $email, password: $password) {
            ...meFragment
        }
    }
    ${fragments.me}
`
export function useMutationLogin() {
    return useMutation(LOGIN_MUTATION)
}

const LOGIN_AUTH0_MUTATION = gql`
    mutation LoginAuth0 {
        LoginAuth0 {
            id
            status
            user {
                ...meFragment
            }
        }
    }
    ${fragments.me}
`
export function useMutationLoginAuth0() {
    return useMutation(LOGIN_AUTH0_MUTATION)
}

export const getMe = gql`
    query getMe {
        me {
            id
            username
            auth0_id
        }
    }
`

export const getAuth0Account = gql`
    query getAuth0Account {
        getAuth0Account {
            id
            auth0_id
            email_verified
            user_id
            status
        }
    }
`

const LinkAuth0Account = gql`
    mutation LinkAuth0Account {
        LinkAuth0Account {
            id
            auth0_id
            user {
                id
                username
                auth0_id
            }
        }
    }
`
export function useMutationLinkAuth0Account() {
    return useMutation(LinkAuth0Account)
}

const SignupAuth0 = gql`
    mutation SignupAuth0($username: String!) {
        SignupAuth0(username: $username) {
            id
            user {
                ...meFragment
            }
        }
    }
    ${fragments.me}
`
export function useMutationSignupAuth0() {
    return useMutation(SignupAuth0)
}

const SyncAuth0 = gql`
    mutation UpdateAuth0User {
        UpdateAuth0User {
            id
            email_verified
            auth0_id
        }
    }
`
export function useMutationSyncAuth0() {
    return useMutation(SyncAuth0)
}
