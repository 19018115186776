import React, { useRef, useState, useEffect } from "react"
import { Icon } from "@marg51/react-ui"
import ClipboardJS from "clipboard"
import usePlayerSize from "../../utils/usePlayerSize"
import { ENDPOINT } from "config"
export function ShareLink({ track, small = false }) {
    const [size] = usePlayerSize()
    const { saved, ...copyProps } = useShareLinkRenderProps({ track })
    const theme = saved ? "green" : "gray"

    return (
        <div
            className={`bg-white px-2 py-1 rounded flex flex-no-wrap items-center text-${theme}-700 border border-${theme}-300 transition`}
        >
            <Icon name="share-alt-square" className={`text-${theme}-600`} />
            <div style={{ width: 10 }} />
            <button
                type="button"
                to={`/traduction/${track.artist.slug}/${track.slug}`}
                style={{ whiteSpace: "nowrap", minWidth: small ? 0 : 250, maxWidth: size - 70 }}
                className="ellipsis"
                {...copyProps}
            >
                {saved
                    ? "Lien copié!"
                    : small
                    ? "Partager"
                    : `${typeof window !== "undefined" ? window.location.origin + "/" : ENDPOINT}traduction/${
                          track.artist.slug
                      }/${track.slug}`}
            </button>
        </div>
    )
}

export function useShareLinkRenderProps({ track }) {
    const ref = useRef()
    const [saved, setSave] = useState(false)

    useEffect(() => {
        if (ref.current) {
            const clipboard = new ClipboardJS(ref.current)
            let timeout
            clipboard.on("success", function(e) {
                setSave(true)
                timeout = setTimeout(() => setSave(false), 700)
            })
            return () => clearTimeout(timeout)
        }
    }, [ref.current])

    return {
        ref,
        "data-clipboard-text": `${typeof window !== "undefined" ? window.location.origin + "/" : ENDPOINT}traduction/${
            track.artist.slug
        }/${track.slug}`,
        saved,
    }
}
