import gql from "graphql-tag"
import fragments from "./_fragments"

export const getPromotedAlbums = gql`
    query getPromotedAlbums($limit: Int = 3) {
        getPromotedAlbums(limit: $limit) {
            ...AlbumReferalFragment
        }
    }
    ${fragments.albumReferal}
    ${fragments.track}
`
