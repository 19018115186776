import gql from "graphql-tag"
import fragments from "./_fragments"
import { useMutation } from "urql"

export const getAdminTrack = gql`
    query getAdminTrack($filter: TrackAdminFilter!) {
        getAdminTrack(filter: $filter) {
            ...trackFullFragment
        }
    }
    ${fragments.trackFull}
    ${fragments.track}
    ${fragments.album}
`

export const getAdminTracks = gql`
    query getAdminTracks($filter: TrackAdminFilter!) {
        getAdminTracks(filter: $filter) {
            ...trackFullFragment
        }
    }
    ${fragments.trackFull}
    ${fragments.track}
    ${fragments.album}
`

export const updateAdminTrack = gql`
    mutation updateAdminTrack($id: String!, $input: TrackAdminInput!) {
        updateAdminTrack(id: $id, input: $input) {
            ...trackFullFragment
        }
    }
    ${fragments.trackFull}
    ${fragments.track}
    ${fragments.album}
`
