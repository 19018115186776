import { createContext, useContext } from "react"

// export { default as V2 } from "./V2"
export { default as V3 } from "./V3"
// export { default as V4 } from "./V4"
// export { default as V5 } from "./V5"
// export { default as V6 } from "./V6"

export const trackListContext = createContext()

export const useTrackListContext = () => useContext(trackListContext)
