import React from "react"
import { TOP_ARTISTS_QUERY } from "../../api/artists"
import { useQuery } from "api/urql"
import { RenderBigArtist } from "../Artist/RenderBigArtist"
import { FlexColumn } from "@marg51/react-ui"
import TrackListLoader from "../Track/TrackListLoader"
import BaseHead from "../Head"
export default function TopArtists() {
    const [result, errorComponent] = useQuery(TOP_ARTISTS_QUERY)

    if (errorComponent) {
        return errorComponent
    }
    if (result.fetching) {
        return <TrackListLoader />
    }

    const { artists } = result.data

    return (
        <React.Fragment>
            <BaseHead
                title="Les meilleurs artistes sur TraduZic"
                description="5 artistes à ne pas manquer sur TraduZic"
            />
            <FlexColumn>
                {artists.map(artist => (
                    <RenderBigArtist artist={artist} key={artist.id} />
                ))}

                <div style={{ flex: 1, flexShrink: 1 }} />
            </FlexColumn>
        </React.Fragment>
    )
}
