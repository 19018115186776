import React from "react"
import bugsnag from "@bugsnag/js"
import BugsnagReact from "@bugsnag/plugin-react"

// to make tests pass
// setTimeout(function () {}).__proto__.unref = function () {}

const bugsnagClient = bugsnag.start({
    apiKey: "7e2572a14b45e884ae693ef0f5959df0",
    appVersion: process.env.REACT_APP_GIT_SHA,
    releaseStage: process.env.REACT_APP_ENV || "development",
    branch: process.env.REACT_APP_BRANCH,
    plugins: [new BugsnagReact(React)],
})

bugsnagClient.addMetadata("branch", process.env.REACT_APP_BRANCH)

export default bugsnagClient
