import { Button, ButtonIconTooltip, Divider, FlexColumn, FlexRow, Icon, Spacer } from "@marg51/react-ui"
import ReachTooltip from "@reach/tooltip"
import { useAccountNeedsTransition } from "auth/ModalTransition"
import Link from "next/link"
import { useRouter } from "next/router"
import React from "react"
import { GetContext } from "../../utils/context"
import { localStorageRemoveItem } from "../../utils/localStorage"
import Avatar from "../User/Avatar"

// import "./sidebar.scss"

export default function Sidebar() {
    const context = GetContext()

    const isLoggedIn = context.state.user && context.state.user.username

    return (
        <FlexColumn
            className="py-5 bg-white border-r border-gray-300"
            style={{
                position: "relative",
                alignItems: "center",
                height: "100vh",
                position: "fixed",
                width: 61
            }}
        >
            <MyButton href="/" icon="https://i.ibb.co/xqb3N1z/4539212.png" name="Page d'accueil" />
            <Spacer />
            <Spacer />
            <MyButton
                href="/classement/[...params]"
                as="/classement/meilleures-videos-sous-titrees/aujourdhui"
                icon="https://cdn-icons-png.flaticon.com/512/751/751463.png"
                name="Recherche"
            />
            <Spacer />
            <Spacer />

            <MyButton href="/playlist" icon="https://cdn-icons-png.flaticon.com/512/839/839860.png" name="Playlist" />
            <Spacer />
            <Divider size="small" />
            <Spacer />
            {isLoggedIn ? (
                <LoggedInButton context={context} />
            ) : (
                <MyButton
                    href="/login"
                    style={{ width: 40 }}
                    icon="https://i.ibb.co/yYVN9HT/2102647.png"
                    name="Espace membre"
                />
            )}
            {isLoggedIn && (
                <React.Fragment>
                    <Spacer />
                    <Spacer />
                    <MyButton href="/logout" icon="https://i.ibb.co/kSHqjTZ/3818096.png" name="Se déconnecter" />
                </React.Fragment>
            )}

            <Spacer />
            <div className="flex-1"></div>
            <div className="text-xs text-gray-500">#{process.env.REACT_APP_GIT_SHA}</div>
        </FlexColumn>
    )
}

function LoggedInButton({ context }) {
    const needsTransition = useAccountNeedsTransition()

    return (
        <div className="px-5">
            <Link href="/mon-compte">
                <a>
                    {needsTransition && (
                        <div style={{ position: "relative" }}>
                            <div
                                style={{
                                    position: "absolute",
                                    top: -5,
                                    right: 0,
                                    width: 20,
                                    height: 20,
                                    lineHeight: "20px"
                                }}
                                className="bg-red-700 text-center text-white rounded-full"
                            >
                                1
                            </div>
                        </div>
                    )}
                    <div style={{ marginLeft: -5, paddingTop: 5 }}>
                        <Avatar user={context.state.user} size={30} />
                    </div>
                </a>
            </Link>
        </div>
    )
}
function MyButton({ icon, name, href, as }) {
    // const router = useRouter()
    const router = {}
    const isActive = router.pathname === href
    return (
        <ReachTooltip
            label={name}
            className={`shadow rounded bg-gray-800 text-gray-300 p-2`}
            style={{ position: "absolute" }}
        >
            <div className="px-3">
                <Link href={href} as={as}>
                    <a
                        style={{ borderRadius: "50%", display: "inline-block" }}
                        className={`transition hover:bg-gray-200 p-2 ${isActive ? "bg-gray-200" : ""}`}
                    >
                        <Icon name={icon} alt={name} style={{ width: 20, height: 20 }} />
                    </a>
                </Link>
            </div>
        </ReachTooltip>
    )
}
