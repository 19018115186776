import { CheckboxForm, Form, InputForm, SelectForm, SubmitButton } from "@marg51/react-forms-ui"
import { Button, FlexColumn, FlexRow, Spacer } from "@marg51/react-ui"
import React, { useState } from "react"
import { useQuery } from "urql"
import { SUGGESTION_QUERY, useMutationUpdate, useMutationUpvoteSuggestion } from "../../api/suggestions"
import { GetContext } from "../../utils/context"
import IconButton from "../ui/IconButton"
import UserSignupPrompt from "../User/UserSignupPrompt"
import SuggestionTranslation, { SUGGESTION_TRANSLATION_QUERY } from "./Translation"

export default function SuggestionAdmin({ suggestionId = null, onRequestClose }) {
    const [id, setId] = useState(suggestionId)

    if (id) return <Suggestion suggestionId={id} unselect={() => setId(null)} onRequestClose={onRequestClose} />
    return <Suggestions selectId={setId} />
}
function Suggestion({ suggestionId, onRequestClose }) {
    const [suggestionQuery] = useQuery({
        query: SUGGESTION_TRANSLATION_QUERY,
        variables: { id: suggestionId },
        pause: !suggestionId
    })
    const [_, update] = useMutationUpdate()

    const [translationId, setTranslationId] = useState(null)
    const [showForm, setShowForm] = useState(true)

    const item = suggestionQuery.data?.getSuggestion
    if (!item) return null

    const translation = item.translations.find((elm) => elm.id === translationId)

    return (
        <FlexColumn className="p-4">
            <div className="bg-gray-200 rounded p-2">
                <Item data={item} onEdit={() => setShowForm(!showForm)} />
            </div>
            <Spacer />
            {showForm ? (
                <Form
                    defaultValues={{ ...item, youtube_url: "https://www.youtube.com/watch?v=" + item.youtube_url }}
                    onSubmit={(data) => update({ id: suggestionId, input: data }).then(onRequestClose)}
                >
                    <FlexColumn>
                        <InputForm required title="Titre" name="name" />
                        <Spacer />
                        <InputForm title="Musique ID" name="track_id" />
                        <Spacer />
                        <InputForm required title="Artiste" name="artist_name" />
                        <Spacer />
                        <InputForm title="Artiste ID" name="artist_id" />
                        <Spacer />
                        <InputForm required title="Lien YT" name="youtube_url" />
                        <Spacer />
                        <SelectForm title="Statut" name="status">
                            <option value="idle">En attente</option>
                            <option value="in_progress">En progrès</option>
                            <option value="cancelled">Refusée</option>
                            <option value="completed">Terminée, encore visible</option>
                            <option value="done">Terminée, cachée</option>
                        </SelectForm>
                        <Spacer />
                        <CheckboxForm name="ok_translation" title="Accepte traductions?" />
                        <Spacer />
                        {/* <InputForm multiline required title="Lyrics" name="original_text" rows={15} /> */}
                        <Spacer />
                        <SubmitButton>Mettre à jour</SubmitButton>
                    </FlexColumn>
                </Form>
            ) : null}
            <Spacer />
            <div className="font-semibold">Traductions ({item.translations.length})</div>
            <Spacer size="small" />
            <div className="bg-gray-200 rounded p-4">
                {item.translations.map((translation) => (
                    <div
                        onClick={() => setTranslationId(translation.id)}
                        className="rounded clickable underline"
                        style={translationId === translation.id ? { border: "1px solid #059669" } : {}}
                    >
                        • Envoyée par <span className="italic">{translation.created_by.username}</span>
                    </div>
                ))}
            </div>
            <Spacer />
            <FlexRow nowrap>
                <div className="text-xs flex-1" style={{ whiteSpace: "pre-line" }}>
                    {item.original_text}
                </div>
                <Spacer />
                {translation && (
                    <>
                        <div className="text-xs flex-1" style={{ whiteSpace: "pre-line" }}>
                            {translation.text}
                        </div>
                    </>
                )}
            </FlexRow>
        </FlexColumn>
    )
}
function Suggestions() {
    const [suggestionsListQuery] = useQuery({ query: SUGGESTION_QUERY })

    if (!suggestionsListQuery.data) return null

    return (
        <FlexColumn>
            {suggestionsListQuery.data.getSuggestions.map((item) => (
                <Item data={item} />
            ))}
        </FlexColumn>
    )
}

function Item({ data, onEdit }) {
    return (
        <FlexRow className="items-center rounded">
            <div className="font-bold text-lg text-gray-700 flex justify-center rounded-lg bg-gray-100 items-center p-1">
                <div className=" flex items-center justify-center px-2" style={{ width: 35 }}>
                    {data.upvotes}
                </div>
            </div>
            <Spacer />
            <FlexColumn>
                <FlexRow>
                    <div className="text-gray-700 font-semibold ellipsis">{data.name}</div>
                    <div className="w-5">&nbsp;</div>
                    <div className="text-gray-600 ellipsis">{data.artist_name}</div>
                </FlexRow>
                {data.status === "in progress" && (
                    <div className="text-gray-600 text-sm italic">En cours de traduction</div>
                )}
                {data.status === "done" && <div className="text-gray-600 text-sm italic">Traduction terminée</div>}
            </FlexColumn>
            <Spacer />
            <div className="flex-1" />
            <div className="text-gray-700">{data.created_by.username}</div>
            {onEdit && (
                <>
                    <Spacer />
                    <Button onClick={() => onEdit()}>Modifier</Button>
                </>
            )}
        </FlexRow>
    )
}
