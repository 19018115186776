import { useReducer, useRef, useMemo } from "react"
import debounce from "lodash.debounce"
import { useMutationSearch } from "api/search"
import { useGaEvent } from "trackers/ga"

export default function useForm(defaultValue) {
    const trackEvent = useGaEvent()
    const [, doSearch] = useMutationSearch()
    const [state, dispatch] = useReducer(formReducer, {
        value: defaultValue,
        artists: [],
        tracks: [],
        status: "idle",
        queryId: 0,
    })

    const ref = useRef(
        debounce(async (value) => {
            const currentQueryId = state.queryId
            if (value.length > 1) {
                doSearch({ query: value })
                    .then(({ data }) => {
                        const items = data.Search
                        if (state.queryId === currentQueryId) {
                            dispatch({ type: "setItems", items })
                            trackEvent("event", {
                                action:
                                    items.tracks.length + items.artists.length > 0
                                        ? "search with results"
                                        : "search without results",
                                category: "Search",
                                label: value,
                            })
                        }
                    })
                    .catch(() => dispatch({ type: "errored" }))
            } else {
                dispatch({ type: "emptyQuery" })
            }
        }, 500)
    )

    const { value } = state
    const setValue = (value) => {
        if (value.length > 1) dispatch({ type: "setValue", value })
        ref.current(value)
    }

    return [value, setValue, state, dispatch]
}

function formReducer(state, action) {
    function updateState(patch) {
        return {
            ...state,
            ...patch,
        }
    }
    switch (action.type) {
        case "setValue":
            return updateState({
                value: action.value,
                status: "loading",
                queryId: state.queryId + 1,
            })
        case "setItems":
            return updateState({
                status: "loaded",
                ...action.items,
            })
        case "errored":
            return updateState({
                status: "error",
            })
        case "emptyQuery":
            return updateState({
                artists: [],
                tracks: [],
                queryId: state.queryId + 1,
                status: "idle",
            })

        default:
            return state
    }
}
