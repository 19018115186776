import { useQuery, useMutation } from "urql"
import gql from "graphql-tag"
import fragments from "./_fragments"

export const TRACKS_QUERY = gql`
    query Tracks($ids: [ID!]!) {
        tracksByIds(filter: { ids: $ids }) {
            ...trackFullFragment
        }
    }

    ${fragments.album}

    ${fragments.track}

    ${fragments.trackFull}
`
export const TRACKS_QUERY_BY_SLUG = gql`
    query Track($slug: String!, $artistSlug: String!) {
        getTrackBySlug(slug: $slug, artistSlug: $artistSlug) {
            ...trackFullFragment
        }
    }

    ${fragments.album}

    ${fragments.track}

    ${fragments.trackFull}
`

export function useTracks(ids) {
    const [result] = useQuery({ query: TRACKS_QUERY, variables: { ids } })

    return {
        fetching: result.fetching,
        error: result.error,
        fetched: !!result.data,
        tracks: result.data ? result.data.tracksByIds : [],
    }
}

const TOGGLE_FAVOURITE_MUTATION = gql`
    mutation ToggleFavourite($id: ID!, $value: Boolean!) {
        ToggleFavourite(id: $id, value: $value)
    }
`
export function useMutationToggleFavourite() {
    return useMutation(TOGGLE_FAVOURITE_MUTATION)
}

const TOGGLE_LIKE_MUTATION = gql`
    mutation ToggleLike($id: String!) {
        ToggleLikeTrack(track_id: $id) {
            id
            likes_count
        }
    }
`
export function useMutationToggleLike() {
    return useMutation(TOGGLE_LIKE_MUTATION)
}

const AddTrackVue = gql`
    mutation TrackVue($track_id: String!) {
        AddTrackVue(track_id: $track_id)
    }
`
export function useMutationAddTrackVue() {
    return useMutation(AddTrackVue)
}
