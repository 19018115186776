import React, { useMemo } from "react"
import { FlexColumn, Spacer, Icon, FlexRow, Button, ButtonIcon } from "@marg51/react-ui"
import Link from "next/link"

import { getContext } from "utils/context"
import InlineTrack from "Components/Track/InlineTrack"
import SmallImage from "Components/ui/SmallImage"

import { trackListContext } from "./"
import LdJson from "../../../utils/LdJson"
import { useGaEvent } from "../../../trackers/ga"

import { Provider as HierarchieProvider } from "../HierarchieContext"

export default function TrackListV3({
    items,
    image,
    title,
    alt = image,
    subtitle,
    children,
    targetRef,
    hidePlayAllAction,
    childrenAtBeginning,
    ldData = null,
    listName,
    ...props
}) {
    const trackIds = useMemo(() => {
        return items.map(({ id }) => id)
    }, [items])

    return (
        <HierarchieProvider value={listName}>
            <trackListContext.Provider value={trackIds}>
                <FlexRow className="bg-gray-800 rounded-t" style={{ height: 60, alignItems: "center" }}>
                    <div className="flex-1">
                        <Header
                            title={title}
                            image={image}
                            alt={alt}
                            playlist={!hidePlayAllAction && items}
                            subtitle={subtitle}
                        >
                            {title}
                        </Header>
                    </div>
                </FlexRow>

                <div className="bg-white rounded-b shadow" style={{ paddingTop: 15 }}>
                    {ldData && <LdJson data={ldData} />}
                    <FlexColumn targetRef={targetRef}>
                        {children && childrenAtBeginning ? (
                            <React.Fragment>
                                {children}
                                <Spacer />
                            </React.Fragment>
                        ) : null}
                        {items.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <InlineTrack item={item} index={index + 1} {...props} />
                                <Spacer />
                            </React.Fragment>
                        ))}
                        {children && !childrenAtBeginning ? (
                            <React.Fragment>
                                <Spacer />
                                {children}
                            </React.Fragment>
                        ) : null}
                    </FlexColumn>
                </div>
                <Spacer size="large" />
            </trackListContext.Provider>
        </HierarchieProvider>
    )
}

const Header = ({ children, image, alt, playlist, subtitle, imageTo, title }) => {
    const context = getContext()
    const trackEvent = useGaEvent()

    function playAll() {
        context.setPlaylist(
            playlist.map((_) => _.id),
            "TrackListV3"
        )
        typeof window !== "undefined" &&
            trackEvent("event", {
                category: "action",
                action: "Changed Playlist",
                label: window.location.pathname + " " + title,
            })
    }

    return (
        <FlexRow
            style={{
                paddingLeft: 10,
                paddingRight: 15,
                fontSize: 12,
                alignItems: "center",
                zIndex: 1,
            }}
            nowrap
        >
            {image ? (
                <div
                    className="bg-white"
                    style={{ marginRight: 21, width: 44, height: 44, padding: 2, borderRadius: "50%" }}
                >
                    {imageTo ? (
                        <Link href={imageTo}>
                            <a>
                                <SmallImage src={image} alt={alt} style={{ borderRadius: "50%" }} />
                            </a>
                        </Link>
                    ) : (
                        <SmallImage src={image} alt={alt} style={{ borderRadius: "50%" }} />
                    )}
                </div>
            ) : (
                <div style={{ width: 65 }} />
            )}
            <FlexColumn className="flex-1">
                <div className="font-semibold text-white uppercase">{children}</div>
                <div className="text-gray-400">{subtitle}</div>
            </FlexColumn>
            <Spacer />
            {playlist ? (
                context.state.isDesktop && false ? (
                    <Button theme="gray" type="secondary" icon="play" onClick={playAll}>
                        TOUT LIRE
                    </Button>
                ) : (
                    <ButtonIcon
                        theme="gray"
                        icon="play"
                        onClick={playAll}
                        style={{
                            borderRadius: "50%",
                            fontSize: 16,
                        }}
                    />
                )
            ) : null}
        </FlexRow>
    )
}
