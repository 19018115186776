import React from "react"
import { FlexColumn, FlexRow, Spacer, Alert } from "@marg51/react-ui"
import { useQuery } from "api/urql"
import { getAuth0Account } from "api/users"

export default function ValidateEmail() {
    const [{ fetching, data }] = useQuery(getAuth0Account)

    console.log({ data })

    if (fetching || !data || !data.getAuth0Account) {
        return null
    }

    if (!data.getAuth0Account.email_verified) return <Alert theme="red">Vous n'avez pas vérifié votre email</Alert>

    return null
}
