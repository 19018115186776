import React from "react"
import flatten from "lodash.flatten"

import { FlexColumn, Spacer, Icon } from "@marg51/react-ui"

import PageContainer from "Components/ui/PageContainer"

import { getContext } from "utils/context"
import { useQuery } from "api/urql"
import { TOP_ARTISTS_QUERY } from "api/artists"

import * as TrackList from "Components/Track/TrackList"
import PageHeader from "Components/ui/PageHeader"
import TrackListLoader from "Components/Track/TrackListLoader"
import { RenderBigArtist } from "Components/Artist/RenderBigArtist"
import PageTitle from "utils/PageTitle"
export const Block = TrackList.V3

export default function Artists() {
    const [result, errorComponent] = useQuery(TOP_ARTISTS_QUERY)

    if (errorComponent) {
        return errorComponent
    }
    if (result.fetching) {
        return (
            <PageContainer header={<ArtistsHeader />}>
                <PageTitle>Le TOP 5 des artistes sur TraduZic</PageTitle>
                <FlexColumn>
                    <TrackListLoader />
                    <Spacer size="large" />
                    <TrackListLoader />
                </FlexColumn>
            </PageContainer>
        )
    }

    const items = result.data.artists

    return (
        <PageContainer header={<ArtistsHeader image={items[1].image} items={items} />}>
            <FlexColumn>
                <div style={{ flexShrink: 0, flex: 1 }}>
                    <FlexColumn>
                        {items.map((artist) => (
                            <RenderBigArtist artist={artist} key={artist.id} />
                        ))}

                        <div style={{ flex: 1, flexShrink: 1 }} />
                    </FlexColumn>
                </div>
            </FlexColumn>
        </PageContainer>
    )
}

function ArtistsHeader({ image, items }) {
    const context = getContext()
    return (
        <PageHeader image={image}>
            <React.Fragment>
                <div className="flex-1">Les plus gros artistes</div>
                <div
                    className="clickable text-gray-800"
                    onClick={() =>
                        items &&
                        context.setPlaylist(
                            flatten(items.map((_) => _.topTracks)).map((_) => _.id),
                            "/artists top tracks"
                        )
                    }
                >
                    <Icon name="play-circle" style={{ fontSize: 40 }} />
                </div>
            </React.Fragment>
        </PageHeader>
    )
}
