import { Form, InputForm, SubmitButton } from "@marg51/react-forms-ui"
import { Button, FlexColumn, FlexRow, Icon, Input, Modal, Spacer } from "@marg51/react-ui"
import gql from "graphql-tag"
import React, { useState } from "react"
import { useQuery } from "urql"
import _fragments from "../../api/_fragments"
import { GetContext } from "../../utils/context"
import UserSignupPrompt from "../User/UserSignupPrompt"
import SuggestionTranslationForm from "./Form"
import Item from "./Item"

export const SUGGESTION_SEARCH_QUERY = gql`
    query searchSuggestions($url: String!) {
        searchSuggestions(url: $url) {
            id
            name
            artist_name
            ok_translation
            youtube_url
            track {
                ...trackFullFragment
            }
            upvotes
        }
    }
    ${_fragments.album}

    ${_fragments.track}

    ${_fragments.trackFull}
`

const Div = (props) => <div {...props} />

export default function SuggestionSearch() {
    const [search, setSearch] = useState("")
    const [result] = useQuery({
        query: SUGGESTION_SEARCH_QUERY,
        variables: { url: search },
        pause: search.length === 0
    })
    const context = GetContext()

    const isLoggedIn = context.state.user && context.state.user.username
    const Wrapper = isLoggedIn ? Div : UserSignupPrompt
    return (
        <>
            <div className="font-semibold text-xs uppercase text-gray-600">Faites une demande</div>
            {result.data && search && (
                <>
                    <FlexRow nowrap className="text-sm text-gray-600 items-center">
                        <div className="flex-1 ellipsis">{search}</div>
                        <Button type="tertiary" onClick={() => setSearch("")}>
                            Annuler
                        </Button>
                    </FlexRow>
                    <div style={{ height: 15 }} />
                </>
            )}
            {result.data && search ? (
                <FlexColumn className="bg-white p-2 shadow rounded" style={{ padding: 15 }}>
                    {result.data?.searchSuggestions.length > 0 ? (
                        <>
                            <div>Une demande existe:</div>
                            {result.data?.searchSuggestions.map((e) => (
                                <Item data={e} />
                            ))}
                        </>
                    ) : (
                        <SuggestionTranslationForm youtube_url={search} onSubmit={() => setSearch("")} />
                    )}
                </FlexColumn>
            ) : (
                <Form onSubmit={(data) => setSearch(data.search)}>
                    <FlexRow>
                        <FlexColumn className="flex-1">
                            <InputForm
                                title=""
                                name="search"
                                pattern={
                                    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
                                }
                                errorMessages={{ pattern: "Entrez une url youtube" }}
                                placeholder="https://youtube.com?v=..."
                            />
                            <div className="text-xs text-gray-600">Entrez le lien youtube de la musique</div>
                        </FlexColumn>
                        <Spacer />
                        <Wrapper>
                            <div>
                                <SubmitButton submit={isLoggedIn} icon="search" style={{ height: 42 }}>
                                    Envoyer
                                </SubmitButton>
                            </div>
                        </Wrapper>
                    </FlexRow>
                </Form>
            )}
        </>
    )
}
