import React, { useState, useEffect } from "react"
import { FlexRow, FlexColumn, Spacer } from "@marg51/react-ui"
import usePlayerSize from "../utils/usePlayerSize"
import Loader from "./ui/Loader"

export default function ActiveTrackSidebarLoader() {
    const size = usePlayerSize()

    // const [count, setCount] = useState(0)

    // useEffect(() => {
    //     const timeout = setTimeout(() => setCount(c => c + 1), 1000)

    //     return () => clearTimeout(timeout)
    // }, [count])

    return (
        <FlexColumn
            className="items-center justify-center flex-1 mx-auto"
            style={{ overflow: "hidden", width: size[0], height: size[1] }}
        >
            <Loader />
        </FlexColumn>
    )

    return (
        <FlexColumn style={{ overflow: "hidden" }}>
            <div style={{ height: size[1], width: size[0] }} className="bg-black rounded-t" />
            <div style={{ height: 71 }} className="bg-white border border-gray-300 rounded-b">
                <div className="m-4 bg-gray-300 p-4 rounded" />
            </div>

            <Spacer />

            <div style={{ flexShrink: 0, position: "relative", marginTop: 15 }}>
                <FlexRow
                    nowrap
                    className="rounded-t bg-gray-600"
                    style={{ position: "relative", alignItems: "center", padding: "5px 0", height: 58 }}
                >
                    <div
                        className="rounded-lg bg-gray-600 _border-gray-600 _border-2"
                        style={{
                            padding: 3,
                            flexShrink: 0,
                            zIndex: 1,
                            height: 81,
                            width: 81,
                            position: "absolute",
                            top: -12,
                            left: 15,
                            overflow: "auto",
                        }}
                    ></div>
                    <div style={{ width: 81 + 15 }} />
                    <Spacer />
                </FlexRow>
                <FlexColumn
                    className="rounded-b bg-white p-4 border-l border-r border-b border-gray-300"
                    style={{
                        flexShrink: 0,
                        position: "relative",
                        height: 100,
                    }}
                ></FlexColumn>
            </div>
        </FlexColumn>
    )
}

function Item({ multiplier, baseY }) {
    return (
        <React.Fragment>
            <rect x="10" y={baseY + multiplier * 55} rx="3" ry="3" width="30" height="30" />
            <rect x="65" y={baseY + multiplier * 55} rx="4" ry="4" width="120" height="13" />
            <rect x="65" y={baseY + 20 + multiplier * 55} rx="4" ry="4" width="90" height="9" />
            <rect x="375" y={baseY + 10 + multiplier * 55} rx="2" ry="2" width="10" height="5" />
        </React.Fragment>
    )
}
