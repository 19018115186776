import React from "react"
import { FlexColumn, FlexRow, Spacer } from "@marg51/react-ui"
// import "./PlayingBars.scss"
import { getContext } from "../../utils/context"

export default function PlayingBars() {
    const context = getContext()
    return (
        <div className={`playing-bars-animations ${context.state.player.status === "playing" ? "playing" : ""}`}>
            <div />
            <div />
            <div />
            <div />
        </div>
    )
}
