export default {
    track: `fragment trackFragment on Track {
        id
        name
        slug
        vues
        comments_count
        likes_count
        langue
        image
        video {
            id
            url
            created_at
            traduction {
                id
                created_at
                sf_guard_user {
                    id
                    username
                }
            }
        }
        artist {
            id
            name
            slug
            image
        }
        feat_artists {
            name
            id
            image
            slug
        }
    }`,
    trackFull: `fragment trackFullFragment on Track {
        id
        ...trackFragment
        video {
            traduction {
                text
            }
        }
        album {
            ...albumFragment
        }
    }`,
    album: `fragment albumFragment on Album {
        id
        name
        year
        vues
        image
        slug
        # tracks_count
    }`,
    albumReferal: `fragment AlbumReferalFragment on AlbumReferal {
        id
        amazon_url
        name
        description
        artist {
            id
            name
            slug
        }
        album {
            id
            name
            slug
            tracks {
                ...trackFragment
            }
        }
    }`,
    comment: `fragment commentFragment on Comment {
        id
        text
        username_v1
        created_at
        likes_count
        track {
            id
        }
        user {
            id
            username
        }
        reply_to {
            id
            user {
                id
                username
            }
        }
    }`,
    artist: `fragment artistFragment on Artist {
        id
        name
        slug
        vues
        total_views
        tracks_count
        albums_count
        feats_count
    }`,
    me: `fragment meFragment on User {
        id
        username
        auth0_id
        token
        role
        tracks_liked {
            id
        }
        comments_liked {
            id
        }
        track_favourites {
            id
        }
        artist_favourites {
            id
        }
    }`
}
