import React from "react"
import ContentLoader from "react-content-loader"
import { FlexRow, FlexColumn } from "@marg51/react-ui"

export default function TrackListLoader() {
    return (
        <FlexColumn style={{ position: "relative", overflow: "hidden", height: 320 }}>
            <div className="bg-gray-600 rounded-t" style={{ height: 60 }} />
            <div className="bg-white rounded-b flex-1 border border-gray-600" />
            <div style={{ position: "absolute", top: 0, right: 0, left: 0, height: 320 }}>
                <ContentLoader height={320} width={400} speed={2} primaryColor="#edf2f7" secondaryColor="#e2e8f0">
                    <circle cx="25" cy="20" r="15" />
                    <circle cx="380" cy="20" r="8" />
                    <rect x="65" y="13" rx="4" ry="4" width="100" height="13" />
                    <Item multiplier={0} baseY={60} />
                    <Item multiplier={1} baseY={60} />
                    <Item multiplier={2} baseY={60} />
                </ContentLoader>
            </div>
        </FlexColumn>
    )
}

function Item({ multiplier, baseY }) {
    return (
        <React.Fragment>
            <rect x="10" y={baseY + multiplier * 55} rx="3" ry="3" width="30" height="30" />
            <rect x="65" y={baseY + multiplier * 55} rx="4" ry="4" width="120" height="13" />
            <rect x="65" y={baseY + 20 + multiplier * 55} rx="4" ry="4" width="90" height="9" />
            <rect x="375" y={baseY + 10 + multiplier * 55} rx="2" ry="2" width="10" height="5" />
        </React.Fragment>
    )
}
