import React from "react"
import ButtonSelect from "../ui/ButtonSelect"
import { getContext } from "../../utils/context"
import { Spacer } from "@marg51/react-ui"
import TopArtists from "./TopArtists"
import TopTracks from "./TopTracks"
import LastTracks from "./LastTracks"
import { useRouter } from "next/router"
import Link from "next/link"
import { Provider } from "../Track/HierarchieContext"

export default function SearchTop() {
    const context = getContext()
    const { query, push: navigate } = useRouter()

    const isTopTracks = query.params[0] === "meilleures-videos-sous-titrees"
    const isNewest = query.params[0] === "dernieres-traductions-de-clips"
    const isSelection = query.params[0] === "videos-traduites-selectionnees-pour-vous"

    return (
        <React.Fragment>
            <ButtonSelect>
                <Link href="/classement/[...params]" as="/classement/videos-traduites-selectionnees-pour-vous">
                    <ButtonSelect.Item active={isSelection}>Top Artistes</ButtonSelect.Item>
                </Link>
                <Spacer size="small" />
                <Link href="/classement/[...params]" as="/classement/meilleures-videos-sous-titrees/aujourdhui">
                    <ButtonSelect.Item active={isTopTracks}>Top Vidéos</ButtonSelect.Item>
                </Link>
                <Spacer size="small" />
                <Link href="/classement/[...params]" as="/classement/dernieres-traductions-de-clips/">
                    <ButtonSelect.Item active={isNewest}>Nouveautés</ButtonSelect.Item>
                </Link>
            </ButtonSelect>
            <Spacer />
            <Provider value="SearchTop">
                {isSelection && <TopArtists />}
                {isTopTracks && <TopTracks />}
                {isNewest && <LastTracks />}
            </Provider>
        </React.Fragment>
    )
}
