import { localStorageRemoveItem } from "../utils/localStorage"
import { useAuth0 } from "auth/Provider"
import * as config from "config"

export default function LogoutPage() {
    const { logout } = useAuth0()
    logout({ federated: true })

    fetch(`${config.auth0_domain}/v2/logout`).then(() => {
        window.location.href = "/"
    })

    localStorageRemoveItem("tz.user")
    localStorageRemoveItem("urql.token")
    localStorageRemoveItem("tz.auth0_token")

    return null
}
