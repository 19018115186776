import React, { useState, useEffect } from "react"
import { useQuery } from "api/urql"
import TrackListLoader from "../Track/TrackListLoader"
import TrackList from "../Track/TrackList/V3"
import gql from "graphql-tag"
import _fragments from "../../api/_fragments"
import ButtonShowMore from "../ui/ButtonShowMore"
import BaseHead from "../Head"

const QUERY = gql`
    query HomePage($offset: Int) {
        tracks(type: last, offset: $offset) {
            ...trackFullFragment
        }
    }
    ${_fragments.track}
    ${_fragments.trackFull}
    ${_fragments.album}
`
export default function LastTracks() {
    const [offset, setOffset] = useState(0)
    const [result, errorComponent] = useQuery(QUERY, { offset })

    useEffect(() => {
        const elm = document.querySelector("[data-id=prevent-scroll-when-dragging]")
        if (elm && elm.scrollTo) {
            elm.scrollTo(0, 0)
        }
    }, [result.data])

    if (errorComponent) {
        return errorComponent
    }
    if (result.fetching) {
        return <TrackListLoader />
    }

    const { tracks } = result.data

    return (
        <React.Fragment>
            <BaseHead
                title="Les dernières traductions et paroles de chansons"
                description="Toutes les nouveautés en un coup d'oeil"
            />
            <TrackList
                listName="last-track"
                title={`Derniers ajouts ${offset ? 2 : 1}/2`}
                items={tracks}
                showArtist
                showArtistImage
            >
                {offset === 0 ? (
                    <ButtonShowMore onClick={() => setOffset(offset + 20)}>Afficher la page 2/2</ButtonShowMore>
                ) : (
                    <ButtonShowMore onClick={() => setOffset(0)}>Afficher la page 1/2</ButtonShowMore>
                )}
            </TrackList>
        </React.Fragment>
    )
}
