import { Form, InputForm, SubmitButton } from "@marg51/react-forms-ui"
import { Button, FlexColumn, FlexRow, Spacer } from "@marg51/react-ui"
import React, { useState } from "react"
import { useMutationCreate } from "../../api/suggestions"

export default function SuggestionForm({ onSubmit, youtube_url }) {
    const [basicData, setBasicData] = useState({ youtube_url })
    const [step, setStep] = useState(0)

    const [, createSuggestion] = useMutationCreate()
    return (
        <>
            {step === 0 && (
                <Form
                    onSubmit={(data) => {
                        setBasicData({ ...basicData, ...data, original_text: "" })
                        // setStep(1) no lyrics yet
                        setStep(2)
                    }}
                    defaultValues={basicData}
                >
                    <FlexColumn>
                        <div>Entrez les informations de la musique:</div>
                        <Spacer />
                        <InputForm
                            title="Nom de l'artiste"
                            name="artist_name"
                            required
                            errorMessages={{ required: "Nom de l'artiste est requis" }}
                        />
                        <Spacer />
                        <InputForm
                            title="titre de la musique"
                            name="name"
                            required
                            errorMessages={{ required: "Titre de la musique est requis" }}
                        />
                        <Spacer />
                        <SubmitButton>Suivant</SubmitButton>
                    </FlexColumn>
                </Form>
            )}
            {step === 1 && (
                <Form
                    onSubmit={(data) => {
                        setBasicData({ ...basicData, ...data })
                        setStep(2)
                    }}
                    defaultValues={basicData}
                >
                    <FlexColumn>
                        <FlexRow>
                            <div className="flex-1">
                                {basicData.name} - {basicData.artist_name}
                            </div>
                            <Button onClick={() => setStep(0)} type="tertiary">
                                Modifier
                            </Button>
                        </FlexRow>
                        <InputForm
                            title={<>Paroles originales de la musique</>}
                            name="original_text"
                            multiline
                            rows={8}
                            required
                            errorMessages={{ required: "Les paroles sont obligatoires" }}
                        />
                        <Spacer />
                        <div>
                            Les paroles sont nécessaires pour la traduction. Vous trouverez certainement les paroles sur{" "}
                            <a
                                className="underline bg-gray-300"
                                href={`https://genius.com/search?q=${encodeURIComponent(
                                    basicData.name + " " + basicData.artist_name
                                )}`}
                                target="_about"
                            >
                                Genius
                            </a>
                            . <br />
                            Copiez puis collez les paroles
                        </div>
                        <Spacer />
                        <SubmitButton>Valider</SubmitButton>
                    </FlexColumn>
                </Form>
            )}
            {step === 2 && (
                <FlexColumn>
                    <FlexRow>
                        <div className="flex-1">
                            {basicData.name} - {basicData.artist_name}
                        </div>
                        <Button onClick={() => setStep(0)} type="tertiary">
                            Modifier
                        </Button>
                    </FlexRow>
                    <div
                        className="text-xs text-gray-700"
                        style={{ whiteSpace: "pre-line", maxHeight: 250, overflow: "auto" }}
                    >
                        {basicData.original_text}
                    </div>
                    <Spacer />
                    <div>
                        <Button theme="blue" onClick={() => createSuggestion({ input: basicData }).then(onSubmit)}>
                            Envoyer
                        </Button>
                    </div>
                </FlexColumn>
            )}
        </>
    )
}
