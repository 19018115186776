import React, { useState } from "react"
import CommentForm from "./Comment_form"
import { FlexRow, Icon, Spacer } from "@marg51/react-ui"
import Link from "next/link"
import { GetContext } from "../../utils/context"

export default function CommentCta({ track }) {
    const context = GetContext()
    const isLoggedIn = context.state.user && context.state.user.username
    const [isCommenting, setIsCommenting] = useState(false)

    if (!isLoggedIn) {
        return (
            <FlexRow
                className="rounded-lg bg-gray-200 p-2 text-gray-600 disabled italic mx-2"
                style={{ alignItems: "center" }}
                nowrap
            >
                <div style={{ width: 52, textAlign: "center" }}>
                    <Icon name="comment-o" />
                </div>
                <Link href="/login">
                    <a>
                        <u>Connectez-vous pour commenter</u>
                    </a>
                </Link>
            </FlexRow>
        )
    }

    if (isCommenting) {
        return <CommentForm track={track} onCancel={() => setIsCommenting(false)} shortLegend />
    }
    return (
        <FlexRow
            className="rounded-lg bg-gray-200 p-2 text-gray-600 hover:text-gray-800 italic clickable mx-2 shadow"
            onClick={() => setIsCommenting(true)}
            style={{ alignItems: "center" }}
        >
            <div style={{ width: 52, textAlign: "center" }}>
                <Icon name="comment-o" />
            </div>
            Ajouter un commentaire!
        </FlexRow>
    )
}
