import React from "react"
import { FlexColumn, Spacer } from "@marg51/react-ui"
import { useQuery } from "api/urql"
import { LATEST_TRACKS_WITH_COMMENTS } from "../../api/comments"
import CommentListLoader from "./CommentListLoader"
import InlineTrack from "../Track/InlineTrack"
import { CommentListV2 } from "./CommentListV2"
import CommentCta from "./Comment_cta"
export default function LatestComments() {
    const [result, errorComponent] = useQuery(LATEST_TRACKS_WITH_COMMENTS, { filter: {}, limit: 5 })

    if (errorComponent) {
        return errorComponent
    }
    if (result.fetching) {
        return <CommentListLoader />
    }

    const data = result.data.getLatestTracksWithComments

    return data.map(track => (
        <React.Fragment>
            <div className="rounded shadow">
                <div className="bg-gray-300 border border-gray-300 rounded-t py-1" style={{ paddingLeft: 5 }}>
                    <InlineTrack item={track} showArtist showArtistImage hideStats />
                </div>
                <FlexColumn className="bg-white p-3 rounded-b">
                    <Spacer />
                    <CommentListV2 track={track} limit={3} />
                    <Spacer />
                    <CommentCta track={track} />
                </FlexColumn>
            </div>

            <Spacer size="large" />
        </React.Fragment>
    ))
}
