import { Form, InputForm, SubmitButton } from "@marg51/react-forms-ui"
import { Alert, Button, ButtonIcon, FlexColumn, palet, Spacer } from "@marg51/react-ui"
import { useMutationLogin } from "api/users"
import Page from "Components/ui/Page"
import { useRouter } from "next/router"
import React, { useEffect, useReducer } from "react"
import bugsnagClient from "trackers/bugsnag"
import { getContext } from "utils/context"
import PageTitle from "utils/PageTitle"
import BaseHead from "../Components/Head"
import Signup from "../Components/User/Signup"
import SvgDiscord from "../svg/Discord"
import { useGaEvent } from "../trackers/ga"
import { localStorageSetItem } from "../utils/localStorage"

export default function Login() {
    const trackEvent = useGaEvent()
    const context = getContext()

    const [result, sendMutation] = useMutationLogin()

    useEffect(() => {
        if (!result.fetching && result.data && result.data.Login) {
            // duplicated UserData
            HandleLogin(context, result.data.Login)
            trackEvent("event", { category: "account", action: "login", label: result.data.Login.username })
            router.push("/")
        }
    }, [result.fetching])

    const router = useRouter()

    if (result.data && result.data.Login && result.data.Login.username) {
        return null
    }

    return (
        <Page header={<div className="text-center flex-1 uppercase text-sm font-semibold my-2">Connectez-vous!</div>}>
            <FlexColumn>
                <BaseHead
                    title="Connectez-vous pour avoir accès à toutes les vidéos sous-titrées"
                    description="Comprenez vos artistes favoris facilement grâce à nos vidéos traduites et sous-titrées à la main"
                />
                <Signup />
                <Spacer size="large" />
                {/* <Form name="login" onSubmit={sendMutation}>
                    <h1
                        className="font-semibold text-gray-800 bg-gray-300 border border-gray-300 rounded-t"
                        style={{ padding: 15 }}
                    >
                        Vous avez créé un compte sur l'ancienne version? Connectez-vous ici
                    </h1>
                    <FlexColumn className="bg-white mx-auto rounded-b border border-gray-300" style={{ padding: 15 }}>
                        <Input required title="Votre pseudo ou email" name="email" placeholder="xxx@gmail.com" />
                        <Spacer />
                        <Input required title="Votre Mot de Passe" name="password" type="password" placeholder="xxx" />
                        <Spacer />
                        <SubmitButton>Login avec votre compte créé avant le 16 Avril 2020</SubmitButton>
                        {result &&
                            result.error &&
                            (result.error.message === "[GraphQL] Auth0 Account" ? (
                                <Alert theme="red">
                                    <Alert.Title>Vous utilisez un compte créé après le 16 Avril 2020.</Alert.Title>
                                    Utilisez le bouton "se connecter" en haut de cette page
                                </Alert>
                            ) : (
                                <Alert theme="red">
                                    <Alert.Title>L'email ou le mot de passe est incorrect</Alert.Title>
                                    Vous pouvez réessayer
                                </Alert>
                            ))}
                    </FlexColumn>
                </Form> */}
                <Spacer />
                <Alert theme="blue">
                    Si vous rencontrez le moindre problème pour vous connecter ou pour créer un compte, contactez-nous
                    sur les reseaux sociaux ou sur{" "}
                    <a
                        href="https://discord.gg/Mx5RTSZ"
                        target="_about"
                        rel="nofollow noreferrer noopener"
                        style={{ whiteSpace: "nowrap" }}
                    >
                        <u>
                            <SvgDiscord fill="#4a5568" style={{ width: 20, display: "inline" }} />
                            discord{" "}
                        </u>
                    </a>
                </Alert>
            </FlexColumn>
        </Page>
    )
}

function Input(props) {
    return <InputForm {...props} style={{ width: "100%" }} />
}

function toObject(items) {
    const list = {}
    for (const { id } of items) {
        list[id] = true
    }

    return list
}

export function HandleLogin(context, data) {
    const { track_favourites, artist_favourites, tracks_liked, comments_liked, ...userData } = data

    context.setUserData(
        userData,
        toObject(track_favourites),
        toObject(tracks_liked),
        toObject(comments_liked),
        toObject(artist_favourites)
    )
    localStorageSetItem("urql.token", userData.token)
    localStorageSetItem("tz.user", JSON.stringify(userData))
    bugsnagClient.user = {
        id: userData.id,
        username: userData.username
    }
}
