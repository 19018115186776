import React, { useState } from "react"
import { ENDPOINT } from "config"

const acceptableSizes = [40, 80]

export default function useSmallImage({ src, style = {}, size = 40, ...props }) {
    const [isLoaded, setIsLoaded] = useState(false)
    const [isError, setIsError] = useState(false)

    if (!acceptableSizes.includes(size)) {
        return <div>An error with image size {size}</div>
    }

    function onLoad() {
        setIsLoaded(true)
    }
    function onError() {
        setIsError(true)
    }

    const innerStyle = {
        minWidth: size,
        width: size,
        height: size,
        borderRadius: "5px",
        // filter: "grayscale(20%)",
        ...style,
    }
    return isError ? (
        <div style={innerStyle} className="bg-gray-100" />
    ) : (
        <picture style={{ visibility: isLoaded ? "visible" : "hidden" }} loading="lazy">
            <source
                style={innerStyle}
                srcSet={`${ENDPOINT}images/${src}-${size}x${size}.webp, ${ENDPOINT}images/${src}-${size * 2}x${size *
                    2}.webp 2x`}
                type="image/webp"
                onError={onError}
                onLoad={onLoad}
                {...props}
            />
            <img
                alt="artist or album cover"
                srcSet={`${ENDPOINT}images/${src}-${size * 2}x${size * 2}.jpeg 2x`}
                style={innerStyle}
                src={`${ENDPOINT}images/${src}-${size}x${size}.jpeg`}
                onError={onError}
                onLoad={onLoad}
                loading="lazy"
                {...props}
            />
        </picture>
    )
}
