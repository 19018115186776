import React from "react"
import { palet, FlexColumn, FlexRow, Spacer, Icon, Button, ButtonIconTooltip, ButtonIcon } from "@marg51/react-ui"
import SmallImage from "./SmallImage"
import { getContext } from "../../utils/context"
import SvgDiscord from "../../svg/Discord"

export default function PageContainer({ style, children, header }) {
    const context = getContext()
    return (
        <FlexColumn
            style={{
                height: context.state.isDesktop ? "100vh" : "auto",
                maxWidth: 500,
                margin: "0 auto 0",
                position: "relative",
                flex: 1,
                overflow: "hidden",
                // boxShadow: "hsla(0,0%,0%,0.20) 0px 10px 20px",
                marginBottom: context.state.isDesktop ? 0 : 160,
                zIndex: 1
            }}
        >
            {header}

            <div
                data-id="prevent-scroll-when-dragging"
                style={{
                    overflow: "auto",
                    paddingLeft: 2,
                    paddingRight: 2,
                    position: "relative",
                    flex: 1,
                    flexShrink: 0,
                    zIndex: 2,
                    willChange: "scroll-position",
                    "-webkit-overflow-scrolling": "touch",
                    paddingTop: 25,
                    ...style
                }}
            >
                {children}
            </div>
            <div
                className="border-t-4 border-gray-300"
                style={{ boxShadow: "0px -5px 5px #f5f6f7", zIndex: 2, flexBasis: 15 }}
            />
            <FlexRow className="justify-around">
                <Spacer size="small" />

                {/* <a href="https://twitter.com/Traduzic" target="_about" rel="nofollow noreferrer noopener">
                    <ButtonIcon icon="twitter" theme="gray" />
                </a> */}
                <a href="https://www.facebook.com/traduzic" target="_about" rel="nofollow noreferrer noopener">
                    <ButtonIcon icon="facebook" theme="gray" />
                </a>
                <a href="https://www.instagram.com/traduzic/" target="_about" rel="nofollow noreferrer noopener">
                    <ButtonIcon icon="instagram" theme="gray" />
                </a>
                <a href="https://discord.gg/Mx5RTSZ" target="_about" rel="nofollow noreferrer noopener">
                    <ButtonIcon icon={<SvgDiscord fill="#4a5568" />} theme="gray" />
                </a>
                <Spacer size="small" />
            </FlexRow>
            <Spacer />
        </FlexColumn>
    )
}
