import React from "react"
import { FlexRow, Spacer, Icon, FlexColumn, Button } from "@marg51/react-ui"
import { GetContext } from "../../utils/context"
import { useMutationToggleFavourite } from "../../api/artists"
import SmallImage from "Components/ui/SmallImage"
import useUser from "utils/useUser"
import UserSignupPrompt from "Components/User/UserSignupPrompt"

export function useAddArtistToFavourites(artist) {
    const artistId = artist.id
    const { state, addArtistFavourite, removeArtistFavourite } = GetContext()

    const [stateMutation, executeMutation] = useMutationToggleFavourite()

    const isFavourite = state.artistFavourites[artistId]

    return [
        isFavourite,
        () => {
            if (isFavourite) removeArtistFavourite(artistId, artist.name)
            else addArtistFavourite(artistId, artist.name)
            executeMutation({ id: artistId, value: !isFavourite })
        },
    ]
}

export default function ArtistFavourite({ artist, small = false }) {
    const { state } = GetContext()
    const user = useUser()
    const [isFavourite, _toggleFavs] = useAddArtistToFavourites(artist)

    function toggleFavs() {
        if (isFavourite) {
            if (!window.confirm(`Se désabonner de ${artist.name}?`)) {
                return
            }
        }
        _toggleFavs()
    }

    const Container = user ? React.Fragment : UserSignupPrompt

    if (small || !artist) {
        return (
            <Container label={`vous abonner à ${artist.name}`} style={{ width: "auto", minWidth: 300 }}>
                <Button
                    theme={isFavourite ? "gray" : "blue"}
                    icon={isFavourite ? "star" : "star-o"}
                    active={isFavourite}
                    onClick={toggleFavs}
                >
                    {isFavourite ? "Abonné" : "S'abonner"}
                </Button>
            </Container>
        )
    }

    return (
        <Container label={`vous abonner à ${artist.name}`} style={{ width: "auto", left: 0 }}>
            <Button
                type="primary"
                theme={isFavourite ? "gray" : "blue"}
                icon={isFavourite ? "star" : "star-o"}
                onClick={toggleFavs}
            >
                {isFavourite ? `Abonné` : `S'abonner à ${artist.name}`}
                <Spacer row />
                <SmallImage src={artist.slug} style={{ width: 30, height: 30, minWidth: 30 }} />
            </Button>
        </Container>
    )
}

export function ArtistFavouriteStar({ artist, ...props }) {
    const { state } = GetContext()
    const [isFavourite] = useAddArtistToFavourites(artist)
    const user = useUser()

    if (!user || !isFavourite) {
        return null
    }

    return <Icon name="star" {...props} title={`Vous êtes abonné à ${artist.name}`} />
}
