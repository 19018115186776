import { useMutation } from "urql"
import gql from "graphql-tag"
import fragments from "./_fragments"

export const LATEST_TRACKS_WITH_COMMENTS = gql`
    query getLatestTracksWithComments($limit: Int) {
        getLatestTracksWithComments(limit: $limit) {
            ...trackFragment
        }
    }
    ${fragments.track}
`

// export const COMMENT_LIST_WITH_TRACK = gql`
//     query Comment($filter: CommentFilter!, $limit: Int) {
//         comments(filter: $filter, orderBy: id, limit: $limit) {
//             ...commentFragment
//         }
//     }

//     fragment commentFragment on Comment {
//         id
//         text
//         username_v1
//         created_at
//         likes_count
//         track {
//             id
//             name
//             artist {
//                 id
//                 name
//                 image
//             }
//         }
//         user {
//             id
//             username
//         }
//         reply_to {
//             user {
//                 id
//                 username
//             }
//         }
//     }
// `

export const COMMENT_LIST = gql`
    query Comment($track_id: ID!, $limit: Int) {
        comments(filter: { track_id: $track_id }, orderBy: id, limit: $limit) {
            ...commentFragment
        }
    }

    ${fragments.comment}
`

const TOGGLE_LIKE_MUTATION = gql`
    mutation ToggleLike($id: String!) {
        ToggleLikeComment(comment_id: $id) {
            id
            likes_count
        }
    }
`
export function useMutationToggleLike() {
    return useMutation(TOGGLE_LIKE_MUTATION)
}

const CREATE_COMMENT = gql`
    mutation CreateComment($input: CommentInput!) {
        CreateComment(commentInput: $input) {
            ...commentFragment
        }
    }
    ${fragments.comment}
`
export function useMutationCreate() {
    return useMutation(CREATE_COMMENT)
}
