import { cacheExchange } from "@urql/exchange-graphcache"
import gql from "graphql-tag"
import fetch from "isomorphic-unfetch"
import React from "react"
import bugsnagClient from "trackers/bugsnag"
import { dedupExchange, fetchExchange, useQuery as _useQuery } from "urql"
import RenderUrqlError from "../Components/ui/RenderUrqlError"
import { auth0_client_id, DEV_ENDPOINT, ENDPOINT } from "../config"
import { localStorageGetItem } from "../utils/localStorage"
import { COMMENT_LIST } from "./comments"
import { SUGGESTION_QUERY } from "./suggestions"

const url = process.env.NODE_ENV === "production" ? ENDPOINT + "api-gql/" : DEV_ENDPOINT

export const options = {
    url,
    fetch,
    fetchOptions: () => {
        const token = typeof window !== "undefined" && localStorageGetItem("urql.token")
        const dtoken = typeof window !== "undefined" && localStorageGetItem("tz.dtoken")
        const auth0_token = typeof window !== "undefined" && localStorageGetItem("tz.auth0_token")
        return {
            headers: {
                Authorization: token || "",
                "ppX-GraphQL-Deduplicate": "true",
                dtoken,
                "auth0-token": auth0_token
            }
        }
    },
    exchanges: [
        dedupExchange,
        cacheExchange({
            // optimistic: {
            //     CreateComment: (args, cache, info) => {
            //         return {
            //             ...args.commentInput,
            //             id: Date.now(),
            //             __typename: "Comment",
            //         }
            //     },
            // },
            updates: {
                Mutation: {
                    CreateSuggestion: (result, args, cache) => {
                        cache.updateQuery({ query: SUGGESTION_QUERY }, (data) => {
                            data.getSuggestions.push({ ...result.CreateSuggestion, _isNew: true })

                            return data
                        })
                    },
                    CreateComment: (result, args, cache) => {
                        const template = gql`
                            fragment _ on Track {
                                id
                                comments_count
                            }
                        `

                        const track = cache.readFragment(template, "Track:" + args.commentInput.track_id)

                        cache.writeFragment(template, {
                            id: args.commentInput.track_id,
                            comments_count: track.comments_count + 1
                        })
                        cache.updateQuery(
                            { query: COMMENT_LIST, variables: { track_id: parseInt(args.commentInput.track_id) } },
                            (data) => {
                                data.comments.unshift(result.CreateComment)

                                return data
                            }
                        )
                    }
                }
            }
        }),
        fetchExchange
    ]
}

// const client = createClient(options)

// export const UrqlProvider = ({ children }) => <Provider value={client}>{children}</Provider>
// export default UrqlProvider

export const useQuery = (query, variables) => {
    const [result] = _useQuery({ query, variables })

    let errorComponent = null

    if (result.error) {
        bugsnagClient.notify(new Error("useQuery: " + result.error.message), (event) => {
            event.addMetadata("query", { query: query.definitions[0].name.value, variables, error: result.error })
        })
        errorComponent = <RenderUrqlError error={result.error} />
        // return "Une erreur est survenue"
    }

    return [result, errorComponent]
}
