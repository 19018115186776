import React from "react"
import Link from "next/link"
import { FlexColumn, FlexRow, Spacer, Icon } from "@marg51/react-ui"
import SmallImage from "Components/ui/SmallImage"

export default function ArtistCardMini({ slug, name }) {
    return (
        <Link href="/artiste/[slug]" as={`/artiste/${slug}`}>
            <a style={{ fontSize: 12 }}>
                <FlexColumn
                    style={{ width: 125 }}
                    className="rounded items-center p-1 transition hover:shadow hover:bg-white"
                >
                    <SmallImage src={slug} size={80} style={{ borderRadius: "50%" }} className="shadow" />
                    <div className="ellipsis font-semibold text-gray-800 uppercase" style={{ maxWidth: 125 }}>
                        {name}
                    </div>
                </FlexColumn>
            </a>
        </Link>
    )
}
