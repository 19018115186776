import React from "react"
import { FlexColumn, Spacer } from "@marg51/react-ui"
import { Comment } from "./Comment_list"
import { useQuery } from "api/urql"
import { COMMENT_LIST } from "../../api/comments"
import CommentListLoader from "./CommentListLoader"
import { CommentV2 } from "./Comment_listV2"
export function CommentList({ track }) {
    const [result, errorComponent] = useQuery(COMMENT_LIST, { track_id: parseInt(track.id) })

    if (errorComponent) {
        return errorComponent
    }
    if (result.fetching) {
        return <CommentListLoader />
    }

    const data = result.data.comments

    return (
        <FlexColumn className="p-4">
            {data.length === 0 ? <em className="text-gray-600">Aucun commentaire sur cette vidéo</em> : null}
            {data.map(comment => (
                <React.Fragment>
                    <CommentV2 item={comment} track={track} />
                    <Spacer />
                </React.Fragment>
            ))}
        </FlexColumn>
    )
}
