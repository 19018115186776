import React, { createContext, useContext } from "react"
import { getContext } from "./context"

const context = createContext([])

export default function PlaylistContext({ value, children }) {
    return <context.Provider value={value} children={children} />
}

export const usePlaylist = () => {
    return useContext(context)
}

export const useCurrentTrack = () => {
    const playlist = usePlaylist()
    const context = getContext()

    return playlist[context.state.player.track_position]
}
