import React from "react"
import { FlexRow, Spacer, Icon, palet } from "@marg51/react-ui"
import { getContext } from "../../utils/context"
import { usePlayerProgress } from "./YtPlayer"
import IconButton from "../ui/IconButton"
import TrackLike from "../Track/TrackLike"
import { useCurrentTrack } from "../../utils/useCurrentTrack"
import Link from "next/link"
import SmallImage from "../ui/SmallImage"
import { PlayPauseControls } from "./PlayerControls"
import { useGaEvent } from "../../trackers/ga"

export default function PlayerControlsMobile() {
    const context = getContext()
    const track = useCurrentTrack()
    const trackEvent = useGaEvent()

    const padding = "5px 10px"

    return (
        <FlexRow
            className="text-gray-800"
            style={{ flex: 1, alignItems: "center", justifyContent: "space-around", height: 42 }}
        >
            {track && (
                <React.Fragment>
                    <Link href="/traduction/[...params]" as={`/traduction/${track.artist.slug}/${track.slug}`}>
                        <a style={{ width: 40, position: "relative" }}>
                            <SmallImage src={track.artist.slug} />
                            <div
                                style={{ position: "absolute", bottom: 0, right: 0, opacity: "0.9" }}
                                className="bg-gray-700 text-gray-200 text-xs text-center rounded-lg px-1"
                            >
                                <Icon name="comments-o" /> {track.comments_count}
                            </div>
                        </a>
                    </Link>
                    <div>
                        <FlexRow nowrap style={{ alignItems: "center", justifyContent: "center", width: 175 }}>
                            <Link href="/playlist">
                                <a style={{ padding, fontSize: 20 }}>
                                    <Icon
                                        name="https://cdn-icons-png.flaticon.com/512/839/839860.png"
                                        style={{ width: 22 }}
                                    />
                                </a>
                            </Link>
                            <Spacer />
                            <div style={{ padding }}>
                                <PlayPauseControls size={30} />
                            </div>
                            <Spacer />
                            <div
                                className="clickable"
                                style={{ padding, fontSize: 20 }}
                                onClick={() => {
                                    trackEvent("event", {
                                        category: "Video",
                                        action: "Video_skipped",
                                        label: `${track.artist.name} - ${track.name}`
                                    })
                                    context.playNext()
                                }}
                            >
                                <Icon name="step-forward" />
                            </div>
                        </FlexRow>
                    </div>

                    <TrackLike track={track} small />
                </React.Fragment>
            )}
        </FlexRow>
    )
}
