import React, { useEffect, useRef, useMemo, useState } from "react"
import ReactGA from "react-ga"
import { useLocation } from "@reach/router"

import { Context } from "./ga"
import { useMutationAddEvent } from "../api/event"
const GA_ID = process.env.REACT_APP_GA || "UA-82886538-1"

export default function ContextProvider({ children }) {
    const [, addEvent] = useMutationAddEvent()

    const location = useLocation()

    const pending = useRef([])
    const init = useRef(false)

    const context = useMemo(() => {
        return {
            push: (type, params, dimensions) => {
                if (process.env.REACT_APP_ENV === "production") {
                    if (type === "pageview") addEvent({ type, label: params })
                    else if (type === "event")
                        addEvent({
                            type,
                            action: params.action,
                            label: params.label,
                            category: params.category,
                        })
                    else {
                        console.error("no event type", type, params)
                    }
                } else {
                    console.log("event", {
                        type,
                        action: params.action,
                        label: params.label,
                        category: params.category,
                        dimensions: dimensions
                            ? {
                                  url: dimensions[2],
                                  "ui context": dimensions[3],
                                  "path + ui context": dimensions[5],
                              }
                            : {},
                    })
                }

                if (!init.current) {
                    pending.current.push([type, params, dimensions])
                } else {
                    setTimeout(() => {
                        try {
                            if (dimensions) {
                                ReactGA.set({ dimension2: dimensions[2] }) // url
                                ReactGA.set({ dimension3: dimensions[3] }) // ui context
                                ReactGA.set({ dimension5: dimensions[5] }) // path + ui context
                                // ReactGA.set({ dimension4: dimensions[4] }) // artist name
                            }
                            ReactGA[type](params)
                        } catch (e) {
                            console.error(e)
                        }
                    }, 0)
                }
            },
            ReactGA,
        }
    }, [addEvent])

    useEffect(() => {
        setTimeout(() => {
            ReactGA.initialize(GA_ID)
            ReactGA.set({ dimension1: process.env.REACT_APP_BRANCH || "unset" })
            pending.current.map(([type, params, dimensions]) => {
                if (dimensions) {
                    ReactGA.set({ dimension2: dimensions[2] }) // url
                    ReactGA.set({ dimension3: dimensions[3] }) // ui context
                    ReactGA.set({ dimension5: dimensions[5] }) // path + ui context
                }
                ReactGA[type](params)
            })
            init.current = true
        }, 5000)
    }, [])

    useEffect(() => {
        context.push("pageview", location.pathname)
    }, [location.pathname])

    return <Context.Provider value={context}>{children}</Context.Provider>
}
