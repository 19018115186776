import React from "react"
import { palet, FlexColumn, FlexRow, Spacer, Icon, Button } from "@marg51/react-ui"
import SmallImage from "./SmallImage"
import { getContext } from "../../utils/context"

export default function PageHeader({ image, children }) {
    const { state } = getContext()
    return (
        <React.Fragment>
            <FlexColumn
                className={`text-white p-3 ${state.isDesktop ? "rounded-b-lg" : "rounded-lg"}`}
                style={{
                    height: 75,
                    fontSize: 28,
                    alignItems: "center",
                    position: "relative",
                    zIndex: 1,
                    overflow: "hidden",
                }}
            >
                <SmallImage
                    src={image}
                    style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        filter: "blur(20px)",
                        width: 600,
                        height: 100,
                    }}
                />
            </FlexColumn>
            {children && (
                <div className="px-2" style={{ zIndex: 2 }}>
                    <FlexRow
                        className="p-2 bg-white shadow-lg"
                        style={{
                            alignItems: "center",
                            marginTop: state.isDesktop ? -50 : -70,
                            borderRadius: 35,
                            marginBottom: 20,
                            maxWidth: 350,
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                        nowrap
                    >
                        {image && (
                            <div
                                style={{ flexShrink: 0, borderRadius: "50%", overflow: "hidden" }}
                                className="bg-white"
                            >
                                <SmallImage src={image} style={{ height: 50, width: 50 }} />
                            </div>
                        )}
                        <Spacer />
                        {children}
                        <Spacer />
                    </FlexRow>
                </div>
            )}
        </React.Fragment>
    )
}
