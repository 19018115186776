import React from "react"
import hashStringToColor from "../../utils/colors"

export default function Avatar({ user, style = {}, size = 35 }) {
    if (!user || !user.username) {
        return (
            <div
                style={{ width: size, height: size, marginRight: 10, marginLeft: 10, flexShrink: 0, ...style }}
                className="rounded-full bg-gray-300"
            />
        )
    }

    return (
        <div
            style={{
                width: size,
                height: size,
                marginRight: 10,
                marginLeft: 10,
                flexShrink: 0,
                lineHeight: `${size}px`,
                textAlign: "center",
                background: hashStringToColor(user.username),
                ...style,
            }}
            className="rounded-full text-white font-semibold"
        >
            {user.username[0]}
        </div>
    )
}
