import React, { createContext, useContext } from "react"
import { GetContext } from "./context"
import { useQuery } from "urql"
import { TRACKS_QUERY } from "../api/tracks"
import { usePlaylist } from "./PlaylistContext"

const TrackContext = createContext()

export default function CurrentTrackProvider({ children }) {
    const context = GetContext()
    const tracks = usePlaylist()

    let track
    if (tracks) {
        track = tracks[context.state.player.track_position]
    }

    return <TrackContext.Provider value={track}>{children}</TrackContext.Provider>
}

export function useCurrentTrack() {
    return useContext(TrackContext)
}
