import React from "react"
import { FlexColumn, FlexRow, Spacer, Alert } from "@marg51/react-ui"
import BaseHead from "../../Components/Head"

export default function Member() {
    return (
        <React.Fragment>
            <BaseHead>
                <meta name="robots" content="noindex" />
            </BaseHead>
            <Alert theme="blue">Le profil de ce membre n'est pas disponible actuellement</Alert>
        </React.Fragment>
    )
}
