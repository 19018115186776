import React, { useState } from "react"
import { FlexColumn, FlexRow, Spacer } from "@marg51/react-ui"
import { useGaEvent } from "trackers/ga"
import Popup from "Components/ui/Popup"
import { SignupButton, LoginButton } from "./Signup"
import { Icon } from "@marg51/react-ui/dist/index.es"

export default function UserSignupPrompt({ children, label = "", ...props }) {
    const [isOpen, setOpen] = useState()
    const trackEvent = useGaEvent()
    const child = React.Children.only(children)

    const Content = React.cloneElement(child, {
        ...child.props,
        onClick: () => {
            trackEvent("event", { category: "account", action: "UserSignupPrompt", label })

            setOpen(true)
        },
    })

    return (
        <React.Fragment>
            {Content}
            <Popup isOpen={isOpen} onRequestClose={() => setOpen(false)} {...props}>
                <FlexColumn className="bg-white rounded p-2">
                    {label && <h1 className="font-semibold">Pour {label}:</h1>}
                    <SignupButton>
                        <FlexRow style={{ width: "100%" }} className="items-center">
                            Je m'inscris
                            <Spacer />
                            <Icon name="google" className="bg-blue-700 p-2" style={{ width: 30 }} />
                            <Spacer size="small" />
                            <Icon name="facebook" className="bg-blue-700 p-2" style={{ width: 30 }} />
                        </FlexRow>
                    </SignupButton>
                    <Spacer />
                    <LoginButton>Je me connecte</LoginButton>
                </FlexColumn>
            </Popup>
        </React.Fragment>
    )
}
