import React, { createContext, useContext, useEffect, useMemo, useReducer, useRef } from "react"
import { useMediaQuery } from "react-responsive"
import { useGaEvent } from "../trackers/ga"
import { localStorageGetItem } from "./localStorage"

const TzContext = createContext()

export const GetContext = () => {
    return useContext(TzContext)
}
export const getContext = GetContext

const userData =
    typeof window !== "undefined" && localStorageGetItem("tz.user") ? JSON.parse(localStorageGetItem("tz.user")) : null

function PlayerPlay(playerRef) {
    if (playerRef) {
        playerRef.playVideo()
    }
}
function PlayerPause(playerRef) {
    if (playerRef) {
        playerRef.pauseVideo()
    }
}

export default function Context({ children }) {
    const trackEvent = useGaEvent()
    const [state, _dispatch] = useReducer(reducer, {
        player: {
            playlist: [],
            _origin_of_tracks: [],
            _origin_of_playlist: [],
            track_position: -1,
            isPlaying: false,
            subtitlesId: null,
            isFullScreen: false
        },
        favourites: {},
        artistFavourites: {},
        tracks_liked: {},
        comments_liked: {},
        isDesktop: useMediaQuery({ query: "(min-width:900px)" }),
        user: userData,
        slideUpOpen: false,
        selectedSearchTab: "top-tracks"
    })
    function dispatch(...args) {
        // console.log(...args)
        _dispatch(...args)
    }

    const playerRef = useRef()
    // const playerContainerRef = useRef()

    const isDesktop = useMediaQuery({ query: "(min-width:900px)" })
    useEffect(() => {
        if (isDesktop && !state.isDesktop) {
            dispatch({
                type: "DEVICE_TYPE:SET",
                value: "desktop"
            })
        }
        if (!isDesktop && state.isDesktop) {
            dispatch({
                type: "DEVICE_TYPE:SET",
                value: "mobile"
            })
        }
    }, [isDesktop])

    const context = useMemo(
        () => ({
            state,
            playerRef,
            // playerContainerRef,
            setPlaylist(playlist, name = "unset", label = "PLAYLIST:SET first item") {
                dispatch({
                    type: "PLAYLIST:SET",
                    playlist,
                    label,
                    name
                })
            },
            addTrackEnd(trackId, position) {
                dispatch({
                    type: "PLAYLIST:ADD_TRACK",
                    trackId,
                    position: state.player.playlist.length
                })
                trackEvent("event", {
                    category: "action",
                    action: "Added End of Playlist",
                    label: trackId
                })
            },
            addTrackNext(trackId, position) {
                dispatch({
                    type: "PLAYLIST:ADD_TRACK",
                    trackId,
                    position: state.player.track_position + 1
                })
                trackEvent("event", {
                    category: "action",
                    action: "Added To Playlist",
                    label: trackId
                })
            },
            playTrack(trackId) {
                dispatch({
                    type: "PLAYLIST:PLAY_TRACK",
                    trackId
                })
            },
            playNext(label = "PLAYLIST:NEXT") {
                dispatch({
                    type: "PLAYLIST:NEXT",
                    label
                })
                setTimeout(() => {
                    PlayerPlay(playerRef.current)
                }, 100)
            },
            playPrevious() {
                dispatch({
                    type: "PLAYLIST:PREVIOUS"
                })
                trackEvent("event", {
                    category: "player",
                    action: "clicked previous",
                    label: "-"
                })
            },
            shuffle() {
                dispatch({
                    type: "PLAYLIST:SHUFFLE"
                })
            },
            setPlayerStatus(status) {
                if (state.player.status === "unstarted" && status === "cued") {
                    PlayerPlay(playerRef.current)
                }
                dispatch({
                    type: "PLAYER:SET_STATUS",
                    status
                })
            },
            pause() {
                PlayerPause(playerRef.current)
            },
            play() {
                PlayerPlay(playerRef.current)
            },
            togglePlay() {
                state.player.status === "playing" ? PlayerPause(playerRef.current) : PlayerPlay(playerRef.current)
            },
            toggleFullScreen() {
                dispatch({
                    type: "PLAYER:TOGGLE_FULLSCREEN"
                })
                trackEvent("event", {
                    category: "player",
                    action: "toggle full screen",
                    label: state.player.isFullScreen ? "close full screen" : "open full screen"
                })
            },
            setSubtitles(subtitlesId) {
                dispatch({
                    type: "PLAYER:SET_SUBTITLES",
                    subtitlesId
                })
            },
            setUserData(user, trackFavourites, tracks_liked, comments_liked, artistFavourites) {
                dispatch({
                    type: "USER:SET_DATA",
                    user
                })
                dispatch({
                    type: "FAVOURITES:SET_TRACKS",
                    trackFavourites
                })
                dispatch({
                    type: "FAVOURITES:SET_ARTISTS",
                    artistFavourites
                })
                dispatch({
                    type: "TRACKS:SET_LIKES",
                    tracks_liked
                })
                dispatch({
                    type: "COMMENTS:SET_LIKES",
                    comments_liked
                })
            },
            addTrackFavourite(trackId) {
                dispatch({
                    type: "FAVOURITES:ADD_TRACK",
                    trackId
                })
                trackEvent("event", {
                    category: "track",
                    action: "add favourite",
                    label: trackId
                })
            },
            removeTrackFavourite(trackId) {
                dispatch({
                    type: "FAVOURITES:REMOVE_TRACK",
                    trackId
                })
                trackEvent("event", {
                    category: "track",
                    action: "remove favourite",
                    label: trackId
                })
            },
            addArtistFavourite(artistId) {
                dispatch({
                    type: "FAVOURITES:ADD_ARTIST",
                    artistId
                })
                trackEvent("event", {
                    category: "artist",
                    action: "add favourite",
                    label: artistId
                })
            },
            removeArtistFavourite(artistId) {
                dispatch({
                    type: "FAVOURITES:REMOVE_ARTIST",
                    artistId
                })
                trackEvent("event", {
                    category: "artist",
                    action: "remove favourite",
                    label: artistId
                })
            },
            addTrackLike(trackId) {
                dispatch({
                    type: "TRACKS:LIKES",
                    trackId
                })
            },
            removeTrackLike(trackId) {
                dispatch({
                    type: "TRACKS:DISLIKES",
                    trackId
                })
            },
            addCommentLike(commentId) {
                dispatch({
                    type: "COMMENTS:LIKES",
                    commentId
                })
            },
            removeCommentLike(commentId) {
                dispatch({
                    type: "COMMENTS:DISLIKES",
                    commentId
                })
            },
            openSlideUp() {
                dispatch({
                    type: "SLIDE_UP:OPEN"
                })
            },
            closeSlideUp() {
                dispatch({
                    type: "SLIDE_UP:CLOSE"
                })
            },
            toggleSlideUp() {
                dispatch({
                    type: "SLIDE_UP:TOGGLE"
                })
            },
            setDesktopMode(value) {
                dispatch({
                    type: "DEVICE_TYPE:SET",
                    value: value ? "desktop" : "mobile"
                })
            }
        }),
        [state]
    )

    return <TzContext.Provider value={context} children={children} />
}

function updatePlayer(state, patch, origin) {
    return {
        ...state,
        player: {
            ...state.player,
            ...patch
        }
    }
}

function reducer(state, action) {
    switch (action.type) {
        case "PLAYLIST:SET": {
            return updatePlayer(
                state,
                {
                    playlist: action.playlist,
                    _origin_of_tracks: [action.label, ...action.playlist.slice(1).map(() => "PLAYLIST:SET")],
                    _origin_of_playlist: action.playlist.map(() => action.name),
                    track_position: 0
                },
                "PLAYLIST:SET"
            )
        }

        case "PLAYLIST:ADD_TRACK":
            const playlist = [...state.player.playlist]

            playlist.splice(action.position, 0, action.trackId)

            const _origin_of_tracks = [...state.player._origin_of_tracks]
            _origin_of_tracks.splice(action.position, 0, "PLAYLIST:ADD_TRACK")

            const _origin_of_playlist = [...state.player._origin_of_playlist]
            _origin_of_playlist.splice(action.position, 0, "none")

            return updatePlayer(state, { playlist, _origin_of_tracks, _origin_of_playlist }, "PLAYLIST:ADD_TRACK")

        case "PLAYLIST:PLAY_TRACK":
            if (state.player.playlist.includes(action.trackId)) {
                const index = state.player.playlist.indexOf(action.trackId)
                const _origin_of_tracks = [...state.player._origin_of_tracks]
                _origin_of_tracks[index] = "PLAYLIST:PLAY_TRACK"

                return updatePlayer(
                    state,
                    {
                        track_position: index,
                        _origin_of_tracks
                        // isPlaying: true,
                    },
                    "PLAYLIST:PLAY_TRACK"
                )
            }

            return updatePlayer(
                state,
                {
                    playlist: [...state.player.playlist, action.trackId],
                    _origin_of_tracks: [...state.player._origin_of_tracks, "PLAYLIST:PLAY_TRACK"],
                    _origin_of_playlist: [...state.player._origin_of_tracks, "none"],
                    track_position: state.player.playlist.length
                },
                "PLAYLIST:PLAY_TRACK"
            )

        case "PLAYLIST:SHUFFLE":
            return updatePlayer(
                state,
                {
                    playlist: state.player.playlist.shuffle(),
                    track_position: 0
                },
                "PLAYLIST:SHUFFLE"
            )
        case "PLAYLIST:NEXT": {
            const track_position = (state.player.track_position + 1) % state.player.playlist.length
            const _origin_of_tracks = [...state.player._origin_of_tracks]
            _origin_of_tracks[track_position] = action.label
            return updatePlayer(
                state,
                {
                    track_position,
                    _origin_of_tracks
                    // isPlaying: false,
                },
                "PLAYLIST:NEXT"
            )
        }
        case "PLAYLIST:PREVIOUS": {
            const track_position =
                state.player.track_position <= 0 ? state.player.playlist.length - 1 : state.player.track_position - 1
            const _origin_of_tracks = [...state.player._origin_of_tracks]
            _origin_of_tracks[track_position] = "PLAYLIST:PREVIOUS"
            return updatePlayer(
                state,
                {
                    track_position,
                    _origin_of_tracks
                },
                "PLAYLIST:PREVIOUS"
            )
        }

        case "PLAYER:SET_STATUS":
            return updatePlayer(
                state,
                {
                    isPlaying: action.status === "playing",
                    status: action.status
                },
                "PLAYER:SET_STATUS"
            )
        // case "PLAYER:PLAY":
        //     return updatePlayer(
        //         state,
        //         {
        //             isPlaying: true,
        //         },
        //         "PLAYER:PLAY"
        //     )
        // case "PLAYER:PAUSE":
        //     return updatePlayer(
        //         state,
        //         {
        //             isPlaying: false,
        //         },
        //         "PLAYER:PAUSE"
        //     )
        // case "PLAYER:TOGGLE":
        //     return updatePlayer(
        //         state,
        //         {
        //             isPlaying: !state.player.isPlaying,
        //         },
        //         "PLAYER:TOGGLE"
        //     )
        case "PLAYER:TOGGLE_FULLSCREEN":
            return updatePlayer(state, {
                isFullScreen: !state.player.isFullScreen
            })
        case "PLAYER:SET_SUBTITLES":
            return updatePlayer(state, {
                subtitlesId: action.subtitlesId
            })

        case "USER:SET_DATA":
            return {
                ...state,
                user: action.user
            }

        case "FAVOURITES:SET_TRACKS":
            return {
                ...state,
                favourites: action.trackFavourites
            }
        case "FAVOURITES:SET_ARTISTS":
            return {
                ...state,
                artistFavourites: action.artistFavourites
            }
        case "FAVOURITES:ADD_TRACK":
            return {
                ...state,
                favourites: {
                    ...state.favourites,
                    [action.trackId]: true
                }
            }
        case "FAVOURITES:REMOVE_TRACK":
            return {
                ...state,
                favourites: {
                    ...state.favourites,
                    [action.trackId]: false
                }
            }
        case "FAVOURITES:ADD_ARTIST":
            return {
                ...state,
                artistFavourites: {
                    ...state.artistFavourites,
                    [action.artistId]: true
                }
            }
        case "FAVOURITES:REMOVE_ARTIST":
            return {
                ...state,
                artistFavourites: {
                    ...state.artistFavourites,
                    [action.artistId]: false
                }
            }
        case "TRACKS:LIKES":
            return {
                ...state,
                tracks_liked: {
                    ...state.tracks_liked,
                    [action.trackId]: true
                }
            }
        case "TRACKS:DISLIKES":
            return {
                ...state,
                tracks_liked: {
                    ...state.tracks_liked,
                    [action.trackId]: false
                }
            }
        case "TRACKS:SET_LIKES":
            return {
                ...state,
                tracks_liked: action.tracks_liked
            }
        case "COMMENTS:LIKES":
            return {
                ...state,
                comments_liked: {
                    ...state.comments_liked,
                    [action.commentId]: true
                }
            }
        case "COMMENTS:DISLIKES":
            return {
                ...state,
                comments_liked: {
                    ...state.comments_liked,
                    [action.commentId]: false
                }
            }
        case "COMMENTS:SET_LIKES":
            return {
                ...state,
                comments_liked: action.comments_liked
            }
        case "SLIDE_UP:OPEN":
            return {
                ...state,
                slideUpOpen: true
            }
        case "SLIDE_UP:CLOSE":
            return {
                ...state,
                slideUpOpen: false
            }
        case "SLIDE_UP:TOGGLE":
            return {
                ...state,
                slideUpOpen: !state.slideUpOpen
            }
        case "DEVICE_TYPE:SET":
            return {
                ...state,
                isDesktop: action.value === "desktop"
            }
        default:
            return state
    }
}
