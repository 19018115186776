import React from "react"
import { FlexColumn, Spacer } from "@marg51/react-ui"
import { useTrack } from "./useEntity"
import InlineBlockTrack from "Components/Track/InlineBlockTrack"
import { Form, InputForm, SubmitButton } from "@marg51/react-forms-ui"
import TrackCard from "Components/Track/TrackCard/V2"
import queryToObject from "../queryToObject"
// import { useParams } from "react-router-dom"
import { FlexRow } from "@marg51/react-ui"
export function LoadItem() {
    return "no"
    // const params = useParams()
    // return <Item key={params.query} filter={queryToObject(params.query)} />
}
export function TrackItem({ filter }) {
    const { fetching, item, update, error } = useTrack(filter)
    if (error) {
        return error
    }
    if (fetching) {
        return "Chargement"
    }
    return (
        <FlexColumn className="items-center flex-1 p-2">
            <FlexRow nowrap style={{ minWidth: 600 }}>
                <div className="flex-1">
                    <InlineBlockTrack item={item} showArtist />
                </div>
                <Spacer />
                <Form onSubmit={(input) => update({ id: item.id, input })}>
                    <FlexColumn>
                        {/*
                        <InputForm name="image" defaultValue={item.image} required />
                        <Spacer />
                        <InputForm name="name" defaultValue={item.name} required />
                        <Spacer />
                    */}
                        <InputForm name="catchphrase" defaultValue={item.catchphrase} />
                        <Spacer />
                        <SubmitButton>Enregistrer</SubmitButton>
                    </FlexColumn>
                </Form>
            </FlexRow>
            <Spacer />

            <div style={{ maxWidth: 500 }}>
                <TrackCard item={item} />
            </div>
        </FlexColumn>
    )
}
