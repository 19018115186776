import { Form, InputForm, SubmitButton } from "@marg51/react-forms-ui"
import { Button, FlexColumn, FlexRow, Icon, Input, Modal, Spacer } from "@marg51/react-ui"
import gql from "graphql-tag"
import React, { useState } from "react"
import { useQuery } from "urql"
import { useMutationCreateTranslation, useMutationUpvoteSuggestion } from "../../api/suggestions"
import { GetContext } from "../../utils/context"
import IconButton from "../ui/IconButton"
import UserSignupPrompt from "../User/UserSignupPrompt"
import SuggestionAdmin from "./Admin"
import SuggestionItem from "./Item"

export const SUGGESTION_TRANSLATION_QUERY = gql`
    query suggestion($id: String!) {
        getSuggestion(id: $id) {
            id
            name
            artist_name
            ok_translation
            original_text
            youtube_url
            status
            created_by {
                username
            }
            track {
                id
                name
                artist {
                    name
                    image
                }
            }
            translations {
                id
                text
                created_by {
                    username
                }
            }
            upvotes
            _isNew
        }
    }
`

export default function SuggestionTranslation({ suggestionId, isOpen, onRequestClose }) {
    const [result] = useQuery({ query: SUGGESTION_TRANSLATION_QUERY, variables: { id: suggestionId }, pause: !isOpen })
    const [, createSuggestionTranslation] = useMutationCreateTranslation()
    const context = GetContext()
    const isLoggedIn = context.state.user && context.state.user.username

    if (!isOpen) return null

    if (context.state.user?.role === "admin") {
        return (
            <Modal isOpen={isOpen} onRequestClose={() => window.confirm("Fermer?") && onRequestClose()} size="large">
                <SuggestionAdmin suggestionId={suggestionId} onRequestClose={onRequestClose} />
            </Modal>
        )
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={() => window.confirm("Fermer?") && onRequestClose()} size="large">
            <Form onSubmit={(data) => createSuggestionTranslation({ id: suggestionId, ...data }).then(onRequestClose)}>
                <FlexColumn className="p-4">
                    {result.data?.getSuggestion.translations.length > 0 && (
                        <>
                            <div className="bg-gray-300 rounded p-4">
                                <div className="font-semibold underline">Des traductions ont été envoyées:</div>
                                {result.data?.getSuggestion.translations.map((translation) => (
                                    <div>
                                        • Envoyée par <span className="italic">{translation.created_by.username}</span>
                                    </div>
                                ))}
                            </div>
                            <Spacer />
                            <Spacer />
                        </>
                    )}
                    <FlexRow>
                        <div className="flex-1" style={{ whiteSpace: "pre-line" }}>
                            <div className="underline">Version originale:</div>
                            <div
                                className="text-gray-700 bg-gray-200 rounded p-2"
                                style={{ maxHeight: 400, overflow: "auto" }}
                            >
                                {result.data?.getSuggestion.original_text}
                            </div>
                        </div>
                        <Spacer />
                        <FlexColumn className="flex-1" style={{ paddingTop: 6 }}>
                            <InputForm multiline name="text" title="Votre Traduction" style={{ height: 400 }} />
                            <Spacer />
                            <SubmitButton>Envoyer ma traduction</SubmitButton>
                        </FlexColumn>
                    </FlexRow>
                </FlexColumn>
            </Form>
        </Modal>
    )
}
