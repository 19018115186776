import React, { useMemo, useReducer, useRef, useState } from "react"
import debounce from "lodash.debounce"
import { FlexColumn, palet, Input, Spacer, Button, Alert, FlexRow, Icon } from "@marg51/react-ui"
import { InputForm, Form as FormUi } from "@marg51/react-forms-ui"

import useSearch from "Components/Search/useSearch"
import InputSearch from "Components/Search/SearchInput"
import { RenderSmallArtist } from "Components/Artist/RenderSmallArtist"
import Page from "Components/ui/Page"
import TrackList from "Components/Track/TrackList/V3"
import TrackListLoader from "Components/Track/TrackListLoader"
import SearchTop from "Components/SearchTop"
import { useGaEvent } from "../../trackers/ga"
import MostSearchedForArtists from "Components/Artist/MostSearchedForArtists"
import { getContext } from "../../utils/context"
import SvgAlgolia from "../../svg/Algolia"
import ButtonSelect from "../../Components/ui/ButtonSelect"
import { HierarchieProvider } from "../../Components/Track/HierarchieContext"
import InlineTrack from "../../Components/Track/InlineTrack"

export default function Search() {
    const context = getContext()
    const [value, setValue, state, dispatch] = useSearch()

    return (
        <Page
            rawHeader={
                <FlexRow className="px-4 py-2 items-center" style={{ position: "relative" }}>
                    <InputSearch setValue={setValue} onFocus={() => {}} />
                </FlexRow>
            }
        >
            <FlexColumn style={{ minHeight: 300, padding: 15 }}>
                {state.status === "loading" && (
                    <React.Fragment>
                        <TrackListLoader />
                    </React.Fragment>
                )}
                {state.status === "loaded" && <RenderResults data={state} />}
                {state.status === "error" && (
                    <React.Fragment>
                        <Alert theme="red">Une erreur est survenue</Alert>
                    </React.Fragment>
                )}
                <MostSearchedForArtists
                    width={500}
                    style={{
                        overflow: "auto",
                        marginRight: context.state.isDesktop ? 0 : -17,
                        paddingBottom: 10,
                        paddingLeft: 2,
                    }}
                    className={context.state.isDesktop ? "" : "bg-white rounded p-2 border border-gray-300"}
                />
                <Spacer size="large" />
                {(state.status === "idle" || state.status === "error") && <SearchTop />}
            </FlexColumn>
        </Page>
    )
}

function RenderResults({ data, isAutoComplete }) {
    const trackEvent = useGaEvent()
    const [activeTab, _setActiveTab] = useState("artist")

    function setActiveTab(tab) {
        trackEvent("event", { category: "search", action: "change recherche type", label: tab })
        _setActiveTab(tab)
    }

    return (
        <HierarchieProvider value="Search results">
            <ButtonSelect>
                <ButtonSelect.Item active={activeTab === "artist"} onClick={() => setActiveTab("artist")}>
                    Artistes ({data.artists_count > 10 ? 10 : data.artists_count})
                </ButtonSelect.Item>
                <Spacer size="small" />

                <ButtonSelect.Item active={activeTab === "track"} onClick={() => setActiveTab("track")}>
                    Vidéos ({data.tracks_count > 10 ? 10 : data.tracks_count})
                </ButtonSelect.Item>
            </ButtonSelect>
            <Spacer size="small" />
            <FlexColumn>
                {activeTab === "artist" && (
                    <React.Fragment>
                        <div className="TrackListHorizontalTitle">Artistes</div>
                        <FlexColumn className="border rounded bg-white border-gray-400 rounded-b">
                            <Spacer />
                            {data.artists.map((artist, index) => (
                                <React.Fragment>
                                    <RenderSmallArtist artist={artist} key={artist.id} />
                                    <Spacer size="small" />
                                </React.Fragment>
                            ))}
                        </FlexColumn>
                    </React.Fragment>
                )}
                {activeTab === "track" && (
                    <React.Fragment>
                        <div className="TrackListHorizontalTitle">Vidéos</div>
                        <FlexColumn className="border rounded bg-white border-gray-400 rounded-b">
                            <HierarchieProvider value="SearchResult">
                                {data.tracks.map((track) => (
                                    <React.Fragment>
                                        <InlineTrack item={track} showArtist showArtistImage />
                                        <Spacer size="small" />
                                    </React.Fragment>
                                ))}
                            </HierarchieProvider>
                        </FlexColumn>
                    </React.Fragment>
                )}
                <div style={{ alignSelf: "flex-end" }}>
                    <Icon name={<SvgAlgolia />} />
                </div>
                <Spacer size="large" />
            </FlexColumn>
        </HierarchieProvider>
    )
}
