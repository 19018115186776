import React from "react"
import { palet, FlexRow, Spacer } from "@marg51/react-ui"
import SmallImage from "../ui/SmallImage"
import Link from "next/link"
import { useGaEvent } from "../../trackers/ga"
export function RenderSmallArtist({ artist, index }) {
    const trackEvent = useGaEvent()
    return (
        <React.Fragment>
            <FlexRow style={{ alignItems: "center", paddingRight: 15 }}>
                <div style={{ paddingLeft: 15 }}>
                    <Link href="/artiste/[slug]" as={`/artiste/${artist.slug}`}>
                        <a>
                            <SmallImage src={artist.slug} />
                        </a>
                    </Link>
                </div>
                <Spacer />

                <div>
                    <Link href="/artiste/[slug]" as={`/artiste/${artist.slug}`}>
                        <a className="text-gray-700 font-semibold">{artist.name}</a>
                    </Link>
                </div>
            </FlexRow>
            <Spacer />
        </React.Fragment>
    )
}
