import { Button, FlexColumn, Icon, palet, Spacer } from "@marg51/react-ui"
import fragments from "api/_fragments"
import LatestComments from "Components/Comment/LatestComments"
import ReferalList from "Components/Referal/List"
import HomepageTop from "Components/Track/HomepageTop"
import TrackListLoader from "Components/Track/TrackListLoader"
import ButtonSelect from "Components/ui/ButtonSelect"
import Page from "Components/ui/Page"
import PageContainer from "Components/ui/PageContainer"
import gql from "graphql-tag"
import React, { useEffect } from "react"
import { useQuery } from "urql"
import { getContext } from "utils/context"
import BaseHead from "../Components/Head"
import SuggestionList from "../Components/Suggestion/List"
import { useCurrentTrack } from "../utils/useCurrentTrack"

const OTHER_QUERY = gql`
  query OtherHomePage {
    mostViewed: tracks(type: top, limit: 5) {
      ...trackFragment
    }
    selection: tracks(type: selection) {
      ...trackFragment
    }
  }
  ${fragments.track}
`
const LATEST_QUERY = gql`
  query LatestTracks {
    latest: tracks(type: last, limit: 5) {
      ...trackFragment
    }
  }
  ${fragments.track}
`

export default function Homepage() {
  const context = getContext()
  const [result] = useQuery({ query: LATEST_QUERY })
  const { data: data2, fetching } = result
  const [other_result] = useQuery({ query: OTHER_QUERY })
  const currentTrack = useCurrentTrack()

  useEffect(() => {
    try {
      if (!currentTrack && data2 && data2.latest && data2.latest[0]) {
        context.setPlaylist(data2.latest.map(({ id }) => id))
      }
    } catch (e) {}
  }, [data2 && data2.latest && data2.latest.length])

  if (fetching)
    return (
      <PageContainer>
        <BaseHead
          title="TraduZic | La traduction de plus de 10 000 paroles de musiques en français"
          description="Comprenez vos artistes favoris facilement grâce à nos vidéos traduites et sous-titrées à la main"
        />
        <FlexColumn>
          <ButtonSelect style={{ filter: "blur(2px)" }}>
            <ButtonSelect.Item>Sélection</ButtonSelect.Item>
            <Spacer size="small" />
            <ButtonSelect.Item active>Nouveautés</ButtonSelect.Item>
            <Spacer size="small" />
            <ButtonSelect.Item>Top Vidéos</ButtonSelect.Item>
          </ButtonSelect>
          <Spacer />
          <TrackListLoader />
          <Spacer size="large" />
          <TrackListLoader />
        </FlexColumn>
      </PageContainer>
    )

  const { latest } = data2 || { latest: [] }

  const { mostViewed, selection } = other_result.data
    ? other_result.data
    : { mostViewed: [], selection: [] }

  return (
    <Page image={latest[2] && latest[2].artist.image}>
      <BaseHead
        title="TraduZic | La traduction de plus de 10 000 paroles de musiques en français"
        description="Comprenez vos artistes favoris facilement grâce à nos vidéos traduites et sous-titrées à la main"
      />
      <FlexColumn>
        <HomepageTop
          mostViewed={mostViewed}
          latest={latest}
          selection={selection}
        />
        <SuggestionList />
        <Spacer size="large" />
        <LatestComments />
        <Spacer />
      </FlexColumn>
    </Page>
  )
}
